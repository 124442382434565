import React from 'react';

import { Divider, Form, Header, Select } from 'semantic-ui-react';

import QuestionList from './QuestionList';

class Page4 extends React.Component {
  render() {
    return (
      <>
        <Header as='h4'>Desired Location of Quoters</Header>
        <Form.Field
          control={Select}
          label='Required location'
          placeholder='Select a distance'
          name='quoterLocation'
          onChange={(_, { value }) => {
            this.props.setFieldTouched('quoterLocation');
            this.props.setFieldValue('quoterLocation', value);
          }}
          onBlur={e => {
            this.props.setFieldTouched('quoterLocation');
            this.props.handleBlur(e);
          }}
          error={
            this.props.touched['quoterLocation'] &&
            this.props.errors['quoterLocation']
          }
          value={this.props.values.quoterLocation}
          options={[
            {
              key: '0',
              text: '',
              value: ''
            },
            {
              key: '1',
              text: 'Local Area (within 1 hour drive)',
              value: '1hour'
            },
            {
              key: '2',
              text: 'Within 2 hour drive',
              value: '2hour'
            },
            {
              key: '3',
              text: 'Within 4 hour drive',
              value: '4hour'
            },
            {
              key: '4',
              text: 'Within the UK',
              value: 'uk'
            },
            {
              key: '5',
              text: 'Within Europe',
              value: 'europe'
            },
            {
              key: '6',
              text: 'World Wide',
              value: 'worldwide'
            }
          ]}
          width={16}
          required
        />
        <Divider />
        <QuestionList
          setFieldValue={this.props.setFieldValue}
          values={this.props.values}
        />
        <Divider />
      </>
    );
  }
}

export default Page4;
