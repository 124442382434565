import React, { Fragment } from 'react';

import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import history from './history';
import * as ROUTES from './constants/routes';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import ProtectedRoute from './components/ProtectedRoute';
import Landing from './components/Landing';
import Home from './components/Home';
import Admin from './components/Admin';
import AdminShowProject from './components/Admin/ShowProject';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import SignUpThankYou from './components/SignUpThankYou';
import PasswordReset from './components/PasswordReset';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Faq from './components/Faq';
import Projects from './components/Projects';
import NewProject from './components/NewProject';
import NewProjectThankYou from './components/NewProjectThankYou';
import ShowProject from './components/ShowProject';
import EditProject from './components/EditProject';
import ProfileEdit from './components/Home/ProfileEdit';
import ProfileManage from './components/Home/ProfileManage';
import ProfileImageUpload from './components/Home/ProfileImageUpload';
import BadRoute from './components/BadRoute';
import CookieConsentPopup from './components/CookieConsentPopup';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import Blog from './components/Blog';
import BlogPost200801 from './components/Blog/BlogPost200801';

function App(props) {
  const { isAuthenticated, isVerifying } = props;
  return (
    <>
      <div
        style={{
          minHeight: '93vh',
        }}
      >
        <CookieConsentPopup />

        <Switch>
          <Route path={ROUTES.LANDING} exact component={Landing} />
          <Fragment>
            <Navigation currentPath={history.location.pathname} />
            <Route path={ROUTES.SIGN_IN} component={SignIn} />
            <Route path={ROUTES.SIGN_UP} component={SignUp} exact />
            <Route path={ROUTES.PASSWORD_RESET} component={PasswordReset} />
            <Route path={ROUTES.ABOUT_US} component={AboutUs} />
            <Route path={ROUTES.CONTACT_US} component={ContactUs} />
            <Route path={ROUTES.FAQ} component={Faq} />
            <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
            <Route path={ROUTES.BLOG} exact component={Blog} />
            <Route
              path={ROUTES.BLOG_POST_200801}
              exact
              component={BlogPost200801}
            />
            <Route
              path={ROUTES.TERMS_AND_CONDITIONS}
              component={TermsAndConditions}
            />

            <ProtectedRoute
              exact
              path={ROUTES.HOME}
              component={Home}
              isAuthenticated={isAuthenticated}
              isVerifying={isVerifying}
            />
            <ProtectedRoute
              exact
              path={ROUTES.SIGN_UP_THANK_YOU}
              component={SignUpThankYou}
              isAuthenticated={isAuthenticated}
              isVerifying={isVerifying}
            />
            <ProtectedRoute
              exact
              path={ROUTES.PROFILE_EDIT}
              component={ProfileEdit}
              isAuthenticated={isAuthenticated}
              isVerifying={isVerifying}
            />
            <ProtectedRoute
              exact
              path={ROUTES.PROFILE_MANAGE}
              component={ProfileManage}
              isAuthenticated={isAuthenticated}
              isVerifying={isVerifying}
            />
            <ProtectedRoute
              exact
              path={ROUTES.PROFILE_IMAGE_UPLOAD}
              component={ProfileImageUpload}
              isAuthenticated={isAuthenticated}
              isVerifying={isVerifying}
            />
            <ProtectedRoute
              exact
              path={ROUTES.PROJECTS}
              component={Projects}
              isAuthenticated={isAuthenticated}
              isVerifying={isVerifying}
            />
            <ProtectedRoute
              exact
              path={ROUTES.NEW_PROJECT}
              component={NewProject}
              isAuthenticated={isAuthenticated}
              isVerifying={isVerifying}
            />
            <ProtectedRoute
              exact
              path={ROUTES.NEW_PROJECT_THANK_YOU}
              component={NewProjectThankYou}
              isAuthenticated={isAuthenticated}
              isVerifying={isVerifying}
            />
            <ProtectedRoute
              exact
              path={ROUTES.SHOW_PROJECT}
              component={ShowProject}
              isAuthenticated={isAuthenticated}
              isVerifying={isVerifying}
            />
            <ProtectedRoute
              exact
              path={ROUTES.EDIT_PROJECT}
              component={EditProject}
              isAuthenticated={isAuthenticated}
              isVerifying={isVerifying}
            />
            <ProtectedRoute
              exact
              path={ROUTES.ADMIN}
              component={Admin}
              isAuthenticated={isAuthenticated}
              isVerifying={isVerifying}
            />
            <ProtectedRoute
              exact
              path={ROUTES.ADMIN_SHOW_PROJECT}
              component={AdminShowProject}
              isAuthenticated={isAuthenticated}
              isVerifying={isVerifying}
            />

            <Route
              component={BadRoute}
              currentPath={history.location.pathname}
            />
          </Fragment>
        </Switch>
      </div>
      <Footer />
    </>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
  };
}
export default connect(mapStateToProps)(App);
