import React from 'react';

import { Icon, Message, Responsive, Step } from 'semantic-ui-react';

class Progress extends React.Component {
  state = { page: this.props.page, upTo: this.props.upTo };
  hasErrors = [false, false, false, false];

  checkErrors() {
    let errors = this.props.errors;

    const pageFields = {
      page1: ['title', 'description', 'category', 'speciality'],
      page2: [],
      page3: [],
      page4: [],
    };

    //check page 1
    pageFields['page1'].forEach((field) => {
      if (errors.hasOwnProperty(field)) {
        this.hasErrors[0] = true;
      }
    });
    //check page 2
    pageFields['page2'].forEach((field) => {
      if (errors.hasOwnProperty(field)) {
        this.hasErrors[1] = true;
      }
    });
    //check page 3
    pageFields['page3'].forEach((field) => {
      if (errors.hasOwnProperty(field)) {
        this.hasErrors[3] = true;
      }
    });
    //check page 4
    pageFields['page4'].forEach((field) => {
      if (errors.hasOwnProperty(field)) {
        this.hasErrors[4] = true;
      }
    });
  }

  step1() {
    if (this.hasErrors[0]) {
      return (
        <Step
          link
          onClick={() => {
            this.props.goToPage(0);
          }}
        >
          <Icon name='edit outline' size='small' />
          <Step.Content>
            <Step.Title>Basic Info</Step.Title>
            <Message negative>Contains Errors!</Message>
          </Step.Content>
        </Step>
      );
    } else if (this.state.page + 1 === 1) {
      return (
        <Step active>
          <Icon name='edit outline' size='small' />
          <Step.Content>
            <Step.Title>Basic Info</Step.Title>
            <Step.Description>Title & Type</Step.Description>
          </Step.Content>
        </Step>
      );
    } else if (this.state.page + 1 > 1 || this.state.upTo + 1 > 1) {
      return (
        <Step
          completed
          link
          onClick={() => {
            this.props.goToPage(0);
          }}
        >
          <Icon name='edit outline' size='small' />
          <Step.Content>
            <Step.Title>Basic Info</Step.Title>
            <Step.Description>Title & Type</Step.Description>
          </Step.Content>
        </Step>
      );
    } else if (this.state.upTo + 1 === 1) {
      return (
        <Step
          link
          onClick={() => {
            this.props.goToPage(0);
          }}
        >
          <Icon name='edit outline' size='small' />
          <Step.Content>
            <Step.Title>Basic Info</Step.Title>
            <Step.Description>Title & Type</Step.Description>
          </Step.Content>
        </Step>
      );
    } else {
      return (
        <Step>
          <Icon name='edit outline' size='small' />
          <Step.Content>
            <Step.Title>Basic Info</Step.Title>
            <Step.Description>Title & Type</Step.Description>
          </Step.Content>
        </Step>
      );
    }
  }

  step2() {
    if (this.state.page + 1 === 2) {
      return (
        <Step active>
          <Icon name='file alternate outline' />
          <Step.Content>
            <Step.Title>Detailed Info</Step.Title>
            <Step.Description>Full Project Requirements</Step.Description>
          </Step.Content>
        </Step>
      );
    } else if (this.state.page + 1 > 2 || this.state.upTo + 1 > 2) {
      return (
        <Step
          completed
          link
          onClick={() => {
            this.props.goToPage(1);
          }}
        >
          <Icon name='file alternate outline' />
          <Step.Content>
            <Step.Title>Detailed Info</Step.Title>
            <Step.Description>Full Project Requirements</Step.Description>
          </Step.Content>
        </Step>
      );
    } else if (this.state.upTo + 1 === 2) {
      return (
        <Step
          link
          onClick={() => {
            this.props.goToPage(1);
          }}
        >
          <Icon name='file alternate outline' />
          <Step.Content>
            <Step.Title>Detailed Info</Step.Title>
            <Step.Description>Full Project Requirements</Step.Description>
          </Step.Content>
        </Step>
      );
    } else {
      return (
        <Step disabled>
          <Icon name='file alternate outline' />
          <Step.Content>
            <Step.Title>Detailed Info</Step.Title>
            <Step.Description>Full Project Requirements</Step.Description>
          </Step.Content>
        </Step>
      );
    }
  }

  step3() {
    if (this.state.page + 1 === 3) {
      return (
        <Step active>
          <Icon name='calculator' />
          <Step.Content>
            <Step.Title>Deliverables</Step.Title>
            <Step.Description>Timeline and Budget</Step.Description>
          </Step.Content>
        </Step>
      );
    } else if (this.state.page + 1 > 2 || this.state.upTo + 1 > 3) {
      return (
        <Step
          completed
          link
          onClick={() => {
            this.props.goToPage(2);
          }}
        >
          <Icon name='calculator' />
          <Step.Content>
            <Step.Title>Deliverables</Step.Title>
            <Step.Description>Timeline and Budget</Step.Description>
          </Step.Content>
        </Step>
      );
    } else if (this.state.upTo + 1 === 3) {
      return (
        <Step
          link
          onClick={() => {
            this.props.goToPage(2);
          }}
        >
          <Icon name='calculator' />
          <Step.Content>
            <Step.Title>Deliverables</Step.Title>
            <Step.Description>Timeline and Budget</Step.Description>
          </Step.Content>
        </Step>
      );
    } else {
      return (
        <Step disabled>
          <Icon name='calculator' />
          <Step.Content>
            <Step.Title>Deliverables</Step.Title>
            <Step.Description>Timeline and Budget</Step.Description>
          </Step.Content>
        </Step>
      );
    }
  }

  step4() {
    // console.log(this.state.upTo);
    if (this.state.page + 1 === 4) {
      return (
        <Step active>
          <Icon name='address card outline' />
          <Step.Content>
            <Step.Title>Final Info</Step.Title>
            <Step.Description>Requirements to quote</Step.Description>
          </Step.Content>
        </Step>
      );
    } else if (this.state.upTo + 1 === 4) {
      return (
        <Step
          link
          onClick={() => {
            this.props.goToPage(3);
          }}
        >
          <Icon name='address card outline' />
          <Step.Content>
            <Step.Title>Final Info</Step.Title>
            <Step.Description>Requirements to quote</Step.Description>
          </Step.Content>
        </Step>
      );
    } else if (this.props.continue) {
      return (
        <Step
          completed
          link
          onClick={() => {
            this.props.goToPage(3);
          }}
        >
          <Icon name='address card outline' />
          <Step.Content>
            <Step.Title>Final Info</Step.Title>
            <Step.Description>Requirements to quote</Step.Description>
          </Step.Content>
        </Step>
      );
    } else {
      return (
        <Step disabled>
          <Icon name='address card outline' />
          <Step.Content>
            <Step.Title>Final Info</Step.Title>
            <Step.Description>Requirements to quote</Step.Description>
          </Step.Content>
        </Step>
      );
    }
  }

  render() {
    // this.checkErrors();
    return (
      <>
        {/* <Responsive maxWidth={Responsive.onlyMobile.maxWidth}> */}
        <Responsive maxWidth={600}>
          <Step.Group widths={4} size='mini'>
            {this.step1()}
            {this.step2()}
            {this.step3()}
            {this.step4()}
          </Step.Group>
        </Responsive>

        <Responsive minWidth={600}>
          <Step.Group unstackable widths={4} size='mini'>
            {this.step1()}
            {this.step2()}
            {this.step3()}
            {this.step4()}
          </Step.Group>
        </Responsive>
      </>
    );
  }
}

export default Progress;
