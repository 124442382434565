import React from 'react';

import { Divider, Form, Header, TextArea } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';

import FileUploader from './FileUploader';

class Page2 extends React.Component {
  setFilesForUpload = (uploadedFiles) => {
    this.setState({ files: uploadedFiles });
  };

  render() {
    return (
      <>
        <Form.Input
          control={TextareaAutosize}
          useCacheForDOMMeasurements
          minRows={8}
          placeholder="Enter a full description of your project. Try to be thorough to ensure good quality quotations."
          name="detailedDescription"
          value={this.props.values.detailedDescription}
          onChange={this.props.handleChange}
          onBlur={this.props.handleBlur}
          label="Full Detailed Description"
          error={
            this.props.touched['detailedDescription'] &&
            this.props.errors['detailedDescription']
          }
          required
        />
        <Form.Input
          label="Additional information or requirements"
          error={
            this.props.touched['additionalInformation'] &&
            this.props.errors['additionalInformation']
          }
        >
          <TextArea
            label="Additional information or requirements"
            rows={3}
            placeholder="Any Additional Information? Insurance requirements, accreditations, preferred suppliers"
            name="additionalInformation"
            value={this.props.values.additionalInformation}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
          />
        </Form.Input>

        <Header as="h4">Supporting Documents</Header>

        <FileUploader
          setFilesForUpload={this.setFilesForUpload}
          setFieldValue={this.props.setFieldValue}
          values={this.props.values}
        />
        <Divider />
      </>
    );
  }
}

export default Page2;
