import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Button, Header, Image, Modal } from 'semantic-ui-react';

import * as ROUTES from '../../constants/routes';

import { getUserInfoFromFirestore } from '../../actions';

class NoProjectModal extends React.Component {
  state = {
    modalOpen: false,
    modalDismissed: false,
    initialised: false,
    uploadingChanges: false,
  };

  componentDidMount() {
    this.props.getUserInfoFromFirestore();
  }

  checkIfNoProjects() {
    if (!this.state.initialised) {
      if (
        this.props.userFirstName &&
        this.props.userInitialised &&
        !this.props.userProjects.length &&
        !this.state.modalOpen
      ) {
        this.setState({ modalOpen: true, initialised: true });
      }
    }
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  render() {
    if (!this.state.initialised) {
      this.checkIfNoProjects();
    }

    return (
      <Modal open={this.state.modalOpen}>
        <Modal.Header>Welcome to Machine Builders!</Modal.Header>
        <Modal.Content image>
          <Image
            size="medium"
            src="https://firebasestorage.googleapis.com/v0/b/machine-builders.appspot.com/o/staticFiles%2F512.png?alt=media&token=87c463a0-133e-46da-a07c-9fea7d0b7561"
            wrapped
          />
          <Modal.Description>
            <Header>Thank you for completing your profile.</Header>
            <p>You're now ready to start using the machine builders platform</p>
            <p>You currently have no projects on your account.</p>
            <p>
              Click on 'Get started' below to begin entering your first project
              requirements.
            </p>
            <p>
              You can save a project and come back to it later if you need to
              get more information for it.
            </p>
            <p>
              Click <Link to={ROUTES.FAQ}>Here</Link> to find out more about how
              the platform works.
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => this.closeModal()}>
            Later
          </Button>
          <Button color="teal" as={Link} to={ROUTES.NEW_PROJECT}>
            Create Project
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    userFirstName: state.firestoreReducers.userRecord.firstName,
    userProjects: state.firestoreReducers.userProjects,
    userInitialised: state.firestoreReducers.userRecord.userInitialised,
  };
}

export default connect(mapStateToProps, {
  getUserInfoFromFirestore,
})(NoProjectModal);
