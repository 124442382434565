import React from 'react';

import { Container, Header, Image } from 'semantic-ui-react';

import { Helmet } from 'react-helmet';

class AboutUs extends React.Component {
  render() {
    return (
      <Container text>
        <Helmet>
          <meta charSet='utf-8' />
          <title>About Us</title>
          <meta
            name='description'
            content='Find out more about how Machine Builders was started and how we operate. We help manufacturers find the best talent for their factory automation projects. Find machine builders, systems integrators, specialists in machine vision and process control, and talent which helps power the fourth industrial revolution'
          />
        </Helmet>
        <Header
          as='h1'
          content='About Us'
          style={{
            fontSize: '4em',
            fontWeight: 'normal',
            marginBottom: 0,
            marginTop: '0.8em',
          }}
        />
        <Header
          as='h2'
          content='What are we all about?'
          style={{
            fontSize: '1.7em',
            fontWeight: 'normal',
            marginTop: 0,
          }}
        />
        <Image
          floated='right'
          size='medium'
          src='https://firebasestorage.googleapis.com/v0/b/machine-builders.appspot.com/o/staticFiles%2F512.png?alt=media&token=87c463a0-133e-46da-a07c-9fea7d0b7561'
        />

        <p>
          Machine Builders.co.uk was set up to bridge the gap between
          manufacturers and solution providers. With years of experience in the
          manufacturing and automation industry the machine builders team have
          seen time and time again that the industry is crying out for a more
          efficient way to get projects done well.
        </p>

        <p>
          Historically manufacturers have struggled to find the correct talent
          for their specific applications. Most often we see projects being
          quoted for by known contacts from previous jobs, not necessarily being
          given to the best companies for that specific project.
        </p>
        <p>
          Project engineers trawl through out of date websites, or have to go to
          many exhibitions to find the correct talent for their applications.
          Machine Builders.co.uk will make it much easier for manufacturers to
          put their project proposals out to the best talent in the industry,
          leading to a quicker turn around and higher success rate for
          automation projects across the sector.
        </p>

        <p>
          Good machine builders have also struggled to find the best projects to
          quote for. Word of mouth can keep a company going, but many superstars
          are going unnoticed. Smaller companies struggle to grow as fast as
          they would like to because they don’t have the marketing budget to
          compete with larger firms, or to go to exhibitions with only the hope
          of coming home with leads.
        </p>
        <p>
          Larger firms also spend a lot of money on exhibitions and
          advertisements with a low rate of return. Machine Builders.co.uk aims
          to democratise the tender process so that solutions providers can skip
          the marketing and apply directly for projects where they can deliver
          fantastic solutions.
        </p>
        <p>
          We’ve been working hard to create the best platform for rapidly
          levelling up the UK manufacturing industry, we’ll keep tirelessly
          working to help bring people together, and with your help we can keep
          the UK manufacturing sector at the top of it’s game.
        </p>
      </Container>
    );
  }
}

export default AboutUs;
