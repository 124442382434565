import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Button,
  Header,
  Icon,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react';
import { adminGetPublishedProjects } from '../../actions';
import * as ROUTES from '../../constants/routes';

class PublishedProjects extends Component {
  state = { showList: false };
  componentDidMount() {
    this.props.adminGetPublishedProjects();
  }

  since(time) {
    const timeNow = Math.floor(Date.now() / 1000);
    const elapsedSeconds = timeNow - time.seconds;
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    const elapsedHours = Math.floor(elapsedMinutes / 60);
    const elapsedDays = Math.floor(elapsedHours / 24);

    if (elapsedDays > 1) {
      return `${elapsedDays} Day${elapsedDays === 1 ? '' : 's'}`;
    } else if (elapsedHours >= 1) {
      return `${elapsedHours} Hour${elapsedHours === 1 ? '' : 's'}`;
    } else if (elapsedMinutes >= 1) {
      return `${elapsedMinutes} Minute${elapsedMinutes === 1 ? '' : 's'}`;
    } else if (elapsedSeconds >= 1) {
      return `${elapsedSeconds} Second${elapsedSeconds === 1 ? '' : 's'}`;
    }
    return 'Error';
  }

  humaniseBudget(budget) {
    const budgetString = `${budget}`;
    let formattedString = '';
    if (budgetString.length > 4) {
      for (let i = budgetString.length - 1; i >= 0; i--) {
        if ((i - 1) % 3 === 0 && i < budgetString.length - 1) {
          formattedString = `${budgetString[i]},` + formattedString;
        } else {
          formattedString = budgetString[i] + formattedString;
        }
      }
    } else {
      formattedString = budgetString;
    }
    return formattedString;
  }

  generateProjectList() {
    if (
      this.props.adminPublishedProjectsRequestSuccess &&
      this.props.adminProjectOwnersRequestSuccess
    ) {
      const projectList = this.props.adminPublishedProjects.map((project) => {
        return (
          <Table.Row>
            <Table.Cell>
              {this.props.adminProjectOwners[project.owner].company.companyName}
            </Table.Cell>
            <Table.Cell>{project.title}</Table.Cell>
            <Table.Cell>£{this.humaniseBudget(project.budget)}</Table.Cell>
            <Table.Cell>{this.since(project.updatedAt)}</Table.Cell>
            <Table.Cell>
              <Button
                as={Link}
                to={`${ROUTES.ADMIN_SHOW_PROJECT_PREFIX}${project.uuid}`}
                color='teal'
                size='tiny'
                floated='right'
              >
                Open
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      });

      return (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Owner</Table.HeaderCell>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>Budget</Table.HeaderCell>
              <Table.HeaderCell>Draft For</Table.HeaderCell>
              <Table.HeaderCell>Open</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{projectList}</Table.Body>
        </Table>
      );
    }
  }

  generateDraftStats() {
    if (
      this.props.adminPublishedProjectsRequestSuccess &&
      this.props.adminProjectOwnersRequestSuccess
    ) {
      return (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Draft projects</Table.HeaderCell>
              <Table.HeaderCell>Longest Wait</Table.HeaderCell>
              <Table.HeaderCell>Individual Users</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                {this.props.adminPublishedProjects.length}
              </Table.Cell>
              <Table.Cell>
                {this.since(this.props.adminPublishedProjects[0].updatedAt)}
              </Table.Cell>
              <Table.Cell>
                {Object.keys(this.props.adminProjectOwners).length}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      );
    } else if (this.props.adminPublishedProjectsRequestError) {
      console.log(this.props.adminPublishedProjectsRequestErrorMessage);
      return (
        <Message icon>
          <Icon name='search' />
          <Message.Content>
            <Message.Header>No such projects found</Message.Header>
            Either there are 0 such projects, or there is an error with the
            database connection.
          </Message.Content>
        </Message>
      );
    }
  }

  handleShowButton = () => {
    this.setState({ showList: !this.state.showList });
  };

  showProjectList = () => {
    if (this.state.showList) {
      return this.generateProjectList();
    } else {
      return (
        <>
          <br />
          <br />
        </>
      );
    }
  };

  renderAlert() {
    if (
      this.props.adminPublishedProjectsRequestSuccess &&
      this.props.adminProjectOwnersRequestSuccess
    ) {
      const timeNow = Math.floor(Date.now() / 1000);
      const elapsedSeconds =
        timeNow - this.props.adminPublishedProjects[0].updatedAt.seconds;
      const elapsedHours = Math.floor(elapsedSeconds / 60 / 24);
      if (elapsedHours > 24) {
        return (
          <Message icon warning>
            <Icon name='alarm' />
            <Message.Content>
              <Message.Header>
                Projects have been waiting 24 hours for Approval!!!
              </Message.Header>
              These projects are confirmed as complete by the customer and are
              ready to go! We need to approve/reject them ASAP to keep the
              process moving!
            </Message.Content>
          </Message>
        );
      }
    }
  }

  render() {
    return (
      <Segment raised>
        <Header as='h3'>
          Published Projects
          <Header.Subheader>
            Published projects requiring Admin Approval, sorted by pending
            duration
          </Header.Subheader>
        </Header>
        {this.generateDraftStats()}
        {this.renderAlert()}
        <Button floated='right' onClick={this.handleShowButton}>
          {this.state.showList ? 'Hide' : 'Show'}
        </Button>
        {this.showProjectList()}
      </Segment>
    );
  }
}

function mapStateToProps(state) {
  return {
    adminPublishedProjectsRequest:
      state.firestoreReducers.adminPublishedProjectsRequest,
    adminPublishedProjectsRequestSuccess:
      state.firestoreReducers.adminPublishedProjectsRequestSuccess,
    adminPublishedProjectsRequestError:
      state.firestoreReducers.adminPublishedProjectsRequestError,
    adminPublishedProjectsRequestErrorMessage:
      state.firestoreReducers.adminPublishedProjectsRequestErrorMessage,
    adminPublishedProjects: state.firestoreReducers.adminPublishedProjects,
    adminProjectOwnersRequest:
      state.firestoreReducers.adminProjectOwnersRequest,
    adminProjectOwnersRequestSuccess:
      state.firestoreReducers.adminProjectOwnersRequestSuccess,
    adminProjectOwnersRequestError:
      state.firestoreReducers.adminProjectOwnersRequestError,
    adminProjectOwnersRequestErrorMessage:
      state.firestoreReducers.adminProjectOwnersRequestErrorMessage,
    adminProjectOwners: state.firestoreReducers.adminProjectOwners,
  };
}

export default connect(mapStateToProps, { adminGetPublishedProjects })(
  PublishedProjects
);
