import React from 'react';

import {
  Divider,
  Form,
  Header,
  Input,
  Label,
  Message,
  Select,
} from 'semantic-ui-react';

class Page3 extends React.Component {
  generateBudgetValue() {
    console.log(typeof this.props.values.budget);
    if (isNaN(this.props.values.budget)) {
      return this.props.values.budget;
    } else {
      return Math.trunc(Number(this.props.values.budget));
    }
  }

  render() {
    return (
      <>
        <Header as="h4">Project Timeline</Header>
        <Form.Field
          control={Select}
          label="Quotation Timeline"
          placeholder="Select a timescale"
          name="quoteTimeline"
          onChange={(_, { value }) => {
            this.props.setFieldTouched('quoteTimeline');
            this.props.setFieldValue('quoteTimeline', value);
          }}
          onBlur={(e) => {
            this.props.setFieldTouched('quoteTimeline');
            this.props.handleBlur(e);
          }}
          error={
            this.props.touched['quoteTimeline'] &&
            this.props.errors['quoteTimeline']
          }
          value={this.props.values.quoteTimeline}
          options={[
            {
              key: '0',
              text: '',
              value: '',
            },
            {
              key: '1',
              text: 'Rush job (immediate need)',
              value: 'rushed',
            },
            { key: '2', text: '1 Month', value: '1month' },
            {
              key: '3',
              text: 'Any time',
              value: 'anytime',
            },
          ]}
          width={16}
          required
        />
        <Form.Field
          control={Select}
          label="Project Timeline"
          placeholder="Select a timescale"
          name="projectTimeline"
          onChange={(_, { value }) => {
            this.props.setFieldTouched('projectTimeline');
            this.props.setFieldValue('projectTimeline', value);
          }}
          onBlur={(e) => {
            this.props.setFieldTouched('projectTimeline');
            this.props.handleBlur(e);
          }}
          error={
            this.props.touched['projectTimeline'] &&
            this.props.errors['projectTimeline']
          }
          value={this.props.values.projectTimeline}
          options={[
            {
              key: '0',
              text: '',
              value: '',
            },
            {
              key: '1',
              text: 'Within 1 Week',
              value: '1week',
            },
            { key: '2', text: 'Within 1 Month', value: '1month' },
            {
              key: '3',
              text: 'Within 3 Months',
              value: '3months',
            },
            {
              key: '4',
              text: 'Within 6 Months',
              value: '6months',
            },
            { key: '5', text: 'Within 1 year', value: '1year' },
            {
              key: '6',
              text: 'More than 1 year',
              value: '>1year',
            },
          ]}
          width={16}
          required
        />
        {/* Why is this your timeline here - timelineReason */}

        <Form.Input
          fluid
          icon="edit outline"
          iconPosition="left"
          label="Timeline Reason"
          placeholder="Why is this your timeline?"
          name="timelineReason"
          onChange={this.props.handleChange}
          onBlur={this.props.handleBlur}
          value={this.props.values.timelineReason}
          error={
            this.props.touched.timelineReason &&
            this.props.errors.timelineReason
          }
          required
        />

        <Form.Field label="Approximate Buget" required />
        <Form.Field
          error={
            this.props.touched.budget && this.props.errors.budget ? true : false
          }
        >
          <Input
            labelPosition="right"
            type="text"
            placeholder="Amount"
            name="budget"
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            value={this.generateBudgetValue()}
            required
          >
            <Label basic>£</Label>
            <input />
            <Label>.00</Label>
          </Input>
        </Form.Field>
        {this.props.touched.budget && this.props.errors.budget && (
          <Message negative>
            <Message.Header>Error!</Message.Header>
            <p>{this.props.errors.budget}</p>
          </Message>
        )}

        <Divider />
      </>
    );
  }
}

export default Page3;
