import { myFirebase, db, storageRef, fieldValue } from '../firebase/firebase';
// import { storage } from 'firebase';
// import { forEach } from 'lodash';

// import history from '../history';
// import * as ROUTES from '../constants/routes';

export const GETTING_USER_INFO_FROM_FIRESTORE =
  'RETRIEVE_USER_INFO_FROM_FIRESTORE';
export const GOT_USER_INFO_FROM_FIRESTORE = 'GOT_USER_INFO_FROM_FIRESTORE';
export const GETTING_USER_INFO_FROM_FIRESTORE_ERROR =
  'RETRIEVE_USER_INFO_FROM_FIRESTORE_ERROR';
export const SAVING_NEW_PROJECT_IN_FIRESTORE =
  'SAVING_NEW_PROJECT_IN_FIRESTORE';
export const SAVING_NEW_PROJECT_IN_FIRESTORE_SUCCESS =
  'SAVING_NEW_PROJECT_IN_FIRESTORE_SUCCESS';
export const SAVING_NEW_PROJECT_IN_FIRESTORE_ERROR =
  'SAVING_NEW_PROJECT_IN_FIRESTORE_ERROR';
export const SAVING_NEW_PROJECT_IN_FIRESTORE_STATE_CLEAR =
  'SAVING_NEW_PROJECT_IN_FIRESTORE_STATE_CLEAR';
export const SAVING_NEW_PROJECT_IN_FIRESTORE_UPLOADING_DOCUMENTS =
  'SAVING_NEW_PROJECT_IN_FIRESTORE_UPLOADING_DOCUMENTS';
export const REQUEST_USERS_PROJECTS_FROM_FIRESTORE =
  'REQUEST_USERS_PROJECTS_FROM_FIRESTORE';
export const RECEIVE_USERS_PROJECTS_FROM_FIRESTORE =
  'RECEIVE_USERS_PROJECTS_FROM_FIRESTORE';
export const REQUEST_USERS_PROJECTS_FROM_FIRESTORE_ERROR =
  'REQUEST_USERS_PROJECTS_FROM_FIRESTORE_ERROR';
export const REQUEST_USERS_PROJECT_FROM_FIRESTORE =
  'REQUEST_USERS_PROJECT_FROM_FIRESTORE';
export const RECEIVE_USERS_PROJECT_FROM_FIRESTORE =
  'RECEIVE_USERS_PROJECT_FROM_FIRESTORE';
export const REQUEST_USERS_PROJECT_FROM_FIRESTORE_ERROR =
  'REQUEST_USERS_PROJECT_FROM_FIRESTORE_ERROR';
export const REQUEST_UPDATE_DOCUMENT_IN_FIRESTORE =
  'REQUEST_UPDATE_DOCUMENT_IN_FIRESTORE';
export const REQUEST_UPDATE_USER_IN_FIRESTORE =
  'REQUEST_UPDATE_USER_IN_FIRESTORE';
export const REQUEST_UPDATE_USER_IN_FIRESTORE_SUCCESS =
  'REQUEST_UPDATE_USER_IN_FIRESTORE_SUCCESS';
export const REQUEST_UPDATE_USER_IN_FIRESTORE_ERROR =
  'REQUEST_UPDATE_USER_IN_FIRESTORE_ERROR';
export const REQUEST_UPDATE_USER_IN_FIRESTORE_STATE_CLEAR =
  'REQUEST_UPDATE_USER_IN_FIRESTORE_STATE_CLEAR';
export const REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE =
  'REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE';
export const REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE_SUCCESS =
  'REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE_SUCCESS';
export const REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE_ERROR =
  'REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE_ERROR';
export const REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE_STATE_CLEAR =
  'REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE_STATE_CLEAR';
export const REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE =
  'REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE';
export const REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE_SUCCESS =
  'REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE_SUCESS';
export const REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE_ERROR =
  'REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE_ERROR';
export const REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE_STATE_CLEAR =
  'REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE_STATE_CLEAR';
export const REQUEST_ADMIN_DRAFT_PROJECTS = 'REQUEST_ADMIN_DRAFT_PROJECTS';
export const REQUEST_ADMIN_DRAFT_PROJECTS_SUCCESS =
  'REQUEST_ADMIN_DRAFT_PROJECTS_SUCCESS';
export const REQUEST_ADMIN_DRAFT_PROJECTS_ERROR =
  'REQUEST_ADMIN_DRAFT_PROJECTS_ERROR';
export const REQUEST_ADMIN_DRAFT_PROJECTS_STATE_CLEAR =
  'REQUEST_ADMIN_DRAFT_PROJECTS_STATE_CLEAR';
export const REQUEST_ADMIN_PUBLISHED_PROJECTS =
  'REQUEST_ADMIN_PUBLISHED_PROJECTS';
export const REQUEST_ADMIN_PUBLISHED_PROJECTS_SUCCESS =
  'REQUEST_ADMIN_PUBLISHED_PROJECTS_SUCCESS';
export const REQUEST_ADMIN_PUBLISHED_PROJECTS_ERROR =
  'REQUEST_ADMIN_PUBLISHED_PROJECTS_ERROR';
export const REQUEST_ADMIN_PUBLISHED_PROJECTS_STATE_CLEAR =
  'REQUEST_ADMIN_PUBLISHED_PROJECTS_STATE_CLEAR';
export const REQUEST_ADMIN_REJECTED_PROJECTS =
  'REQUEST_ADMIN_REJECTED_PROJECTS';
export const REQUEST_ADMIN_REJECTED_PROJECTS_SUCCESS =
  'REQUEST_ADMIN_REJECTED_PROJECTS_SUCCESS';
export const REQUEST_ADMIN_REJECTED_PROJECTS_ERROR =
  'REQUEST_ADMIN_REJECTED_PROJECTS_ERROR';
export const REQUEST_ADMIN_REJECTED_PROJECTS_STATE_CLEAR =
  'REQUEST_ADMIN_REJECTED_PROJECTS_STATE_CLEAR';
export const REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS =
  'REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS';
export const REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS_SUCCESS =
  'REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS_SUCCESS';
export const REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS_ERROR =
  'REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS_ERROR';
export const REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS_STATE_CLEAR =
  'REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS_STATE_CLEAR';
export const REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO =
  'REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO';
export const REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO_SUCCESS =
  'REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO_SUCCESS';
export const REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO_ERROR =
  'REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO_ERROR';
export const REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO_STATE_CLEAR =
  'REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO_STATE_CLEAR';
export const REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE =
  'REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE';
export const REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE_SUCCESS =
  'REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE_SUCCESS';
export const REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE_ERROR =
  'REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE_ERROR';
export const REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE_STATE_CLEAR =
  'REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE_STATE_CLEAR';
export const REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE =
  'REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE';
export const REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE_SUCCESS =
  'REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE_SUCCESS';
export const REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE_ERROR =
  'REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE_ERROR';
export const REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE_STATE_CLEAR =
  'REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE_STATE_CLEAR';
export const REQUEST_ADMIN_GET_STATISTICS = 'REQUEST_ADMIN_GET_STATISTICS';
export const REQUEST_ADMIN_GET_STATISTICS_SUCCESS =
  'REQUEST_ADMIN_GET_STATISTICS_SUCCESS';
export const REQUEST_ADMIN_GET_STATISTICS_ERROR =
  'REQUEST_ADMIN_GET_STATISTICS_ERROR';
export const REQUEST_ADMIN_GET_STATISTICS_STATE_CLEAR =
  'REQUEST_ADMIN_GET_STATISTICS_STATE_CLEAR';
export const REQUEST_ADMIN_GET_USER_INFO = 'REQUEST_ADMIN_GET_USER_INFO';
export const REQUEST_ADMIN_GET_USER_INFO_SUCCESS =
  'REQUEST_ADMIN_GET_USER_INFO_SUCCESS';
export const REQUEST_ADMIN_GET_USER_INFO_ERROR =
  'REQUEST_ADMIN_GET_USER_INFO_ERROR';
export const REQUEST_ADMIN_GET_USER_INFO_STATE_CLEAR =
  'REQUEST_ADMIN_GET_USER_INFO_STATE_CLEAR';

export const REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE =
  'REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE';
export const REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE_SUCCESS =
  'REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE_SUCCESS';
export const REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE_ERROR =
  'REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE_ERROR';
export const REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE_STATE_CLEAR =
  'REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE_STATE_CLEAR';

const requestUserInfoFromFirestore = () => {
  return {
    type: GETTING_USER_INFO_FROM_FIRESTORE,
  };
};

const receiveUserInfoFromFirestore = (userRecord) => {
  return {
    type: GOT_USER_INFO_FROM_FIRESTORE,
    userRecord,
  };
};

const requestUserInfoFromFirestoreError = (error) => {
  return {
    type: GETTING_USER_INFO_FROM_FIRESTORE_ERROR,
    error: error.message,
  };
};

const requestSaveNewProjectInFirestore = () => {
  return {
    type: SAVING_NEW_PROJECT_IN_FIRESTORE,
  };
};

const requestSaveNewProjectInFirestoreUploadingDocuments = (percentage) => {
  return {
    type: SAVING_NEW_PROJECT_IN_FIRESTORE_UPLOADING_DOCUMENTS,
    percentage: percentage,
  };
};

const requestSaveNewProjectInFirestoreSuccess = (projectRef) => {
  return {
    type: SAVING_NEW_PROJECT_IN_FIRESTORE_SUCCESS,
    projectRef: projectRef,
  };
};

const requestSaveNewProjectInFirestoreError = (error) => {
  return {
    type: SAVING_NEW_PROJECT_IN_FIRESTORE_ERROR,
    error: error.message,
  };
};

const requestSaveNewProjectInFirestoreStateClear = () => {
  return {
    type: SAVING_NEW_PROJECT_IN_FIRESTORE_ERROR,
  };
};

const requestUsersProjectsFromFirestore = () => {
  return {
    type: REQUEST_USERS_PROJECTS_FROM_FIRESTORE,
  };
};

const receiveUsersProjectsFromFirestore = (projects) => {
  return {
    type: RECEIVE_USERS_PROJECTS_FROM_FIRESTORE,
    projects: projects,
  };
};

const requestUsersProjectsFromFirestoreError = (error) => {
  return {
    type: REQUEST_USERS_PROJECTS_FROM_FIRESTORE_ERROR,
    error: error.message,
  };
};

const requestUsersProjectFromFirestore = () => {
  return {
    type: REQUEST_USERS_PROJECT_FROM_FIRESTORE,
  };
};

const receiveUsersProjectFromFirestore = (project) => {
  return {
    type: RECEIVE_USERS_PROJECT_FROM_FIRESTORE,
    project: project,
  };
};

const requestUsersProjectFromFirestoreError = (error) => {
  return {
    type: REQUEST_USERS_PROJECT_FROM_FIRESTORE_ERROR,
    error: error.message,
  };
};

const requestUpdateFirestoreDocument = () => {
  return {
    type: REQUEST_UPDATE_DOCUMENT_IN_FIRESTORE,
  };
};

const requestUpdateFirestoreUser = () => {
  return {
    type: REQUEST_UPDATE_USER_IN_FIRESTORE,
  };
};

const requestUpdateFirestoreUserSuccess = () => {
  return {
    type: REQUEST_UPDATE_USER_IN_FIRESTORE_SUCCESS,
  };
};

const requestUpdateFirestoreUserError = (error) => {
  return {
    type: REQUEST_UPDATE_USER_IN_FIRESTORE_SUCCESS,
    error: error.message,
  };
};

const requestUpdateFirestoreUserStateClear = () => {
  return {
    type: REQUEST_UPDATE_USER_IN_FIRESTORE_STATE_CLEAR,
  };
};

const requestSetProjectAsPublishedInFirestore = () => {
  return {
    type: REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE,
  };
};

const requestSetProjectAsPublishedInFirestoreSuccess = () => {
  return {
    type: REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE_SUCCESS,
  };
};

const requestSetProjectAsPublishedInFirestoreError = (error) => {
  return {
    type: REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE_ERROR,
    error: error.message,
  };
};

const requestSetProjectAsPublishedInFirestoreStateClear = () => {
  return {
    type: REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE_STATE_CLEAR,
  };
};

const requestUploadUserLogoInFirestore = () => {
  return {
    type: REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE,
  };
};

const requestUploadUserLogoInFirestoreSuccess = () => {
  return {
    type: REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE_SUCCESS,
  };
};

const requestUploadUserLogoInFirestoreError = (error) => {
  return {
    type: REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE_ERROR,
    error: error.message,
  };
};

const requestUploadUserLogoInFirestoreStateClear = () => {
  return {
    type: REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE_STATE_CLEAR,
  };
};

const requestAdminDraftProjects = () => {
  return {
    type: REQUEST_ADMIN_DRAFT_PROJECTS,
  };
};

const requestAdminDraftProjectsSuccess = (projects) => {
  return {
    type: REQUEST_ADMIN_DRAFT_PROJECTS_SUCCESS,
    projects: projects,
  };
};

const requestAdminDraftProjectsError = (error) => {
  return {
    type: REQUEST_ADMIN_DRAFT_PROJECTS_ERROR,
    error: error.message,
  };
};

const requestAdminDraftProjectsStateClear = () => {
  return {
    type: REQUEST_ADMIN_DRAFT_PROJECTS_STATE_CLEAR,
  };
};

const requestAdminPublishedProjects = () => {
  return {
    type: REQUEST_ADMIN_PUBLISHED_PROJECTS,
  };
};

const requestAdminPublishedProjectsSuccess = (projects) => {
  return {
    type: REQUEST_ADMIN_PUBLISHED_PROJECTS_SUCCESS,
    projects: projects,
  };
};

const requestAdminPublishedProjectsError = (error) => {
  return {
    type: REQUEST_ADMIN_PUBLISHED_PROJECTS_ERROR,
    error: error.message,
  };
};

const requestAdminPublishedProjectsStateClear = () => {
  return {
    type: REQUEST_ADMIN_PUBLISHED_PROJECTS_STATE_CLEAR,
  };
};

const requestAdminRejectedProjects = () => {
  return {
    type: REQUEST_ADMIN_REJECTED_PROJECTS,
  };
};

const requestAdminRejectedProjectsSuccess = (projects) => {
  return {
    type: REQUEST_ADMIN_REJECTED_PROJECTS_SUCCESS,
    projects: projects,
  };
};

const requestAdminRejectedProjectsError = (error) => {
  return {
    type: REQUEST_ADMIN_REJECTED_PROJECTS_ERROR,
    error: error.message,
  };
};

const requestAdminRejectedProjectsStateClear = () => {
  return {
    type: REQUEST_ADMIN_REJECTED_PROJECTS_STATE_CLEAR,
  };
};

const requestAdminPendingGoLiveProjects = () => {
  return {
    type: REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS,
  };
};

const requestAdminPendingGoLiveProjectsSuccess = (projects) => {
  return {
    type: REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS_SUCCESS,
    projects: projects,
  };
};

const requestAdminPendingGoLiveProjectsError = (error) => {
  return {
    type: REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS_ERROR,
    error: error.message,
  };
};

const requestAdminPendingGoLiveProjectsStateClear = () => {
  return {
    type: REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS_STATE_CLEAR,
  };
};

const requestAdminGetProjectOwnersInfo = () => {
  return {
    type: REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO,
  };
};
const requestAdminGetProjectOwnersInfoSuccess = (owners) => {
  return {
    type: REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO_SUCCESS,
    owners: owners,
  };
};
const requestAdminGetProjectOwnersInfoError = (error) => {
  return {
    type: REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO_ERROR,
    error: error.message,
  };
};
const requestAdminGetProjectOwnersInfoStateClear = () => {
  return {
    type: REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO_STATE_CLEAR,
  };
};

const requestAdminSetProjectAsApprovedInFirestore = () => {
  return {
    type: REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE,
  };
};

const requestAdminSetProjectAsApprovedInFirestoreSuccess = () => {
  return {
    type: REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE_SUCCESS,
  };
};

const requestAdminSetProjectAsApprovedInFirestoreError = (error) => {
  return {
    type: REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE_ERROR,
    error: error.message,
  };
};

const requestAdminSetProjectAsApprovedInFirestoreStateClear = () => {
  return {
    type: REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE_STATE_CLEAR,
  };
};

const requestAdminSetProjectAsRejectedInFirestore = () => {
  return {
    type: REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE,
  };
};

const requestAdminSetProjectAsRejectedInFirestoreSuccess = () => {
  return {
    type: REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE_SUCCESS,
  };
};

const requestAdminSetProjectAsRejectedInFirestoreError = (error) => {
  return {
    type: REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE_ERROR,
    error: error.message,
  };
};

const requestAdminSetProjectAsRejectedInFirestoreStateClear = () => {
  return {
    type: REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE_STATE_CLEAR,
  };
};

const requestAdminGetStatistics = () => {
  return {
    type: REQUEST_ADMIN_GET_STATISTICS,
  };
};
const requestAdminGetStatisticsSuccess = (statistics) => {
  return {
    type: REQUEST_ADMIN_GET_STATISTICS_SUCCESS,
    statistics: statistics,
  };
};
const requestAdminGetStatisticsError = (error) => {
  return {
    type: REQUEST_ADMIN_GET_STATISTICS_ERROR,
    error: error.message,
  };
};
const requestAdminGetStatisticsStateClear = () => {
  return {
    type: REQUEST_ADMIN_GET_STATISTICS_STATE_CLEAR,
  };
};

const requestAdminUserInfoFromFirestore = () => {
  return {
    type: REQUEST_ADMIN_GET_USER_INFO,
  };
};

const requestAdminUserInfoFromFirestoreSuccess = (userInfo) => {
  return {
    type: REQUEST_ADMIN_GET_USER_INFO_SUCCESS,
    userInfo: userInfo,
  };
};

const requestAdminUserInfoFromFirestoreError = (error) => {
  return {
    type: REQUEST_ADMIN_GET_USER_INFO_ERROR,
    error: error.message,
  };
};

const requestAdminUserInfoFromFirestoreStateClear = () => {
  return {
    type: REQUEST_ADMIN_GET_USER_INFO_STATE_CLEAR,
  };
};

const requestStoreContactUsFormInFirebase = () => {
  return {
    type: REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE,
  };
};

const requestStoreContactUsFormInFirebaseSuccess = () => {
  return {
    type: REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE_SUCCESS,
  };
};

const requestStoreContactUsFormInFirebaseError = (error) => {
  return {
    type: REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE_ERROR,
    error: error.message,
  };
};

const requestStoreContactUsFormInFirebaseStateClear = () => {
  return {
    type: REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE_STATE_CLEAR,
  };
};

export const saveNewProjectInFirestore = (fields) => (dispatch) => {
  dispatch(requestSaveNewProjectInFirestore());
  const promises = [];
  const {
    title,
    description,
    category,
    speciality,
    detailedDescription,
    additionalInformation,
    files,
    quoteTimeline,
    projectTimeline,
    timelineReason,
    budget,
    quoterLocation,
    questions,
  } = fields;
  const currentUserUid = myFirebase.auth().currentUser.uid;
  const timestamp = db.app.firebase_.firestore.FieldValue.serverTimestamp();
  let uploadedDocRef = null;
  let uploadPercentage = 0;
  const projectRef = db.collection('projects').doc();
  // db.collection('projects')
  //   .add({
  projectRef
    .set({
      uuid: projectRef.id,
      createdAt: timestamp,
      updatedAt: timestamp,
      isLive: false,
      isDraft: true,
      isPublished: false,
      isApprovedByAdmin: false,
      isRejectedByAdmin: false,
      isCancelled: false,
      isCompleted: false,
      owner: currentUserUid,
      title: title,
      description,
      category,
      speciality,
      detailedDescription,
      additionalInformation,
      uploadedDocuments: [],
      quoteTimeline,
      projectTimeline,
      timelineReason,
      budget,
      quoterLocation,
      questions,
    })
    .then((docRef) => {
      uploadedDocRef = projectRef;
      // console.log(docRef);
      db.collection('users')
        .doc(currentUserUid)
        .collection('projects')
        .doc(projectRef.id)
        .set(
          {
            title: title,
            description: description,
            detailedDescription: detailedDescription,
            projectDocument: projectRef,
            projectDocumentReference: projectRef.id,
            createdAt: timestamp,
            updatedAt: timestamp,
            isLive: false,
            isDraft: true,
            isPublished: false,
            isApprovedByAdmin: false,
            isRejectedByAdmin: false,
            isCancelled: false,
            isCompleted: false,
          },
          { merge: true }
        );
    })
    .then(() => {
      if (files.length > 0) {
        dispatch(requestSaveNewProjectInFirestoreUploadingDocuments(0));

        files.forEach((file) => {
          var ref = storageRef.child(
            `projectFiles/${uploadedDocRef.id}/quoteDocuments/${file.name}`
          );
          const uploadTask = ref.put(file);
          promises.push(uploadTask);
          // console.log(promises);
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              if (snapshot.state === 'running') {
                const progress10 = (prog) => {
                  const progressIn10ths = Math.floor(prog / 10) * 10;
                  if (uploadPercentage !== progressIn10ths) {
                    uploadPercentage = progressIn10ths;
                    dispatch(
                      requestSaveNewProjectInFirestoreUploadingDocuments(
                        uploadPercentage
                      )
                    );
                  }
                };
                progress10(progress);
              }
            },
            (error) => console.log(error.code),
            async () => {
              const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
              // do something with the url
              // console.log(downloadURL);
              // console.log(uploadTask.snapshot);
              // console.log(uploadTask.snapshot.ref);
              db.doc(uploadedDocRef.path).update({
                uploadedDocuments: fieldValue.arrayUnion({
                  name: file.name,
                  path: uploadTask.snapshot.ref.location.path,
                  downloadUrl: downloadURL,
                  fileSize: uploadTask.snapshot.totalBytes,
                  approved: true,
                }),
              });
            }
          );
        });
        Promise.all(promises)
          .then(() => {
            // alert('All files uploaded');
            dispatch(
              requestSaveNewProjectInFirestoreSuccess(uploadedDocRef.id)
            );
          })
          .catch((error) => {
            console.log(error.code);
            dispatch(requestSaveNewProjectInFirestoreError(error));
          });
      } else {
        // console.log('skipped file upload and dispatching anyway');
        dispatch(requestSaveNewProjectInFirestoreSuccess(uploadedDocRef.id));
      }
    })
    .catch((error) => {
      dispatch(requestSaveNewProjectInFirestoreError(error));
      console.error('Error adding document: ', error);
    });
};

export const saveNewProjectInFirestoreStateClear = () => (dispatch) => {
  // console.log('clearing state!');
  dispatch(requestSaveNewProjectInFirestoreStateClear());
};

export const uploadUserLogo = (file) => (dispatch) => {
  dispatch(requestUploadUserLogoInFirestore());
  const currentUserUid = myFirebase.auth().currentUser.uid;
  if (file != null) {
    var ref = storageRef.child(`userFiles/${currentUserUid}/logo/${file.name}`);
    ref
      .put(file)
      .then(function (snapshot) {
        // console.log(snapshot);
        snapshot.ref.getDownloadURL().then((url) => {
          // console.log(url);
          db.collection('users')
            .doc(currentUserUid)
            .update({ 'company.logoUrl': url })
            .then(() => {
              dispatch(requestUploadUserLogoInFirestoreSuccess());
              dispatch(getUserInfoFromFirestore());
              // history.push(ROUTES.HOME);
            });
        });
      })
      .catch((error) => {
        dispatch(requestUploadUserLogoInFirestoreError(error));
      });
  }
};

export const uploadUserLogoStateClear = () => (dispatch) => {
  console.log('clearing state!');
  dispatch(requestUploadUserLogoInFirestoreStateClear());
};

export const getAllUsersProjectsFromFirestore = () => (dispatch) => {
  dispatch(requestUsersProjectsFromFirestore());
  const currentUserUid = myFirebase.auth().currentUser.uid;
  const documentArray = [];
  db.collection('users')
    .doc(currentUserUid)
    .collection('projects')
    .orderBy('createdAt', 'desc')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        documentArray.push(doc.data());
      });
      dispatch(receiveUsersProjectsFromFirestore(documentArray));
    })
    .catch((error) => {
      if ((error = 'FirebaseError: Missing or insufficient permissions.')) {
        dispatch(receiveUsersProjectsFromFirestore([]));
      } else {
        dispatch(requestUsersProjectsFromFirestoreError(error));
      }
    });
};

export const getOneUsersProjectFromFirestore = (projectReference) => (
  dispatch
) => {
  dispatch(requestUsersProjectFromFirestore());
  db.collection('projects')
    .doc(projectReference)
    .get()
    .then((doc) => {
      if (doc.exists) {
        // console.log(doc.data());
        dispatch(receiveUsersProjectFromFirestore(doc.data()));
      } else {
        dispatch(
          requestUsersProjectFromFirestoreError('No such document found.')
        );
      }
    });
};

export const setProjectAsPublishedInFirestore = (projectRef) => (dispatch) => {
  dispatch(requestSetProjectAsPublishedInFirestore());
  const currentUserUid = myFirebase.auth().currentUser.uid;
  const timestamp = db.app.firebase_.firestore.FieldValue.serverTimestamp();

  db.collection('projects')
    .doc(projectRef)
    .update({
      updatedAt: timestamp,
      isDraft: false,
      isPublished: true,
      isCancelled: false,
    })
    .then(() => {
      // console.log(projectRef);
      db.collection('users')
        .doc(currentUserUid)
        .collection('projects')
        .doc(projectRef)
        .update({
          isDraft: false,
          isPublished: true,
          isCancelled: false,
        });
    })
    .then(() => {
      dispatch(requestSetProjectAsPublishedInFirestoreSuccess());
    })
    .catch((error) => {
      //TODO implement error handling
      console.log(error);
      dispatch(requestSetProjectAsPublishedInFirestoreError(error));
    });
};

export const setProjectAsPublishedInFirestoreStateClear = () => (dispatch) => {
  // console.log('clearing state!');
  dispatch(requestSetProjectAsPublishedInFirestoreStateClear());
};

export const updateFirestoreProject = (projectRef, fields) => (dispatch) => {
  dispatch(requestUpdateFirestoreDocument());
  const {
    title,
    description,
    category,
    speciality,
    detailedDescription,
    additionalInformation,
    files,
    quoteTimeline,
    projectTimeline,
    timelineReason,
    budget,
    quoterLocation,
    questions,
  } = fields;

  let promises = [];
  let uploadPercentage = 0;

  const currentUserUid = myFirebase.auth().currentUser.uid;
  const timestamp = db.app.firebase_.firestore.FieldValue.serverTimestamp();

  db.collection('projects')
    .doc(projectRef)
    .update({
      updatedAt: timestamp,
      isLive: false,
      isDraft: true,
      isPublished: false,
      isApprovedByAdmin: false,
      isRejectedByAdmin: false,
      isCancelled: false,
      isCompleted: false,
      owner: currentUserUid,
      title: title,
      description,
      category,
      speciality,
      detailedDescription,
      additionalInformation,
      quoteTimeline,
      projectTimeline,
      timelineReason,
      budget,
      quoterLocation,
      questions,
    })
    .then(() => {
      // console.log(projectRef);
      db.collection('users')
        .doc(currentUserUid)
        .collection('projects')
        .doc(projectRef)
        .update({
          title: title,
          description: description,
          detailedDescription: detailedDescription,
          updatedAt: timestamp,
          isLive: false,
          isDraft: true,
          isPublished: false,
          isApprovedByAdmin: false,
          isRejectedByAdmin: false,
          isCancelled: false,
          isCompleted: false,
        });
    })
    .then(() => {
      if (files.length > 0) {
        dispatch(requestSaveNewProjectInFirestoreUploadingDocuments(0));

        files.forEach((file) => {
          if (file instanceof File) {
            var ref = storageRef.child(
              `projectFiles/${projectRef}/quoteDocuments/${file.name}`
            );
            const uploadTask = ref.put(file);
            promises.push(uploadTask);
            // console.log(promises);
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                if (snapshot.state === 'running') {
                  // console.log(`Progress: ${progress}%`);
                  // console.log(`Progress on ${file.name}: ${progress}`);
                  const progress10 = (prog) => {
                    const progressIn10ths = Math.floor(prog / 10) * 10;
                    if (uploadPercentage !== progressIn10ths) {
                      uploadPercentage = progressIn10ths;
                      dispatch(
                        requestSaveNewProjectInFirestoreUploadingDocuments(
                          uploadPercentage
                        )
                      );
                    }
                  };
                  progress10(progress);
                }
              },
              (error) => console.log(error.code),
              async () => {
                const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
                // do something with the url
                // console.log(downloadURL);
                // console.log(uploadTask.snapshot);
                // console.log(uploadTask.snapshot.ref);
                db.collection('projects')
                  .doc(projectRef)
                  .update({
                    uploadedDocuments: fieldValue.arrayUnion({
                      name: file.name,
                      path: uploadTask.snapshot.ref.location.path,
                      downloadUrl: downloadURL,
                      fileSize: uploadTask.snapshot.totalBytes,
                      approved: true,
                    }),
                  });
              }
            );
          }
        });
        Promise.all(promises)
          .then(() => {
            // alert('All files uploaded');
            dispatch(requestSaveNewProjectInFirestoreSuccess(projectRef));
          })
          .catch((error) => {
            console.log(error.code);
            dispatch(requestSaveNewProjectInFirestoreError(error));
          });
      } else {
        // console.log('skipped file upload and dispatching anyway');
        dispatch(requestSaveNewProjectInFirestoreSuccess(projectRef));
      }
    })
    .catch((error) => {
      dispatch(requestSaveNewProjectInFirestoreError(error));
      console.error('Error adding document: ', error);
    });
};

// TODO - Check if this is still used
export const updateFirestoreProjectDocuments = ({
  owner,
  document,
  fields,
}) => (dispatch) => {
  dispatch(requestUpdateFirestoreDocument());
  db.collection('projects')
    .doc(document)
    .set(fields, { merge: true })
    .then(() => {
      db.collection('users')
        .doc(owner)
        .collection('projects')
        .doc(document)
        .set(fields, { merge: true })
        .then(() => {
          dispatch(getOneUsersProjectFromFirestore(document));
        });
    })
    .catch((error) => {
      //TODO: Handle Error
      console.error(error);
    });
};

export const getUserInfoFromFirestore = () => (dispatch) => {
  dispatch(requestUserInfoFromFirestore());
  const currentUserUid = myFirebase.auth().currentUser.uid;
  if (currentUserUid != null) {
    db.collection('users')
      .doc(currentUserUid)
      .get()
      .then((userRecord) => {
        if (userRecord.exists) {
          // console.log(userRecord);
          dispatch(receiveUserInfoFromFirestore(userRecord.data()));
          // console.log(userRecord.data());
        } else {
          dispatch(
            requestUserInfoFromFirestoreError(
              'No such user document found in database!'
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(requestUserInfoFromFirestoreError(error));
      });
  }
};

export const updateFirestoreUserInfo = (owner, fields) => (dispatch) => {
  dispatch(requestUpdateFirestoreUser());
  // console.log('updating user record');
  // db.collection('users')
  //   .doc(owner)
  //   .set(fields, { merge: true })
  //   .then(() => {
  //     db.collection('users')
  //       .doc(owner)
  //       .set(fields, { merge: true })
  //       .then(() => {
  //         dispatch(requestUpdateFirestoreUserSuccess());
  //       });
  //   })
  db.collection('users')
    .doc(owner)
    .set(fields, { merge: true })
    .then(() => {
      dispatch(requestUpdateFirestoreUserSuccess());
    })
    .catch((error) => {
      //TODO: Handle Error
      console.error(error);
      requestUpdateFirestoreUserError(error);
    });
};

export const updatingUserRecordInFirestoreStateClear = () => (dispatch) => {
  console.log('clearing state!');
  dispatch(requestUpdateFirestoreUserStateClear());
};

export const updateFirestoreUserAsInitialised = () => (dispatch) => {
  dispatch(requestUpdateFirestoreUser());
  console.log('updating user record');
  const currentUserUid = myFirebase.auth().currentUser.uid;

  db.collection('users')
    .doc(currentUserUid)
    .set({ userInitialised: true }, { merge: true })
    .then(() => {
      dispatch(requestUpdateFirestoreUserSuccess());
    })
    .catch((error) => {
      //TODO: Handle Error
      console.error(error);
      requestUpdateFirestoreUserError(error);
    });
};

// Helper method to order lists of projects by updated at date
function compareValues(key, order = 'asc') {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
}

// Helper method for splitting lists into lists of 10 so that the firebase limit is not met
const splitListInto10s = (list) => {
  const returnList = [];

  if (list.length < 10) {
    returnList[0] = list;
  } else {
    const splitTimes = Math.ceil(list.length / 10);
    for (let i = 1; i <= splitTimes; i++) {
      const start = (i - 1) * 10;
      const end = i * 10;
      returnList.push(list.slice(start, end));
    }
  }

  return returnList;
};

export const adminGetProjectOwnersInfo = (ownersList) => async (dispatch) => {
  dispatch(requestAdminGetProjectOwnersInfo());
  console.log('getting owners info');
  const owners = {};
  const queryList = splitListInto10s(ownersList);

  const promises = await queryList.map(async (listOfOwners) => {
    const snapshot = await db
      .collection('users')
      .where('uuid', 'in', listOfOwners)
      .get();

    if (snapshot.empty) {
      // TODO Error handling - less important as only for admins
      dispatch(
        requestAdminGetProjectOwnersInfoError('Search returned nothing')
      );
      console.log('no owners found');
      return;
    }
    snapshot.forEach((doc) => {
      owners[doc.id] = doc.data();
    });
  });

  Promise.all(promises).then(() => {
    // console.log(owners);
    // return owners;
    dispatch(requestAdminGetProjectOwnersInfoSuccess(owners));
  });
};

export const adminGetProjectOwnersInfoStateClear = () => (dispatch) => {
  // console.log('clearing state!');
  dispatch(requestAdminGetProjectOwnersInfoStateClear());
};

export const adminGetDraftProjects = () => async (dispatch) => {
  dispatch(requestAdminDraftProjects());

  const snapshot = await db
    .collection('projects')
    .where('isDraft', '==', true)
    .where('isApprovedByAdmin', '==', false)
    .get();

  if (snapshot.empty) {
    dispatch(requestAdminDraftProjectsError('Search returned nothing'));
    console.log('no draft projects found');
    return;
  }

  const draftProjects = [];
  const draftProjectOwners = [];
  snapshot.forEach((doc) => {
    // console.log(doc.id, '=>', doc.data());
    draftProjects.push(doc.data());
    draftProjectOwners.push(doc.data().owner);
    // let date = new Date(doc.data().createdAt / 1000);
    // console.log(date);
  });
  // dispatch(adminGetProjectOwnersInfo(draftProjectOwners));
  dispatch(adminGetProjectOwnersInfo(draftProjectOwners));
  dispatch(
    requestAdminDraftProjectsSuccess(
      draftProjects.sort(compareValues('updatedAt'))
    )
  );
};

export const adminGetDraftProjectsStateClear = () => (dispatch) => {
  // console.log('clearing state!');
  dispatch(requestAdminDraftProjectsStateClear());
};

export const adminGetPublishedProjects = () => async (dispatch) => {
  dispatch(requestAdminPublishedProjects());

  const snapshot = await db
    .collection('projects')
    .where('isPublished', '==', true)
    .where('isApprovedByAdmin', '==', false)
    .where('isLive', '==', false)
    .get();

  if (snapshot.empty) {
    dispatch(requestAdminPublishedProjectsError('Search returned nothing'));
    console.log('no published projects found');
    return;
  }

  const publishedProjects = [];
  const publishedProjectOwners = [];
  snapshot.forEach((doc) => {
    publishedProjects.push(doc.data());
    publishedProjectOwners.push(doc.data().owner);
  });
  dispatch(adminGetProjectOwnersInfo(publishedProjectOwners));
  dispatch(
    requestAdminPublishedProjectsSuccess(
      publishedProjects.sort(compareValues('updatedAt'))
    )
  );
};

export const adminGetPublishedProjectsStateClear = () => (dispatch) => {
  // console.log('clearing state!');
  dispatch(requestAdminPublishedProjectsStateClear());
};

export const adminGetRejectedProjects = () => async (dispatch) => {
  dispatch(requestAdminRejectedProjects());

  const snapshot = await db
    .collection('projects')
    .where('isPublished', '==', false)
    .where('isRejectedByAdmin', '==', true)
    .where('isLive', '==', false)
    .get();

  if (snapshot.empty) {
    dispatch(requestAdminRejectedProjectsError('Search returned nothing'));
    console.log('no published projects found');
    return;
  }

  const rejectedProjects = [];
  const rejectedProjectOwners = [];
  snapshot.forEach((doc) => {
    rejectedProjects.push(doc.data());
    rejectedProjectOwners.push(doc.data().owner);
  });
  dispatch(adminGetProjectOwnersInfo(rejectedProjectOwners));
  dispatch(
    requestAdminRejectedProjectsSuccess(
      rejectedProjects.sort(compareValues('updatedAt'))
    )
  );
};

export const adminGetRejectedProjectsStateClear = () => (dispatch) => {
  // console.log('clearing state!');
  dispatch(requestAdminRejectedProjectsStateClear());
};

export const adminGetPendingGoLiveProjects = () => async (dispatch) => {
  dispatch(requestAdminPendingGoLiveProjects());

  const snapshot = await db
    .collection('projects')
    .where('isPublished', '==', true)
    .where('isApprovedByAdmin', '==', true)
    .where('isLive', '==', false)
    .get();

  if (snapshot.empty) {
    dispatch(requestAdminPendingGoLiveProjectsError('Search returned nothing'));
    console.log('no pending go live projects found');
    return;
  }

  const pendingGoLiveProjects = [];
  const pendingGoLiveProjectOwners = [];
  snapshot.forEach((doc) => {
    pendingGoLiveProjects.push(doc.data());
    pendingGoLiveProjectOwners.push(doc.data().owner);
  });
  dispatch(adminGetProjectOwnersInfo(pendingGoLiveProjectOwners));
  dispatch(
    requestAdminPendingGoLiveProjectsSuccess(
      pendingGoLiveProjects.sort(compareValues('updatedAt'))
    )
  );
};

export const adminGetPendingGoLiveProjectsStateClear = () => (dispatch) => {
  // console.log('clearing state!');
  dispatch(requestAdminPendingGoLiveProjectsStateClear());
};

export const adminSetProjectAsApprovedInFirestore = (projectRef, ownerUid) => (
  dispatch
) => {
  dispatch(requestAdminSetProjectAsApprovedInFirestore());
  const timestamp = db.app.firebase_.firestore.FieldValue.serverTimestamp();

  db.collection('projects')
    .doc(projectRef)
    .update({
      isApprovedByAdmin: true,
      isRejectedByAdmin: false,
      updatedAt: timestamp,
    })
    .then(() => {
      // console.log(projectRef);
      db.collection('users')
        .doc(ownerUid)
        .collection('projects')
        .doc(projectRef)
        .update({
          isApprovedByAdmin: true,
          isRejectedByAdmin: false,
          updatedAt: timestamp,
        });
    })
    .then(() => {
      dispatch(requestAdminSetProjectAsApprovedInFirestoreSuccess());
    })
    .catch((error) => {
      //TODO implement error handling
      console.log(error);
      dispatch(requestAdminSetProjectAsApprovedInFirestoreError(error));
    });
};

export const adminSetProjectAsApprovedInFirestoreStateClear = () => (
  dispatch
) => {
  // console.log('clearing state!');
  dispatch(requestAdminSetProjectAsApprovedInFirestoreStateClear());
};

export const adminSetProjectAsRejectedInFirestore = (
  projectRef,
  ownerUid,
  rejectionReason
) => (dispatch) => {
  dispatch(requestAdminSetProjectAsRejectedInFirestore());
  const timestamp = db.app.firebase_.firestore.FieldValue.serverTimestamp();

  db.collection('projects')
    .doc(projectRef)
    .update({
      isApprovedByAdmin: false,
      isRejectedByAdmin: true,
      rejectionReason: rejectionReason,
      updatedAt: timestamp,
    })
    .then(() => {
      // console.log(projectRef);
      db.collection('users')
        .doc(ownerUid)
        .collection('projects')
        .doc(projectRef)
        .update({
          isApprovedByAdmin: false,
          isRejectedByAdmin: true,
          rejectionReason: rejectionReason,
          updatedAt: timestamp,
        });
    })
    .then(() => {
      dispatch(requestAdminSetProjectAsRejectedInFirestoreSuccess());
    })
    .catch((error) => {
      //TODO implement error handling
      console.log(error);
      dispatch(requestAdminSetProjectAsRejectedInFirestoreError(error));
    });
};

export const adminSetProjectAsRejectedInFirestoreStateClear = () => (
  dispatch
) => {
  // console.log('clearing state!');
  dispatch(requestAdminSetProjectAsRejectedInFirestoreStateClear());
};

export const adminGetStatistics = () => async (dispatch) => {
  dispatch(requestAdminGetStatistics());
  let userCount = 0;
  let projectCount = 0;
  let draftProjects = 0;
  let liveProjects = 0;
  let completedProjects = 0;
  let quotes = '/';

  const promises = [];

  const usersSnapshot = await db.collection('users').get();
  promises.push(usersSnapshot);
  if (!usersSnapshot.empty) {
    userCount = usersSnapshot.docs.length;
  }

  const projectsSnapshot = await db.collection('projects').get();
  promises.push(projectsSnapshot);
  if (!projectsSnapshot.empty) {
    projectCount = projectsSnapshot.docs.length;
  }

  const draftSnapshot = await db
    .collection('projects')
    .where('isDraft', '==', true)
    .get();
  promises.push(draftSnapshot);
  if (!draftSnapshot.empty) {
    console.log(draftSnapshot);
    draftProjects = draftSnapshot.docs.length;
  }

  const liveSnapshot = await db
    .collection('projects')
    .where('isLive', '==', true)
    .get();
  promises.push(liveSnapshot);
  if (!liveSnapshot.empty) {
    liveProjects = liveSnapshot.docs.length;
  }

  const completedSnapshot = await db
    .collection('projects')
    .where('isCompleted', '==', true)
    .get();
  promises.push(completedSnapshot);
  if (!completedSnapshot.empty) {
    completedProjects = completedSnapshot.docs.length;
  }

  const statistics = {
    userCount,
    projectCount,
    draftProjects,
    liveProjects,
    completedProjects,
    quotes,
  };

  Promise.all(promises)
    .then(() => {
      dispatch(requestAdminGetStatisticsSuccess(statistics));
      console.log(statistics);
    })
    .catch((error) => {
      console.log(error);
      dispatch(requestAdminGetStatisticsError(error));
    });
};

export const adminGetStatisticsStateClear = () => (dispatch) => {
  dispatch(requestAdminGetStatisticsStateClear());
};

export const adminGetUserInfoFromFirestore = (user) => (dispatch) => {
  dispatch(requestAdminUserInfoFromFirestore());
  // const currentUserUid = myFirebase.auth().currentUser.uid;
  if (user != null) {
    db.collection('users')
      .doc(user)
      .get()
      .then((userRecord) => {
        if (userRecord.exists) {
          // console.log(userRecord);
          dispatch(requestAdminUserInfoFromFirestoreSuccess(userRecord.data()));
          // console.log(userRecord.data());
        } else {
          dispatch(
            requestAdminUserInfoFromFirestoreError({
              message: 'No such user document found in database!',
            })
          );
        }
      })
      .catch(function (error) {
        dispatch(requestAdminUserInfoFromFirestore(error));
      });
  }
};

export const adminGetUserInfoFromFirestoreStateClear = () => (dispatch) => {
  dispatch(requestAdminUserInfoFromFirestoreStateClear());
};

export const storeUnregisteredContactUsRequestInFirebase = (fields) => (
  dispatch
) => {
  const {
    firstName,
    lastName,
    title,
    company,
    email,
    phone,
    message,
    method,
  } = fields;

  const timestamp = db.app.firebase_.firestore.FieldValue.serverTimestamp();
  dispatch(requestStoreContactUsFormInFirebase());
  const docRef = db.collection('contactForms').doc();
  docRef
    .set({
      firstName,
      lastName,
      title,
      company,
      email,
      phone,
      message,
      method,
      uploadedAt: timestamp,
    })
    .then(() => {
      dispatch(requestStoreContactUsFormInFirebaseSuccess());
    })
    .catch((error) => {
      dispatch(requestStoreContactUsFormInFirebaseError(error));
    });
};

export const storeUnregisteredContactUsRequestInFirebaseStateClear = () => (
  dispatch
) => {
  dispatch(requestStoreContactUsFormInFirebaseStateClear());
};
