import React from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Container,
  Dimmer,
  Form,
  Grid,
  Header,
  Icon,
  Loader,
  Message,
  Popup,
  Segment,
} from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  getUserInfoFromFirestore,
  saveNewProjectInFirestore,
  saveNewProjectInFirestoreStateClear,
  updateFirestoreProject,
} from '../../actions';
import history from '../../history';
import * as ROUTES from '../../constants/routes';

import Buttons from './Buttons';
import Wizard from './Wizard';
import Progress from './Progress';
// import { head } from 'lodash';
// import { PROFILE_EDIT } from '../../constants/routes';

const initialFormikValues = {
  title: '',
  description: '',
  category: '',
  speciality: '',
  detailedDescription: '',
  additionalInformation: '',
  files: [],
  quoteTimeline: '',
  projectTimeline: '',
  timelineReason: '',
  budget: '',
  quoterLocation: '',
  questions: [],
};

const yupValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(10, 'Title must be at least 10 characters')
    .required('A Project Title is required.'),
  description: Yup.string()
    .min(20, 'Description must be at least 20 characters.')
    .required('A brief description is required'),
  category: Yup.string().required(
    'You must pick a category of work. If you are unsure select "Other" '
  ),
  speciality: Yup.string().required(
    'You must pick a speciality of work. If you are unsure select "Other" '
  ),
  detailedDescription: Yup.string()
    .min(
      50,
      'Detailed description of project must be at least 50 characters - more is better!'
    )
    .required('Detailed Description is required'),
  additionalInformation: Yup.string().max(
    1000,
    'Additional information must be less than 1000 characters.'
  ),
  quoteTimeline: Yup.string().required(
    'You must pick a project timeline. If you are unsure select "Any time" '
  ),
  projectTimeline: Yup.string().required(
    'You must pick a project timeline. If you are unsure select "More than 1 year" '
  ),
  timelineReason: Yup.string()
    .required(
      'You must provide information about why this timeline has been set.'
    )
    .min(10, 'Your timeline reason must be at least 10 characters'),
  budget: Yup.number(
    'Your budget must be an integer. E.g. 1000 - not One Thousand or 1,000'
  )
    .typeError(
      'Your budget must be an integer. E.g. 1000 - not One Thousand or 1,000'
    )
    .positive('Your budget must be a positive number.')
    .integer(
      'Your budget must be a whole integer. E.g. 1000 - not One Thousand, or 1,000, or 1000.01'
    )
    .notOneOf([0], 'Your budget cannot be £0')
    .required(
      'You must approximate the budget for this project. Try to be as realistic as possible.'
    ),
  quoterLocation: Yup.string().required(
    'You must pick a desired distance for your quoters. If you are unsure select a large area such as "UK or Worldwide" '
  ),
});

class NewProject extends React.Component {
  state = {
    files: [],
    page: 0,
    questions: [],
    upTo: this.props.continue ? 4 : 0,
    initialised: false,
  };
  componentDidMount() {
    this.props.getUserInfoFromFirestore();
    this.props.saveNewProjectInFirestoreStateClear();
    setTimeout(() => {
      this.setState({ initialised: true });
    }, 1000);
    // if (this.props.continue) {
    //   console.log('Continuing');
    // }
  }
  loaded = false;

  syncInitialValues = (setFieldValue, setValues, values) => {
    // console.log(this.props);
    if (this.props.project) {
      if ('title' in this.props.project) {
        if (this.loaded === false) {
          this.loaded = true;
          const title =
            this.props.project.title === undefined
              ? ''
              : this.props.project.title;
          const description =
            this.props.project.description === undefined
              ? ''
              : this.props.project.description;
          const category =
            this.props.project.category === undefined
              ? ''
              : this.props.project.category;
          const speciality =
            this.props.project.speciality === undefined
              ? ''
              : this.props.project.speciality;
          const detailedDescription =
            this.props.project.detailedDescription === undefined
              ? ''
              : this.props.project.detailedDescription;
          const additionalInformation =
            this.props.project.additionalInformation === undefined
              ? ''
              : this.props.project.additionalInformation;
          const files =
            this.props.project.uploadedDocuments === undefined
              ? []
              : this.props.project.uploadedDocuments;
          const quoteTimeline =
            this.props.project.quoteTimeline === undefined
              ? ''
              : this.props.project.quoteTimeline;
          const projectTimeline =
            this.props.project.projectTimeline === undefined
              ? ''
              : this.props.project.projectTimeline;
          const timelineReason =
            this.props.project.timelineReason === undefined
              ? ''
              : this.props.project.timelineReason;

          const budget =
            this.props.project.budget === undefined
              ? ''
              : this.props.project.budget;

          const quoterLocation =
            this.props.project.quoterLocation === undefined
              ? ''
              : this.props.project.quoterLocation;
          const questions =
            this.props.project.questions === undefined
              ? []
              : this.props.project.questions;
          // console.log('setting values!');
          setValues({
            title,
            description,
            category,
            speciality,
            detailedDescription,
            additionalInformation,
            files,
            quoteTimeline,
            projectTimeline,
            timelineReason,
            budget,
            quoterLocation,
            questions,
          });
          this.setState({ upTo: 3 });
        }
      }
    }
  };

  setFilesForUpload = (uploadedFiles) => {
    this.setState({ files: uploadedFiles });
    // console.log(this.state);
  };

  setQuestionsForUpload = (questionList) => {
    this.setState({ questions: questionList });
    // console.log(this.state);
  };

  nextPage = () => {
    if (this.state.page === this.state.upTo) {
      this.setState({ page: this.state.page + 1, upTo: this.state.upTo + 1 });
    } else {
      this.setState({ page: this.state.page + 1 });
    }
  };

  goToPage = (pageNumber) => {
    this.setState({ page: pageNumber });
  };

  generateUploadingDimmerText = () => {
    if (this.props.savingNewProjectInFirestoreUploadingDocuments) {
      return `Uploading Documents . . . ${this.props.savingNewProjectInFirestoreUploadingDocumentsPercentage}%`;
    } else if (this.props.savingNewProjectInFirestore) {
      return 'Uploading new project information . . .';
    }
  };

  handleRedirect() {
    if (
      this.props.savingNewProjectInFirestoreSuccess &&
      this.state.initialised
    ) {
      const redirectRef = this.props.projectRef
        ? this.props.projectRef
        : this.props.savingNewProjectInFirestoreProjectRef;
      let routePrefix = this.props.continue
        ? ROUTES.SHOW_PROJECT_PREFIX
        : ROUTES.NEW_PROJECT_THANK_YOU_PREFIX;

      setTimeout(() => {
        history.push(`${routePrefix}${redirectRef}`);
      }, 2000);
      return (
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>New Project successfully uploaded!</Message.Header>
            Redirecting you to the project page . . .
          </Message.Content>
        </Message>
      );
    }
  }

  render() {
    return (
      <Container text>
        <Dimmer
          active={
            this.props.savingNewProjectInFirestore ||
            this.props.savingNewProjectInFirestoreUploadingDocuments
          }
          inverted
        >
          <Loader size="large" inverted>
            {this.generateUploadingDimmerText()}
          </Loader>
        </Dimmer>
        <br />
        <Formik
          initialValues={initialFormikValues}
          validationSchema={yupValidationSchema}
          onSubmit={(fields) => {
            if (this.props.continue) {
              this.props.updateFirestoreProject(this.props.projectRef, fields);
            } else {
              this.props.saveNewProjectInFirestore(fields);
            }
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            setValues,
          }) => {
            this.syncInitialValues(setFieldValue, setValues, values);
            const headerText = this.props.continue
              ? 'Edit project'
              : 'Create a New Project';
            return (
              <Grid
                textAlign="center"
                verticalAlign="middle"
                key={this.state.page}
              >
                <Grid.Column>
                  <Form size="small">
                    <Segment stacked>
                      <Header as="h2" textAlign="center">
                        <Popup
                          content="Click here to open our full guide on what to includegit "
                          trigger={
                            <Button
                              floated="right"
                              href={`https://machinebuilders.co.uk${ROUTES.BLOG_POST_200801}`}
                              target="_blank"
                            >
                              <Icon
                                name="help"
                                size="large"
                                style={{ margin: '0 0 0' }}
                              />
                            </Button>
                          }
                        />

                        {headerText}
                      </Header>
                      <Progress
                        page={this.state.page}
                        upTo={this.state.upTo}
                        goToPage={this.goToPage}
                        continue={this.props.continue}
                        errors={errors}
                      />
                      <Wizard
                        page={this.state.page}
                        touched={touched}
                        handleChange={handleChange}
                        errors={errors}
                        handleBlur={handleBlur}
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        setFilesForUpload={this.setFilesForUpload}
                        setQuestionsForUpload={this.setQuestionsForUpload}
                      />
                      <Buttons
                        page={this.state.page}
                        nextPage={this.nextPage}
                        handleSubmit={handleSubmit}
                        errors={errors}
                        touched={touched}
                        setFieldTouched={setFieldTouched}
                      />
                    </Segment>
                  </Form>
                </Grid.Column>
              </Grid>
            );
          }}
        />
        {this.handleRedirect()}
      </Container>
    );
  }
}

//TODO Clarify if userFirst Name and logout error are required for the new project fields. My gut tells me I added this as a copy paste job at the start and they aren't in fact required.

function mapStateToProps(state) {
  return {
    userFirstName: state.firestoreReducers.userRecord.firstName,
    logoutError: state.auth.logoutError,
    savingNewProjectInFirestore:
      state.firestoreReducers.savingNewProjectInFirestore,
    savingNewProjectInFirestoreUploadingDocuments:
      state.firestoreReducers.savingNewProjectInFirestoreUploadingDocuments,
    savingNewProjectInFirestoreUploadingDocumentsPercentage:
      state.firestoreReducers
        .savingNewProjectInFirestoreUploadingDocumentsPercentage,
    savingNewProjectInFirestoreSuccess:
      state.firestoreReducers.savingNewProjectInFirestoreSuccess,
    savingNewProjectInFirestoreError:
      state.firestoreReducers.savingNewProjectInFirestoreError,
    savingNewProjectInFirestoreErrorMessage:
      state.firestoreReducers.savingNewProjectInFirestoreErrorMessage,
    savingNewProjectInFirestoreProjectRef:
      state.firestoreReducers.savingNewProjectInFirestoreProjectRef,
  };
}

export default connect(mapStateToProps, {
  getUserInfoFromFirestore,
  saveNewProjectInFirestore,
  saveNewProjectInFirestoreStateClear,
  updateFirestoreProject,
})(NewProject);
