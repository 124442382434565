import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Container, Header, Icon, Image } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

import * as ROUTES from '../constants/routes';

class NewProjectThankYou extends React.Component {
  render() {
    const { projectRef } = this.props.match.params;
    return (
      <Container text>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Thank You</title>
          <meta
            name="description"
            content="Thank you for posting your new project on machine builders"
          />
          <script>{`
          (function() {
            if(typeof(jQuery)=='undefined')
            {
              var jScript = document.createElement('script'); jScript.type = 'text/javascript';
              jScript.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'www.googletagmanager.com/gtag/js?id=AW-595125483';
              var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(jScript, s);
            }
          })();

          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-595125483');
          gtag('event', 'conversion', {'send_to': 'AW-595125483/V_yfCJ-vod8BEOvJ45sC'});
          `}</script>
        </Helmet>
        <Header
          as="h1"
          content="Thank You"
          style={{
            fontSize: '4em',
            fontWeight: 'normal',
            marginBottom: 0,
            marginTop: '0.8em',
          }}
        />
        <Header
          as="h2"
          content="Thank you for posting your new project!"
          style={{
            fontSize: '1.7em',
            fontWeight: 'normal',
            marginTop: 0,
            marginBottom: '1em',
          }}
        />
        <img
          class="ui centered medium image"
          src="https://firebasestorage.googleapis.com/v0/b/machine-builders.appspot.com/o/staticFiles%2F512.png?alt=media&token=87c463a0-133e-46da-a07c-9fea7d0b7561"
          alt="Machine builders logo"
        />
        <br />

        <p>Thank you for submitting a draft version of your project!</p>
        <p>
          Click on the button below to view your project. If you're happy with
          it please publish it and we will review it for publishing on our
          platform to machine builders.
        </p>
        <Button
          as={Link}
          to={`${ROUTES.SHOW_PROJECT_PREFIX}${projectRef}`}
          color="teal"
          fluid
          size="large"
        >
          View Project
          <Icon name="right arrow" />
        </Button>
      </Container>
    );
  }
}

export default NewProjectThankYou;
