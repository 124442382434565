import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Button,
  Card,
  Container,
  Dimmer,
  Grid,
  Header,
  // Icon,
  Image,
  Loader,
  Placeholder,
  Responsive,
  Segment,
} from 'semantic-ui-react';

import * as ROUTES from '../../constants/routes';
import { getUserInfoFromFirestore } from '../../actions';

import ProfileImageDimmer from './ProfileImageDimmer';

class ProfileDisplay extends React.Component {
  state = {};

  componentDidMount() {
    this.props.getUserInfoFromFirestore();
  }

  profileLoadingPlaceholder() {
    return (
      <Segment>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </Segment>
    );
  }

  nextStepCard() {
    return (
      <Card>
        <Card.Content>
          <Image
            floated='right'
            size='mini'
            src='https://react.semantic-ui.com/images/avatar/large/jenny.jpg'
          />
          <Card.Header>Jenny Lawrence</Card.Header>
          <Card.Meta>New User</Card.Meta>
          <Card.Description>
            Jenny requested permission to view your contact details
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <div className='ui two buttons'>
            <Button basic color='green'>
              Approve
            </Button>
            <Button basic color='red'>
              Decline
            </Button>
          </div>
        </Card.Content>
      </Card>
    );
  }
  handleWindowResize = (e, { width }) => {
    this.setState({ windowWidth: width });
    // const windowWidth = window != undefined ? window.innerWidth : 0;
    // const smallEnoughToStack = 600 < windowWidth;
    // console.log(windowWidth);
    // console.log(Responsive.getWidth);
    // console.log(smallEnoughToStack);
  };

  generateCompanyInfo() {
    if (this.props.userFirstName) {
      const userName = this.props.userFirstName + ' ' + this.props.userLastName;
      const CompanyName = this.props.userCompanyRecord.companyName;
      const CompanyWebsite = this.props.userCompanyRecord.companyWebsite;
      const CompanyAddressL1 = this.props.userCompanyRecord.address.line1;
      const CompanyAddressL2 = this.props.userCompanyRecord.address.line2;
      const CompanyAddressL3 = this.props.userCompanyRecord.address.town;
      const CompanyAddressL4 = this.props.userCompanyRecord.address.postCode;
      const email = this.props.userEmail;
      const landline = this.props.userLandline;
      const mobile = this.props.userMobile;
      const logoUrl = this.props.userCompanyRecord.logoUrl;

      const smallEnoughToStack = 525 > this.state.windowWidth;

      return (
        <Responsive fireOnMount onUpdate={this.handleWindowResize}>
          <Grid columns={2} stackable={smallEnoughToStack}>
            <Grid.Row>
              {/* <Responsive as={Grid.Column} minWidth={525}>
                <Header as='h6'>Company Logo</Header>
                <ProfileImageDimmer logoUrl={logoUrl} />
              </Responsive> */}
              <Grid.Column>
                <ProfileImageDimmer logoUrl={logoUrl} />
              </Grid.Column>
              <Grid.Column>
                {/* <Container text> */}
                <Header as='h6'>
                  Name
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    {userName}{' '}
                  </Header.Subheader>
                </Header>

                <Header as='h6'>
                  Email
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    {email}
                  </Header.Subheader>
                </Header>
                <Header as='h6'>
                  Landline
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    {landline}
                  </Header.Subheader>
                </Header>
                <Header as='h6'>
                  Mobile
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    {mobile}
                  </Header.Subheader>
                </Header>
                <Header as='h6'>
                  Company Name
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    {CompanyName}
                  </Header.Subheader>
                </Header>
                <Header as='h6'>
                  Company Website
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    <a href={CompanyWebsite}>{CompanyWebsite}</a>
                  </Header.Subheader>
                </Header>
                <Header as='h6'>
                  Company Address
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    {CompanyAddressL1}
                  </Header.Subheader>
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    {CompanyAddressL2}
                  </Header.Subheader>
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    {CompanyAddressL3}
                  </Header.Subheader>
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    {CompanyAddressL4}
                  </Header.Subheader>
                </Header>

                {/* </Container> */}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Responsive>
      );
    } else {
      return this.profileLoadingPlaceholder();
    }
  }

  render() {
    return (
      <Container fluid>
        <Segment.Group raised>
          <Segment>
            <Header>
              <Button floated='right' as={Link} to={`${ROUTES.PROFILE_EDIT}`}>
                Edit
              </Button>
              My Profile
              <Header.Subheader>
                {/* TODO: Maybe add a pop up 'why is this required' section? */}
                Ensure this is accurate so you can be contacted
              </Header.Subheader>
            </Header>
            {this.generateCompanyInfo()}
          </Segment>
        </Segment.Group>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    userFirstName: state.firestoreReducers.userRecord.firstName,
    userLastName: state.firestoreReducers.userRecord.lastName,
    userCompanyRecord: state.firestoreReducers.userRecord.company,
    userEmail: state.firestoreReducers.userRecord.email,
    userLandline: state.firestoreReducers.userRecord.landline,
    userMobile: state.firestoreReducers.userRecord.mobile,
  };
}

export default connect(mapStateToProps, {
  getUserInfoFromFirestore,
})(ProfileDisplay);
