import {
  GETTING_USER_INFO_FROM_FIRESTORE,
  GOT_USER_INFO_FROM_FIRESTORE,
  GETTING_USER_INFO_FROM_FIRESTORE_ERROR,
  SAVING_NEW_PROJECT_IN_FIRESTORE,
  SAVING_NEW_PROJECT_IN_FIRESTORE_UPLOADING_DOCUMENTS,
  SAVING_NEW_PROJECT_IN_FIRESTORE_SUCCESS,
  SAVING_NEW_PROJECT_IN_FIRESTORE_ERROR,
  SAVING_NEW_PROJECT_IN_FIRESTORE_STATE_CLEAR,
  REQUEST_USERS_PROJECTS_FROM_FIRESTORE,
  RECEIVE_USERS_PROJECTS_FROM_FIRESTORE,
  REQUEST_USERS_PROJECTS_FROM_FIRESTORE_ERROR,
  REQUEST_USERS_PROJECT_FROM_FIRESTORE,
  RECEIVE_USERS_PROJECT_FROM_FIRESTORE,
  REQUEST_USERS_PROJECT_FROM_FIRESTORE_ERROR,
  REQUEST_UPDATE_USER_IN_FIRESTORE,
  REQUEST_UPDATE_USER_IN_FIRESTORE_SUCCESS,
  REQUEST_UPDATE_USER_IN_FIRESTORE_ERROR,
  REQUEST_UPDATE_USER_IN_FIRESTORE_STATE_CLEAR,
  REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE,
  REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE_SUCCESS,
  REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE_ERROR,
  REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE_STATE_CLEAR,
  REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE,
  REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE_SUCCESS,
  REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE_ERROR,
  REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE_STATE_CLEAR,
  REQUEST_ADMIN_DRAFT_PROJECTS,
  REQUEST_ADMIN_DRAFT_PROJECTS_SUCCESS,
  REQUEST_ADMIN_DRAFT_PROJECTS_ERROR,
  REQUEST_ADMIN_DRAFT_PROJECTS_STATE_CLEAR,
  REQUEST_ADMIN_PUBLISHED_PROJECTS,
  REQUEST_ADMIN_PUBLISHED_PROJECTS_SUCCESS,
  REQUEST_ADMIN_PUBLISHED_PROJECTS_ERROR,
  REQUEST_ADMIN_PUBLISHED_PROJECTS_STATE_CLEAR,
  REQUEST_ADMIN_REJECTED_PROJECTS,
  REQUEST_ADMIN_REJECTED_PROJECTS_SUCCESS,
  REQUEST_ADMIN_REJECTED_PROJECTS_ERROR,
  REQUEST_ADMIN_REJECTED_PROJECTS_STATE_CLEAR,
  REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO,
  REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO_SUCCESS,
  REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO_ERROR,
  REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO_STATE_CLEAR,
  REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE,
  REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE_SUCCESS,
  REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE_ERROR,
  REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE_STATE_CLEAR,
  REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE,
  REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE_SUCCESS,
  REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE_ERROR,
  REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE_STATE_CLEAR,
  REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS,
  REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS_SUCCESS,
  REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS_ERROR,
  REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS_STATE_CLEAR,
  REQUEST_ADMIN_GET_STATISTICS,
  REQUEST_ADMIN_GET_STATISTICS_SUCCESS,
  REQUEST_ADMIN_GET_STATISTICS_ERROR,
  REQUEST_ADMIN_GET_STATISTICS_STATE_CLEAR,
  REQUEST_ADMIN_GET_USER_INFO,
  REQUEST_ADMIN_GET_USER_INFO_SUCCESS,
  REQUEST_ADMIN_GET_USER_INFO_ERROR,
  REQUEST_ADMIN_GET_USER_INFO_STATE_CLEAR,
  REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE,
  REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE_SUCCESS,
  REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE_ERROR,
  REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE_STATE_CLEAR,
} from '../actions/';

export default (
  state = {
    isGettingUserInfoFromFirestore: false,
    hasGotUserInfoFromFirestore: false,
    gettingUserInfoFromFirestoreError: false,
    gettingUserInfoFromFirestoreErrorMessage: '',
    userRecord: {},
    savingNewProjectInFirestore: false,
    savingNewProjectInFirestoreUploadingDocuments: false,
    savingNewProjectInFirestoreUploadingDocumentsPercentage: 0,
    savingNewProjectInFirestoreSuccess: false,
    savingNewProjectInFirestoreError: false,
    savingNewProjectInFirestoreErrorMessage: '',
    savingNewProjectInFirestoreProjectRef: '',
    gettingUserProjectsFromFirestore: false,
    gotUserProjectsFromFirestore: false,
    userProjects: [],
    gettingUserProjectsFromFirestoreError: false,
    gettingUserProjectsFromFirestoreErrorMessage: '',
    gettingUserProjectFromFirestore: false,
    gotUserProjectFromFirestore: false,
    userProject: {},
    gettingUserProjectFromFirestoreError: false,
    gettingUserProjectFromFirestoreErrorMessage: '',
    updatingUserRecordInFirestore: false,
    updatingUserRecordInFirestoreSuccess: false,
    updatingUserRecordInFirestoreError: false,
    updatingUserRecordInFirestoreErrorMessage: '',
    markingProjectAsPublished: false,
    markingProjectAsPublishedSuccess: false,
    markingProjectAsPublishedError: false,
    markingProjectAsPublishedErrorMessage: '',
    uploadingUserLogo: false,
    uploadingUserLogoSuccess: false,
    uploadingUserLogoError: false,
    uploadingUserLogoErrorMessage: '',
    uploadingContactUsForm: false,
    uploadingContactUsFormSucess: false,
    uploadingContactUsFormError: false,
    uploadingContactUsFormErrorMessage: '',
  },
  action
) => {
  switch (action.type) {
    case GETTING_USER_INFO_FROM_FIRESTORE:
      return {
        ...state,
        isGettingUserInfoFromFirestore: true,
        hasGotUserInfoFromFirestore: false,
        gettingUserInfoFromFirestoreError: false,
        gettingUserInfoFromFirestoreErrorMessage: '',
      };
    case GOT_USER_INFO_FROM_FIRESTORE:
      return {
        ...state,
        isGettingUserInfoFromFirestore: false,
        hasGotUserInfoFromFirestore: true,
        gettingUserInfoFromFirestoreError: false,
        gettingUserInfoFromFirestoreErrorMessage: '',
        userRecord: action.userRecord,
      };
    case GETTING_USER_INFO_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isGettingUserInfoFromFirestore: false,
        hasGotUserInfoFromFirestore: false,
        gettingUserInfoFromFirestoreError: true,
        gettingUserInfoFromFirestoreErrorMessage: action.error,
      };
    case SAVING_NEW_PROJECT_IN_FIRESTORE:
      return {
        ...state,
        savingNewProjectInFirestore: true,
        savingNewProjectInFirestoreUploadingDocuments: false,
        savingNewProjectInFirestoreUploadingDocumentsPercentage: 0,
        savingNewProjectInFirestoreSuccess: false,
        savingNewProjectInFirestoreError: false,
        savingNewProjectInFirestoreErrorMessage: '',
      };
    case SAVING_NEW_PROJECT_IN_FIRESTORE_UPLOADING_DOCUMENTS:
      return {
        ...state,
        savingNewProjectInFirestore: true,
        savingNewProjectInFirestoreUploadingDocuments: true,
        savingNewProjectInFirestoreUploadingDocumentsPercentage:
          action.percentage,
        savingNewProjectInFirestoreSuccess: false,
        savingNewProjectInFirestoreError: false,
        savingNewProjectInFirestoreErrorMessage: '',
        savingNewProjectInFirestoreProjectRef: '',
      };

    case SAVING_NEW_PROJECT_IN_FIRESTORE_SUCCESS:
      return {
        ...state,
        savingNewProjectInFirestore: false,
        savingNewProjectInFirestoreUploadingDocuments: false,
        savingNewProjectInFirestoreUploadingDocumentsPercentage: 100,
        savingNewProjectInFirestoreSuccess: true,
        savingNewProjectInFirestoreError: false,
        savingNewProjectInFirestoreErrorMessage: '',
        savingNewProjectInFirestoreProjectRef: action.projectRef,
      };

    case SAVING_NEW_PROJECT_IN_FIRESTORE_ERROR:
      return {
        ...state,
        savingNewProjectInFirestore: false,
        savingNewProjectInFirestoreUploadingDocuments: false,
        savingNewProjectInFirestoreUploadingDocumentsPercentage: 0,
        savingNewProjectInFirestoreSuccess: false,
        savingNewProjectInFirestoreError: true,
        savingNewProjectInFirestoreErrorMessage: action.error,
        savingNewProjectInFirestoreProjectRef: '',
      };
    case SAVING_NEW_PROJECT_IN_FIRESTORE_STATE_CLEAR:
      return {
        ...state,
        savingNewProjectInFirestore: false,
        savingNewProjectInFirestoreUploadingDocuments: false,
        savingNewProjectInFirestoreUploadingDocumentsPercentage: 0,
        savingNewProjectInFirestoreSuccess: false,
        savingNewProjectInFirestoreError: false,
        savingNewProjectInFirestoreErrorMessage: '',
        savingNewProjectInFirestoreProjectRef: '',
      };
    case REQUEST_USERS_PROJECTS_FROM_FIRESTORE:
      return {
        ...state,
        gettingUserProjectsFromFirestore: true,
        gotUserProjectsFromFirestore: false,
        userProjects: [],
        gettingUserProjectsFromFirestoreError: false,
        gettingUserProjectsFromFirestoreErrorMessage: '',
      };
    case RECEIVE_USERS_PROJECTS_FROM_FIRESTORE:
      return {
        ...state,
        gettingUserProjectsFromFirestore: false,
        gotUserProjectsFromFirestore: true,
        userProjects: action.projects,
        gettingUserProjectsFromFirestoreError: false,
        gettingUserProjectsFromFirestoreErrorMessage: '',
      };
    case REQUEST_USERS_PROJECTS_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        gettingUserProjectsFromFirestore: false,
        gotUserProjectsFromFirestore: false,
        userProjects: [],
        gettingUserProjectsFromFirestoreError: true,
        gettingUserProjectsFromFirestoreErrorMessage: action.error,
      };
    case REQUEST_USERS_PROJECT_FROM_FIRESTORE:
      return {
        ...state,
        gettingUserProjectFromFirestore: true,
        gotUserProjectFromFirestore: false,
        userProject: [],
        gettingUserProjectFromFirestoreError: false,
        gettingUserProjectFromFirestoreErrorMessage: '',
      };
    case RECEIVE_USERS_PROJECT_FROM_FIRESTORE:
      return {
        ...state,
        gettingUserProjectFromFirestore: false,
        gotUserProjectFromFirestore: true,
        userProject: action.project,
        gettingUserProjectFromFirestoreError: false,
        gettingUserProjectFromFirestoreErrorMessage: '',
      };
    case REQUEST_USERS_PROJECT_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        gettingUserProjectFromFirestore: false,
        gotUserProjectFromFirestore: false,
        userProject: [],
        gettingUserProjectFromFirestoreError: true,
        gettingUserProjectFromFirestoreErrorMessage: action.error,
      };

    case REQUEST_UPDATE_USER_IN_FIRESTORE:
      return {
        ...state,
        updatingUserRecordInFirestore: true,
        updatingUserRecordInFirestoreSuccess: false,
        updatingUserRecordInFirestoreError: false,
        updatingUserRecordInFirestoreErrorMessage: '',
      };
    case REQUEST_UPDATE_USER_IN_FIRESTORE_SUCCESS:
      return {
        ...state,
        updatingUserRecordInFirestore: false,
        updatingUserRecordInFirestoreSuccess: true,
        updatingUserRecordInFirestoreError: false,
        updatingUserRecordInFirestoreErrorMessage: '',
      };
    case REQUEST_UPDATE_USER_IN_FIRESTORE_ERROR:
      return {
        ...state,
        updatingUserRecordInFirestore: false,
        updatingUserRecordInFirestoreSuccess: false,
        updatingUserRecordInFirestoreError: true,
        updatingUserRecordInFirestoreErrorMessage: action.error,
      };
    case REQUEST_UPDATE_USER_IN_FIRESTORE_STATE_CLEAR:
      return {
        ...state,
        updatingUserRecordInFirestore: false,
        updatingUserRecordInFirestoreSuccess: false,
        updatingUserRecordInFirestoreError: false,
        updatingUserRecordInFirestoreErrorMessage: '',
      };

    case REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE:
      return {
        ...state,
        markingProjectAsPublished: true,
        markingProjectAsPublishedSuccess: false,
        markingProjectAsPublishedError: false,
        markingProjectAsPublishedErrorMessage: '',
      };
    case REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE_SUCCESS:
      return {
        ...state,
        markingProjectAsPublished: false,
        markingProjectAsPublishedSuccess: true,
        markingProjectAsPublishedError: false,
        markingProjectAsPublishedErrorMessage: '',
      };
    case REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE_ERROR:
      return {
        ...state,
        markingProjectAsPublished: false,
        markingProjectAsPublishedSuccess: false,
        markingProjectAsPublishedError: true,
        markingProjectAsPublishedErrorMessage: action.error,
      };
    case REQUEST_SET_PROJECT_AS_PUBLISHED_IN_FIRESTORE_STATE_CLEAR:
      return {
        ...state,
        markingProjectAsPublished: false,
        markingProjectAsPublishedSuccess: false,
        markingProjectAsPublishedError: false,
        markingProjectAsPublishedErrorMessage: '',
      };
    case REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE:
      return {
        ...state,
        uploadingUserLogo: true,
        uploadingUserLogoSuccess: false,
        uploadingUserLogoError: false,
        uploadingUserLogoErrorMessage: '',
      };
    case REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE_SUCCESS:
      return {
        ...state,
        uploadingUserLogo: false,
        uploadingUserLogoSuccess: true,
        uploadingUserLogoError: false,
        uploadingUserLogoErrorMessage: '',
      };
    case REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE_ERROR:
      return {
        ...state,
        uploadingUserLogo: false,
        uploadingUserLogoSuccess: false,
        uploadingUserLogoError: true,
        uploadingUserLogoErrorMessage: action.error,
      };

    case REQUEST_UPLOAD_USER_LOGO_IN_FIRESTORE_STATE_CLEAR:
      return {
        ...state,
        uploadingUserLogo: false,
        uploadingUserLogoSuccess: false,
        uploadingUserLogoError: false,
        uploadingUserLogoErrorMessage: '',
      };
    case REQUEST_ADMIN_DRAFT_PROJECTS:
      return {
        ...state,
        adminDraftProjectsRequest: true,
        adminDraftProjectsRequestSuccess: false,
        adminDraftProjectsRequestError: false,
        adminDraftProjectsRequestErrorMessage: '',
        adminDraftProjects: [],
      };
    case REQUEST_ADMIN_DRAFT_PROJECTS_SUCCESS:
      return {
        ...state,
        adminDraftProjectsRequest: false,
        adminDraftProjectsRequestSuccess: true,
        adminDraftProjectsRequestError: false,
        adminDraftProjectsRequestErrorMessage: '',
        adminDraftProjects: action.projects,
      };
    case REQUEST_ADMIN_DRAFT_PROJECTS_ERROR:
      return {
        ...state,
        adminDraftProjectsRequest: false,
        adminDraftProjectsRequestSuccess: false,
        adminDraftProjectsRequestError: true,
        adminDraftProjectsRequestErrorMessage: action.error,
        adminDraftProjects: [],
      };
    case REQUEST_ADMIN_DRAFT_PROJECTS_STATE_CLEAR:
      return {
        ...state,
        adminDraftProjectsRequest: false,
        adminDraftProjectsRequestSuccess: false,
        adminDraftProjectsRequestError: false,
        adminDraftProjectsRequestErrorMessage: '',
        adminDraftProjects: [],
      };

    case REQUEST_ADMIN_PUBLISHED_PROJECTS:
      return {
        ...state,
        adminPublishedProjectsRequest: true,
        adminPublishedProjectsRequestSuccess: false,
        adminPublishedProjectsRequestError: false,
        adminPublishedProjectsRequestErrorMessage: '',
        adminPublishedProjects: [],
      };
    case REQUEST_ADMIN_PUBLISHED_PROJECTS_SUCCESS:
      return {
        ...state,
        adminPublishedProjectsRequest: false,
        adminPublishedProjectsRequestSuccess: true,
        adminPublishedProjectsRequestError: false,
        adminPublishedProjectsRequestErrorMessage: '',
        adminPublishedProjects: action.projects,
      };
    case REQUEST_ADMIN_PUBLISHED_PROJECTS_ERROR:
      return {
        ...state,
        adminPublishedProjectsRequest: false,
        adminPublishedProjectsRequestSuccess: false,
        adminPublishedProjectsRequestError: true,
        adminPublishedProjectsRequestErrorMessage: action.error,
        adminPublishedProjects: [],
      };
    case REQUEST_ADMIN_PUBLISHED_PROJECTS_STATE_CLEAR:
      return {
        ...state,
        adminPublishedProjectsRequest: false,
        adminPublishedProjectsRequestSuccess: false,
        adminPublishedProjectsRequestError: false,
        adminPublishedProjectsRequestErrorMessage: '',
        adminPublishedProjects: [],
      };

    case REQUEST_ADMIN_REJECTED_PROJECTS:
      return {
        ...state,
        adminRejectedProjectsRequest: true,
        adminRejectedProjectsRequestSuccess: false,
        adminRejectedProjectsRequestError: false,
        adminRejectedProjectsRequestErrorMessage: '',
        adminRejectedProjects: [],
      };
    case REQUEST_ADMIN_REJECTED_PROJECTS_SUCCESS:
      return {
        ...state,
        adminRejectedProjectsRequest: false,
        adminRejectedProjectsRequestSuccess: true,
        adminRejectedProjectsRequestError: false,
        adminRejectedProjectsRequestErrorMessage: '',
        adminRejectedProjects: action.projects,
      };
    case REQUEST_ADMIN_REJECTED_PROJECTS_ERROR:
      return {
        ...state,
        adminRejectedProjectsRequest: false,
        adminRejectedProjectsRequestSuccess: false,
        adminRejectedProjectsRequestError: true,
        adminRejectedProjectsRequestErrorMessage: action.error,
        adminRejectedProjects: [],
      };
    case REQUEST_ADMIN_REJECTED_PROJECTS_STATE_CLEAR:
      return {
        ...state,
        adminRejectedProjectsRequest: false,
        adminRejectedProjectsRequestSuccess: false,
        adminRejectedProjectsRequestError: false,
        adminRejectedProjectsRequestErrorMessage: '',
        adminRejectedProjects: [],
      };

    case REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS:
      return {
        ...state,
        adminPendingGoLiveProjectsRequest: true,
        adminPendingGoLiveProjectsRequestSuccess: false,
        adminPendingGoLiveProjectsRequestError: false,
        adminPendingGoLiveProjectsRequestErrorMessage: '',
        adminPendingGoLiveProjects: [],
      };
    case REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS_SUCCESS:
      return {
        ...state,
        adminPendingGoLiveProjectsRequest: false,
        adminPendingGoLiveProjectsRequestSuccess: true,
        adminPendingGoLiveProjectsRequestError: false,
        adminPendingGoLiveProjectsRequestErrorMessage: '',
        adminPendingGoLiveProjects: action.projects,
      };
    case REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS_ERROR:
      return {
        ...state,
        adminPendingGoLiveProjectsRequest: false,
        adminPendingGoLiveProjectsRequestSuccess: false,
        adminPendingGoLiveProjectsRequestError: true,
        adminPendingGoLiveProjectsRequestErrorMessage: action.error,
        adminPendingGoLiveProjects: [],
      };
    case REQUEST_ADMIN_PENDING_GO_LIVE_PROJECTS_STATE_CLEAR:
      return {
        ...state,
        adminPendingGoLiveProjectsRequest: false,
        adminPendingGoLiveProjectsRequestSuccess: false,
        adminPendingGoLiveProjectsRequestError: false,
        adminPendingGoLiveProjectsRequestErrorMessage: '',
        adminPendingGoLiveProjects: [],
      };

    case REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO:
      return {
        ...state,
        adminProjectOwnersRequest: true,
        adminProjectOwnersRequestSuccess: false,
        adminProjectOwnersRequestError: false,
        adminProjectOwnersRequestErrorMessage: '',
        // adminProjectOwners: {},
      };
    case REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO_SUCCESS:
      return {
        ...state,
        adminProjectOwnersRequest: false,
        adminProjectOwnersRequestSuccess: true,
        adminProjectOwnersRequestError: false,
        adminProjectOwnersRequestErrorMessage: '',
        adminProjectOwners: { ...state.adminProjectOwners, ...action.owners },
      };
    case REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO_ERROR:
      return {
        ...state,
        adminProjectOwnersRequest: false,
        adminProjectOwnersRequestSuccess: false,
        adminProjectOwnersRequestError: true,
        adminProjectOwnersRequestErrorMessage: action.error,
        // adminProjectOwners: {},
      };
    case REQUEST_ADMIN_GET_PROJECT_OWNERS_INFO_STATE_CLEAR:
      return {
        ...state,
        adminProjectOwnersRequest: false,
        adminProjectOwnersRequestSuccess: false,
        adminProjectOwnersRequestError: false,
        adminProjectOwnersRequestErrorMessage: '',
        adminProjectOwners: {},
      };

    case REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE:
      return {
        ...state,
        adminProjectApprovalRequest: true,
        adminProjectApprovalRequestSuccess: false,
        adminProjectApprovalRequestError: false,
        adminProjectApprovalRequestErrorMessage: '',
      };
    case REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE_SUCCESS:
      return {
        ...state,
        adminProjectApprovalRequest: false,
        adminProjectApprovalRequestSuccess: true,
        adminProjectApprovalRequestError: false,
        adminProjectApprovalRequestErrorMessage: '',
      };
    case REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE_ERROR:
      return {
        ...state,
        adminProjectApprovalRequest: false,
        adminProjectApprovalRequestSuccess: false,
        adminProjectApprovalRequestError: true,
        adminProjectApprovalRequestErrorMessage: action.error,
      };
    case REQUEST_ADMIN_SET_PROJECT_AS_APPROVED_IN_FIRESTORE_STATE_CLEAR:
      return {
        ...state,
        adminProjectApprovalRequest: false,
        adminProjectApprovalRequestSuccess: false,
        adminProjectApprovalRequestError: false,
        adminProjectApprovalRequestErrorMessage: '',
      };

    case REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE:
      return {
        ...state,
        adminProjectRejectRequest: true,
        adminProjectRejectRequestSuccess: false,
        adminProjectRejectRequestError: false,
        adminProjectRejectRequestErrorMessage: '',
      };
    case REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE_SUCCESS:
      return {
        ...state,
        adminProjectRejectRequest: false,
        adminProjectRejectRequestSuccess: true,
        adminProjectRejectRequestError: false,
        adminProjectRejectRequestErrorMessage: '',
      };
    case REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE_ERROR:
      return {
        ...state,
        adminProjectRejectRequest: false,
        adminProjectRejectRequestSuccess: false,
        adminProjectRejectRequestError: true,
        adminProjectRejectRequestErrorMessage: action.error,
      };
    case REQUEST_ADMIN_SET_PROJECT_AS_REJECTED_IN_FIRESTORE_STATE_CLEAR:
      return {
        ...state,
        adminProjectRejectRequest: false,
        adminProjectRejectRequestSuccess: false,
        adminProjectRejectRequestError: false,
        adminProjectRejectRequestErrorMessage: '',
      };

    case REQUEST_ADMIN_GET_STATISTICS:
      return {
        ...state,
        adminGetStatisticsRequest: true,
        adminGetStatisticsSuccess: false,
        adminGetStatisticsError: false,
        adminGetStatisticsErrorMessage: '',
        adminStatistics: {},
      };
    case REQUEST_ADMIN_GET_STATISTICS_SUCCESS:
      return {
        ...state,
        adminGetStatisticsRequest: false,
        adminGetStatisticsSuccess: true,
        adminGetStatisticsError: false,
        adminGetStatisticsErrorMessage: '',
        adminStatistics: action.statistics,
      };
    case REQUEST_ADMIN_GET_STATISTICS_ERROR:
      return {
        ...state,
        adminGetStatisticsRequest: false,
        adminGetStatisticsSuccess: false,
        adminGetStatisticsError: true,
        adminGetStatisticsErrorMessage: action.error,
        adminStatistics: {},
      };
    case REQUEST_ADMIN_GET_STATISTICS_STATE_CLEAR:
      return {
        ...state,
        adminGetStatisticsRequest: false,
        adminGetStatisticsSuccess: false,
        adminGetStatisticsError: false,
        adminGetStatisticsErrorMessage: '',
        adminStatistics: {},
      };

    case REQUEST_ADMIN_GET_USER_INFO:
      return {
        ...state,
        adminGetUserInfoRequest: true,
        adminGetUserInfoSuccess: false,
        adminGetUserInfoError: false,
        adminGetUserInfoErrorMessage: '',
        adminUserRecord: {},
      };
    case REQUEST_ADMIN_GET_USER_INFO_SUCCESS:
      return {
        ...state,
        adminGetUserInfoRequest: false,
        adminGetUserInfoSuccess: true,
        adminGetUserInfoError: false,
        adminGetUserInfoErrorMessage: '',
        adminUserRecord: action.userInfo,
      };
    case REQUEST_ADMIN_GET_USER_INFO_ERROR:
      return {
        ...state,
        adminGetUserInfoRequest: false,
        adminGetUserInfoSuccess: false,
        adminGetUserInfoError: true,
        adminGetUserInfoErrorMessage: action.error,
        adminUserRecord: {},
      };
    case REQUEST_ADMIN_GET_USER_INFO_STATE_CLEAR:
      return {
        ...state,
        adminGetUserInfoRequest: false,
        adminGetUserInfoSuccess: false,
        adminGetUserInfoError: false,
        adminGetUserInfoErrorMessage: '',
        adminUserRecord: {},
      };

    case REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE:
      return {
        ...state,
        uploadingContactUsForm: true,
        uploadingContactUsFormSuccess: false,
        uploadingContactUsFormError: false,
        uploadingContactUsFormErrorMessage: '',
      };
    case REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE_SUCCESS:
      return {
        ...state,
        uploadingContactUsForm: false,
        uploadingContactUsFormSuccess: true,
        uploadingContactUsFormError: false,
        uploadingContactUsFormErrorMessage: '',
      };
    case REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE_ERROR:
      return {
        ...state,
        uploadingContactUsForm: false,
        uploadingContactUsFormSuccess: false,
        uploadingContactUsFormError: true,
        uploadingContactUsFormErrorMessage: action.error,
      };
    case REQUEST_STORE_CONTACT_US_FORM_IN_FIREBASE_STATE_CLEAR:
      return {
        ...state,
        uploadingContactUsForm: false,
        uploadingContactUsFormSuccess: false,
        uploadingContactUsFormError: false,
        uploadingContactUsFormErrorMessage: '',
      };

    default:
      return state;
  }
};
