export const LANDING = '/';
export const SIGN_IN = '/signin';
export const SIGN_UP = '/signup';
export const SIGN_UP_THANK_YOU = '/signup/thankyou';
export const PASSWORD_RESET = '/forgotpassword';
export const HOME = '/home';
export const ABOUT_US = '/about';
export const FAQ = '/FAQ';
export const CONTACT_US = '/contact';
export const PROJECTS = '/projects';
export const NEW_PROJECT = '/projects/new';
export const NEW_PROJECT_THANK_YOU_PREFIX = '/projects/thankyou/';
export const NEW_PROJECT_THANK_YOU = '/projects/thankyou/:projectRef';
export const SHOW_PROJECT = '/projects/show/:projectRef';
export const SHOW_PROJECT_PREFIX = '/projects/show/';
export const EDIT_PROJECT = '/projects/edit/:projectRef';
export const EDIT_PROJECT_PREFIX = '/projects/edit/';
export const ADMIN = '/admin';
export const ADMIN_SHOW_PROJECT = '/admin/projects/show/:projectRef';
export const ADMIN_SHOW_PROJECT_PREFIX = '/admin/projects/show/';
export const PROFILE = '/profile';
export const PROFILE_EDIT = '/profile/edit/';
export const PROFILE_MANAGE = '/profile/manage/';
export const PROFILE_IMAGE_UPLOAD = '/profile/logo/';
export const PRIVACY_POLICY = '/privacypolicy';
export const TERMS_AND_CONDITIONS = '/termsandconditions';
export const BLOG = '/blog';
export const BLOG_POST_200801 = '/blog/posting-project-guidelines';
