import React from 'react';
import { connect } from 'react-redux';

import {
  Container,
  Dimmer,
  Grid,
  Header,
  Image,
  Loader,
  Placeholder,
  Responsive,
  Segment,
} from 'semantic-ui-react';

import { adminGetUserInfoFromFirestore } from '../../../actions';

class ProjectUserProfileDisplay extends React.Component {
  state = {};

  componentDidMount() {
    this.props.adminGetUserInfoFromFirestore(this.props.userId);
  }

  profileLoadingPlaceholder() {
    return (
      <Segment>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </Segment>
    );
  }

  handleWindowResize = (e, { width }) => {
    this.setState({ windowWidth: width });
    // const windowWidth = window != undefined ? window.innerWidth : 0;
    // const smallEnoughToStack = 600 < windowWidth;
    // console.log(windowWidth);
    // console.log(Responsive.getWidth);
    // console.log(smallEnoughToStack);
  };

  generateCompanyInfo() {
    if (this.props.adminGetUserInfoSuccess) {
      const userName =
        this.props.adminUserRecord.firstName +
        ' ' +
        this.props.adminUserRecord.lastName;
      const CompanyName = this.props.adminUserRecord.company.companyName;
      const CompanyWebsite = this.props.adminUserRecord.company.companyWebsite;
      const CompanyAddressL1 = this.props.adminUserRecord.company.address.line1;
      const CompanyAddressL2 = this.props.adminUserRecord.company.address.line2;
      const CompanyAddressL3 = this.props.adminUserRecord.company.address.town;
      const CompanyAddressL4 = this.props.adminUserRecord.company.address
        .postCode;
      const email = this.props.adminUserRecord.email;
      const landline = this.props.adminUserRecord.landline;
      const mobile = this.props.adminUserRecord.mobile;
      let logoUrl = this.props.adminUserRecord.company.logoUrl;
      logoUrl = logoUrl
        ? logoUrl
        : 'https://react.semantic-ui.com/images/wireframe/image.png';

      const smallEnoughToStack = 525 > this.state.windowWidth;

      return (
        <Responsive fireOnMount onUpdate={this.handleWindowResize}>
          <Grid columns={2} stackable={smallEnoughToStack}>
            <Grid.Row>
              <Grid.Column>
                {/* <ProfileImageDimmer logoUrl={logoUrl} /> */}
                Image goes here
                <Image size='medium' src={logoUrl} />
              </Grid.Column>
              <Grid.Column>
                {/* <Container text> */}
                <Header as='h6'>
                  Name
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    {userName}{' '}
                  </Header.Subheader>
                </Header>

                <Header as='h6'>
                  Email
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    {email}
                  </Header.Subheader>
                </Header>
                <Header as='h6'>
                  Landline
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    {landline}
                  </Header.Subheader>
                </Header>
                <Header as='h6'>
                  Mobile
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    {mobile}
                  </Header.Subheader>
                </Header>
                <Header as='h6'>
                  Company Name
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    {CompanyName}
                  </Header.Subheader>
                </Header>
                <Header as='h6'>
                  Company Website
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    <a href={CompanyWebsite}>{CompanyWebsite}</a>
                  </Header.Subheader>
                </Header>
                <Header as='h6'>
                  Company Address
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    {CompanyAddressL1}
                  </Header.Subheader>
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    {CompanyAddressL2}
                  </Header.Subheader>
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    {CompanyAddressL3}
                  </Header.Subheader>
                  <Header.Subheader style={{ wordWrap: 'break-word' }}>
                    {CompanyAddressL4}
                  </Header.Subheader>
                </Header>

                {/* </Container> */}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Responsive>
      );
    } else {
      return this.profileLoadingPlaceholder();
    }
  }

  render() {
    return (
      <Container fluid>
        <Segment.Group raised>
          <Segment>
            <Header>
              Project Owner Profile
              <Header.Subheader>
                Ensure this is accurate before approval so they can be contacted
              </Header.Subheader>
            </Header>
            {this.generateCompanyInfo()}
          </Segment>
        </Segment.Group>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    adminGetUserInfoRequest: state.firestoreReducers.adminGetUserInfoRequest,
    adminGetUserInfoSuccess: state.firestoreReducers.adminGetUserInfoSuccess,
    adminGetUserInfoError: state.firestoreReducers.adminGetUserInfoError,
    adminGetUserInfoErrorMessage:
      state.firestoreReducers.adminGetUserInfoErrorMessage,
    adminUserRecord: state.firestoreReducers.adminUserRecord,
  };
}

export default connect(mapStateToProps, {
  adminGetUserInfoFromFirestore,
})(ProjectUserProfileDisplay);
