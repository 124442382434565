import React from 'react';

import { Form, Header, Select } from 'semantic-ui-react';

class Page1 extends React.Component {
  state = { errors: this.props.errors };

  renderSpecialitySelection(category) {
    switch (category) {
      case 'electrical':
        return [
          {
            key: '0',
            text: '',
            value: '',
          },
          {
            key: '1',
            text: 'New Panel',
            value: 'newPanelElectrical',
          },
          {
            key: '2',
            text: 'New Hardware Integration',
            value: 'newHardwareElectrical',
          },
          {
            key: '3',
            text: 'Upgrade of equipment',
            value: 'upgradeElectrical',
          },
          {
            key: '4',
            text: 'Drawings/Documentation',
            value: 'drawingsElectrical',
          },
          {
            key: '5',
            text: 'Modifications',
            value: 'modificationsElectrical',
          },
          {
            key: '6',
            text: 'Other',
            value: 'otherElectrical',
          },
        ];
      case 'mechanical':
        return [
          {
            key: '0',
            text: '',
            value: '',
          },
          {
            key: '1',
            text: 'New Fabrication',
            value: 'newFabrication',
          },
          {
            key: '2',
            text: 'Equipment Installation',
            value: 'equipmentInstallation',
          },
          {
            key: '3',
            text: 'Modifications',
            value: 'modifications',
          },
          {
            key: '4',
            text: 'Other',
            value: 'otherMechanical',
          },
        ];
      case 'software':
        return [
          {
            key: '0',
            text: 'PLC Programming',
            value: 'plc',
          },
          {
            key: '1',
            text: 'SCADA/SAP System',
            value: 'scada',
          },
          {
            key: '2',
            text: 'PC/Server Software',
            value: 'pcSoftware',
          },
          {
            key: '3',
            text: 'Other',
            value: 'otherSoftware',
          },
        ];
      case 'multidisciplinary':
        return [
          {
            key: '0',
            text: '',
            value: '',
          },
          {
            key: '1',
            text: 'New Machine Build',
            value: 'newMachine',
          },
          {
            key: '2',
            text: 'Machine Upgrade',
            value: 'machineUpgrade',
          },
          {
            key: '3',
            text: 'Full line build',
            value: 'fullLine',
          },
          {
            key: '4',
            text: 'Commissioning',
            value: 'commissioning',
          },
          {
            key: '5',
            text: 'Other',
            value: 'otherMultidiscipliniary',
          },
        ];
      case 'other':
        return [
          {
            key: '0',
            text: '',
            value: '',
          },
          {
            key: '1',
            text: 'Other',
            value: 'otherOther',
          },
        ];

      default:
        return [
          {
            key: '0',
            text: '',
            value: '',
          },
        ];
    }
  }

  render() {
    return (
      <>
        <Form.Input
          fluid
          icon="edit outline"
          iconPosition="left"
          label="Project Title"
          placeholder="Project Title"
          name="title"
          onChange={this.props.handleChange}
          onBlur={this.props.handleBlur}
          value={this.props.values.title}
          error={this.props.touched.title && this.props.errors.title}
          required
        />
        <Form.Input
          fluid
          icon="edit outline"
          iconPosition="left"
          label="Short Description"
          placeholder="Enter a short description of your project"
          name="description"
          onChange={this.props.handleChange}
          onBlur={this.props.handleBlur}
          value={this.props.values.description}
          error={
            this.props.touched.description && this.props.errors.description
          }
          required
        />

        <Header as="h5">Type of work</Header>
        <Form.Group widths="equal">
          <Form.Select
            label="Category"
            placeholder="Category"
            name="category"
            onChange={(_, { value }) => {
              this.props.setFieldTouched('category');
              this.props.setFieldValue('category', value);
            }}
            onBlur={(e) => {
              this.props.setFieldTouched('category');
              this.props.handleBlur(e);
            }}
            value={this.props.values.category}
            error={this.props.touched.category && this.props.errors.category}
            options={[
              {
                key: '0',
                text: '',
                value: '',
              },
              {
                key: '1',
                text: 'Electrical',
                value: 'electrical',
              },
              {
                key: '2',
                text: 'Mechanical',
                value: 'mechanical',
              },
              {
                key: '3',
                text: 'Software',
                value: 'software',
              },
              {
                key: '4',
                text: 'Multidisciplinary',
                value: 'multidisciplinary',
              },
              {
                key: '5',
                text: 'Other',
                value: 'other',
              },
            ]}
            width={8}
            required
          />
          <Form.Field
            control={Select}
            label="Speciality"
            placeholder="Speciality"
            name="speciality"
            onChange={(_, { value }) => {
              this.props.setFieldTouched('speciality');
              this.props.setFieldValue('speciality', value);
            }}
            onBlur={(e) => {
              this.props.setFieldTouched('speciality');
              this.props.handleBlur(e);
            }}
            value={this.props.values.speciality}
            error={
              this.props.touched['speciality'] &&
              this.props.errors['speciality']
            }
            options={this.renderSpecialitySelection(this.props.values.category)}
            width={8}
            required
          />
        </Form.Group>
      </>
    );
  }
}

export default Page1;
