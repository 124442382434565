import React from 'react';
import { Link } from 'react-router-dom';

import {
  Accordion,
  Container,
  Header,
  Segment,
  Icon,
  List,
} from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

import * as ROUTES from '../constants/routes';

class Faq extends React.Component {
  state = { activeIndex: null };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  questions = [
    {
      question: 'What is MachineBuilders.co.uk?',
      answer: [
        'MachineBuilders.co.uk is a powerful platform designed to bring end users, systems integrators, and machine builders together to ensure that the UK manufacturing sector remains best in class.',
        'When planning modern manufacturing improvements end users require high quality quotations provided by the best and most reliable system integrators and machine builders. Currently it is quite difficult for end users to find good OEMs and vice versa.',
        'MachineBuilders.co.uk make it our mission to bridge that gap.',
        {
          start: 'Find out more about us on our ',
          linkText: 'About',
          end: ' page.',
          linkTo: ROUTES.ABOUT_US,
        },
      ],
    },
    {
      question: 'How do I post a project?',
      answer: [
        'Just sign up, complete your profile, and click on the ‘create new project’ button in the projects section of your home page. This will take you through the new project wizard which will efficiently gather all the information required to ensure we gather effective quotations for you.',
        'One of our experienced project engineers will be in touch if more information is required and your project will need to meet a minimum standard of information to go live and receive bids.',
      ],
    },
    {
      question: 'What happens once I have posted a project?',
      answer: [
        'We will then review the project',
        'Once it is approved we will offer it to local or national OEMs and request quotes for the project. This stage may require further information and possibly a site visit to fully understand the scope of the project.',
        'Up to three quotes will be provided per job posting. Once received, you can make a decision on the supplier and place orders directly.',
      ],
    },
    {
      question: 'How do I bid for a project?',
      answer: [
        'If you are interested in viewing and bidding for our live projects we ask that you sign up to the site, complete your profile, and click on the ‘request access to bidding’ link in the footer at the bottom of the website.',
        'We will conduct an onboarding interview to qualify you for access to the site and once accepted you will be invited to bid for some live projects.',
        `You will then be granted access to view some information about the currently live projects.`,
        `Project overviews will contain enough information to know if it is a project which matches up with your business capabilities, but to find out the full information and who to supply with your quotation you will need to pay for a specific project's access fee.`,
      ],
    },
    {
      question: 'How much does it cost to advertise a project?',
      answer: [
        'It is completely free to post a project on our service!',
        '',
        '',
        '',
      ],
    },
    {
      question: 'How much does it cost to bid for a project?',
      answer: [
        'The exact cost to bid for projects will depend on the specific project itself.',
        'MachineBuilders spends a lot on advertising to ensure we have a large selection of high quality projects for you to bid for.',
        'We also invest a lot of time to ensure the project requests are full of high quality information which will make it much more efficient to put together an effective quotation.',
        'You can see the cost to bid for each live project when viewing them.',
      ],
    },
    {
      question: 'How do I sign up?',
      answer: [
        {
          start: 'Follow ',
          linkText: 'this link',
          end:
            ' to create your username and password. You can complete the project wizard at this time but to make a project go live and be ready to receive bids you will need to complete your full user profile and validate your contact information.',
          linkTo: ROUTES.SIGN_UP,
        },
        'You can browse projects without being a member but can’t see all of it’s details. To bid or post new projects you must be registered.',
        'Fill out a short registration form. You’ll also need to provide a valid email address and activate your account.',
        'Create your profile. Take a few minutes to provide some information about yourself and/or your business. This information will be stored and used to match you based on location and experience to new project postings. You can also get alerts when relevant projects have been posted',
      ],
    },
    {
      question: 'I have forgotten my password, what do I do?',
      answer: [
        'list',
        {
          start: 'Head to the ',
          linkText: 'Login page',
          end: '',
          linkTo: ROUTES.SIGN_IN,
        },
        'Click the ‘forgot password?’ link',
        'Enter your profile’s email and click ‘reset password’',
        'An email will be sent to your inbox with a link to reset your password',
        'Follow the link and enter a new password',
      ],
    },
    {
      question: 'How do I make a payment?',
      answer: [
        'Payments are only required when bidding to quote for a project.',
        'Once you are in a position to bid for a live project, follow the ‘bid for this project’ link on the project view and complete the form.',
        'Payments are processed via the secure payment platform Stripe which is one of the most trusted payment gateways available on the market. Currently we only accept payments via credit/debit cards through Stripe.',
        {
          start: `If you wish to pay through an alternative means please contact `,
          linkText: 'support@machinebuilders.co.uk',
          end: ' for guidance.',
          linkTo:
            'mailto:support@machinebuilders.co.uk?subject=Contact%20Machine%20Builders%20Support',
        },
      ],
    },
    {
      question: 'How do I give feedback on my project?',
      answer: [
        'After the project has been completed, feedback will be requested from both parties. This will then be stored for future work and taken into account on future bids and project postings.',
        {
          start: `If you have specific praise or problems please don't hesitate to get in touch at `,
          linkText: 'support@machinebuilders.co.uk',
          end: '',
          linkTo:
            'mailto:support@machinebuilders.co.uk?subject=Contact%20Machine%20Builders%20Support',
        },
      ],
    },
  ];

  checkAndHandleLinks(line) {
    if (typeof line === 'object') {
      return (
        <>
          {line.start}
          <a href={line.linkTo}>{line.linkText}</a>
          {line.end}
        </>
      );
    } else {
      return line;
    }
  }

  renderAnswers(answerList) {
    let answerContent = null;
    if (answerList[0] === 'list') {
      const answerContentListElements = answerList.map((line, index) => {
        if (line !== 'list') {
          let keyid;
          if (typeof line === 'object') {
            keyid = line.start.substring(1 + index);
          } else if (line === '') {
            keyid = (1 + index).toString();
          } else {
            keyid = line.substring(1 + index);
          }
          return (
            <List.Item key={keyid}>{this.checkAndHandleLinks(line)}</List.Item>
          );
        }
        return null;
      });
      answerContent = <List ordered>{answerContentListElements}</List>;
    } else {
      answerContent = answerList.map((line, index) => {
        let keyid;
        if (typeof line === 'object') {
          keyid = line.start.substring(2 + index);
        } else if (line === '') {
          keyid = (1 + index).toString();
        } else {
          keyid = line.substring(2 + index);
        }
        return <p key={keyid}>{this.checkAndHandleLinks(line)}</p>;
      });
    }

    return answerContent;
  }

  renderQuestions = (activeIndex) => {
    const questionAccordianEntries = this.questions.map((entry, index) => {
      return (
        <React.Fragment key={'fragment' + index}>
          <Accordion.Title
            active={activeIndex === index}
            index={index}
            onClick={this.handleClick}
            key={'title' + index}
          >
            <Icon name='dropdown' />
            {entry.question}
          </Accordion.Title>
          <Accordion.Content
            active={activeIndex === index}
            key={'content' + index}
          >
            {this.renderAnswers(entry.answer)}
          </Accordion.Content>
        </React.Fragment>
      );
    });

    return questionAccordianEntries;
  };
  render() {
    const { activeIndex } = this.state;

    return (
      <Container text>
        <Helmet>
          <meta charSet='utf-8' />
          <title>FAQ</title>
          <meta
            name='description'
            content='Frequently Asked Questions from our users and machine builders. Machine Builders the best platform to easily find machine builders and systems integrators for your factory automation projects.'
          />
        </Helmet>
        <Header
          as='h1'
          content='FAQ'
          style={{
            fontSize: '4em',
            fontWeight: 'normal',
            marginBottom: 0,
            marginTop: '0.8em',
          }}
        />
        <Header
          as='h2'
          content='Find answers to our most frequently asked questions'
          style={{
            fontSize: '1.7em',
            fontWeight: 'normal',
            marginTop: 0,
          }}
        />
        Have a question that hasn't been answered here?{' '}
        <Link to={ROUTES.CONTACT_US}>Contact Us</Link>
        <Segment.Group raised>
          <Segment>
            <Header
              as='h6'
              content='Select a question to see our answer'
              style={{
                fontSize: '1em',
                fontWeight: 'normal',
                marginBottom: '1em',
                marginTop: '0em',
              }}
            />
            <Accordion fluid styled>
              {this.renderQuestions(activeIndex)}
            </Accordion>
          </Segment>
        </Segment.Group>
        <br />
        <br />
        <br />
      </Container>
    );
  }
}

export default Faq;
