import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Dimmer,
  Form,
  Header,
  Icon,
  List,
  Loader,
  Segment,
  TextArea,
  Table,
  Divider,
} from "semantic-ui-react";
import prettysize from "prettysize";
import { Formik } from "formik";
import * as Yup from "yup";

import * as ROUTES from "../../../constants/routes";
import {
  getOneUsersProjectFromFirestore,
  adminSetProjectAsApprovedInFirestore,
  adminSetProjectAsApprovedInFirestoreStateClear,
  adminSetProjectAsRejectedInFirestore,
  adminSetProjectAsRejectedInFirestoreStateClear,
} from "../../../actions";

import ProjectUserProfileDisplay from "./ProjectUserProfileDisplay";

class AdminShowProject extends React.Component {
  state = {
    publishElementDisplay: false,
    projectRef: "",
    publishingLoading: false,
    rejectionReasonDisplay: false,
    rejectionReasonLoading: false,
  };

  componentDidMount() {
    const { projectRef } = this.props.match.params;
    // console.log(projectRef);
    this.props.getOneUsersProjectFromFirestore(projectRef);
    this.props.adminSetProjectAsApprovedInFirestoreStateClear();
    this.props.adminSetProjectAsRejectedInFirestoreStateClear();
  }

  projectLoading() {
    return (
      <Segment loading>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Segment>
    );
  }

  convertCamelToHumanReadable(str) {
    return str
      .match(/^[a-z]+|[A-Z][a-z]*/g)
      .map(function (x) {
        return x[0].toUpperCase() + x.substr(1).toLowerCase();
      })
      .join(" ");
  }

  renderQuestionList(questions) {
    const questionsList = questions.map((question) => {
      return <List.Item>{question}</List.Item>;
    });
    return (
      <List.Item>
        <List.Header>Questions for quoter</List.Header>
        <List ordered relaxed>
          {questionsList}
        </List>
      </List.Item>
    );
  }

  downloadFile(url, filename) {
    var link = document.createElement("a");
    link.download = filename;
    link.href = url;
    link.target = "blank";
    link.click();
  }

  renderUploadedDocuments(documents) {
    const documentsList = documents.map((document) => {
      return (
        <Table.Row>
          <Table.Cell>
            <Icon name="file" />
            {document.name}
          </Table.Cell>
          <Table.Cell>{prettysize(document.fileSize)}</Table.Cell>
          <Table.Cell>
            <Button
              onClick={() => {
                this.downloadFile(document.downloadUrl, document.name);
              }}
            >
              Open File
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
    return (
      <List.Item>
        <List.Header>Uploaded documents</List.Header>
        <Table>
          <Table.Body>{documentsList}</Table.Body>
        </Table>
      </List.Item>
    );
  }

  listOfProjectElements = () => {
    const projectHeaders = [
      "title",
      "description",
      "category",
      "speciality",
      "detailedDescription",
      "additionalInformation",
      "uploadedDocuments",
      "quoteTimeline",
      "projectTimeline",
      "budget",
      "quoterLocation",
      "questions",
    ];

    const locationHumanised = {
      "1hour": "Within a 1 hour drive of site",
      "2hour": "Within a 2 hour drive of site",
      "4hour": "Within a 4 hour drive of site",
      uk: "Anywhere in the UK",
      europe: "Anywhere within Europe",
      worldwide: "Anywhere globally",
    };
    const quoteTimelineHumanised = {
      rushed: "Needed urgently",
      "1month": "Required within 1 month",
      anytime: "No specific timeline.",
    };

    const projectTimelineHumanised = {
      "1week": "Project needs delivering within 1 week.",
      "1month": "Project needs delivering within 1 month",
      "3months": "Project needs delivering within 3 months",
      "6months": "Project needs delivering within 6 months",
      "1year": "Project needs delivering within 12 months",
      ">1year": "Project does not need delivering until after 12 months",
    };

    const projectHeadersHumanised = {
      title: { header: "Project Title", append: "" },
      description: { header: "Short Description", append: "" },
      category: { header: "Category of work", append: "" },
      speciality: { header: "Speciality", append: "" },
      detailedDescription: {
        header: "Detailed Description of Project",
        append: "",
      },
      additionalInformation: { header: "Additional Information", append: "" },
      uploadedDocuments: { header: "Uploaded Documents", append: "" },
      quoteTimeline: { header: "Timeline for Quotations", append: "" },
      projectTimeline: {
        header: "Timeline for project implementation",
        append: "",
      },
      budget: { header: "Approximate Budget", append: "£" },
      quoterLocation: { header: "Desired location of Quoter", append: "" },
      questions: { header: "Questions from the customer", append: "" },
    };
    const list = projectHeaders.map((key) => {
      if (this.props.project[key]) {
        if (key === "questions") {
          const questionList = this.renderQuestionList(this.props.project[key]);
          return questionList;
        } else if (key === "uploadedDocuments") {
          const documentList = this.renderUploadedDocuments(
            this.props.project[key]
          );
          return documentList;
        } else if (key === "quoteTimeline") {
          return (
            <List.Item>
              <List.Header>{projectHeadersHumanised[key].header}</List.Header>
              <div style={{ whiteSpace: "pre-wrap" }}>
                {projectHeadersHumanised[key].append}
                {quoteTimelineHumanised[this.props.project[key]]}
              </div>
            </List.Item>
          );
        } else if (key === "projectTimeline") {
          return (
            <List.Item>
              <List.Header>{projectHeadersHumanised[key].header}</List.Header>
              <div style={{ whiteSpace: "pre-wrap" }}>
                {projectHeadersHumanised[key].append}
                {projectTimelineHumanised[this.props.project[key]]}
              </div>
            </List.Item>
          );
        } else if (key === "quoterLocation") {
          return (
            <List.Item>
              <List.Header>{projectHeadersHumanised[key].header}</List.Header>
              <div style={{ whiteSpace: "pre-wrap" }}>
                {projectHeadersHumanised[key].append}
                {locationHumanised[this.props.project[key]]}
              </div>
            </List.Item>
          );
        } else if (key === "budget") {
          const formattedBudget = new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(this.props.project[key]);

          return (
            <List.Item>
              <List.Header>{projectHeadersHumanised[key].header}</List.Header>
              {/* <List.Header>{this.convertCamelToHumanReadable(key)}</List.Header> */}
              <div style={{ whiteSpace: "pre-wrap" }}>
                {/* {projectHeadersHumanised[key].append} */}
                {formattedBudget}
              </div>
            </List.Item>
          );
        }
      }

      return (
        <List.Item>
          <List.Header>{projectHeadersHumanised[key].header}</List.Header>
          {/* <List.Header>{this.convertCamelToHumanReadable(key)}</List.Header> */}
          <div style={{ whiteSpace: "pre-wrap" }}>
            {projectHeadersHumanised[key].append}
            {this.props.project[key]}
          </div>
        </List.Item>
      );
    });
    return list;
  };

  projectGenerator = () => {
    if (!this.props.projectLoaded) {
      return this.projectLoading();
    } else {
      console.log(this.props.project);
      return (
        <Segment>
          <List relaxed>{this.listOfProjectElements()}</List>
        </Segment>
      );
    }
  };

  handleSetAsApproved = () => {
    const { uuid, owner } = this.props.project;
    // console.log(projectRef);
    this.setState({ publishingLoading: true });
    this.props.adminSetProjectAsApprovedInFirestore(uuid, owner);
    setTimeout(() => {
      this.props.getOneUsersProjectFromFirestore(uuid);
      setTimeout(
        () =>
          this.setState({
            publishElementDisplay: false,
            publishingLoading: false,
          }),
        1000
      );
    }, 2000);
  };

  setApprovalStatusConfirmation = () => {
    if (this.state.publishElementDisplay) {
      return (
        <Segment raised>
          <Segment placeholder>
            <Header as="h3">
              You are about to approve / reject this project!
              <Header.Subheader>
                Ensure all project details meet the required standard before
                approving.
              </Header.Subheader>
            </Header>
            <p>
              Once approved (and published by the customer) this project will go
              into the 'Requires go live' Section and will require sanitising to
              publish.
            </p>
            <p>To successfully approve a project it should:</p>
            <List bulleted>
              <List.Item>
                Contain all the relevant information required to produce an
                effective quote
              </List.Item>
              <List.Item>
                Include all relevant documentation required to produce an
                effective quote. (drawings, diagrams, manuals, sample programs,
                etc)
              </List.Item>
              <List.Item>
                Follow our best practice guidelines for effective project
                requirements.
              </List.Item>
            </List>
            <Divider horizontal>
              <Header as="h4">What is your decision?</Header>
            </Divider>
            <Button.Group>
              <Button
                negative
                onClick={() => {
                  this.setState({ rejectionReasonDisplay: true });
                }}
              >
                Reject
              </Button>
              <Button.Or />
              <Button onClick={this.handleSetAsApproved} color="teal">
                Approve
              </Button>
            </Button.Group>
          </Segment>
        </Segment>
      );
    }
  };

  since(time) {
    const timeNow = Math.floor(Date.now() / 1000);
    const elapsedSeconds = timeNow - time.seconds;
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    const elapsedHours = Math.floor(elapsedMinutes / 60);
    const elapsedDays = Math.floor(elapsedHours / 24);

    if (elapsedDays > 1) {
      return `${elapsedDays} Day${elapsedDays === 1 ? "" : "s"}`;
    } else if (elapsedHours >= 1) {
      return `${elapsedHours} Hour${elapsedHours === 1 ? "" : "s"}`;
    } else if (elapsedMinutes >= 1) {
      return `${elapsedMinutes} Minute${elapsedMinutes === 1 ? "" : "s"}`;
    } else if (elapsedSeconds >= 1) {
      return `${elapsedSeconds} Second${elapsedSeconds === 1 ? "" : "s"}`;
    }
    return "Error";
  }

  generateProjectStatusAndButtons = () => {
    const {
      isLive,
      isApprovedByAdmin,
      isRejectedByAdmin,
      isPublished,
      isCancelled,
      isCompleted,
      isDraft,
      createdAt,
      updatedAt,
    } = this.props.project;
    console.log(this.props.project);
    let status = (
      <Header as="h2" textAlign="centre">
        Loading
      </Header>
    );
    let details =
      "Loading project status. If this does not load contact support.";
    let action = <></>;
    if (isLive) {
      // TODO implement process to do something with live projects. Maybe mark them as complete by admins as one option, and pull them from the market as the second, then cancel them as the third.
      status = (
        <Header as="h2" textAlign="centre" color="green">
          Live
        </Header>
      );
      details = `Project is live since ${this.since(updatedAt)}.`;
      action = (
        <Button
          onClick={() =>
            alert(
              "Need to decide what Admins can do to a live project, maybe kick it back to rejected?"
            )
          }
          size="tiny"
        >
          Not Implemented
        </Button>
      );
    } else if (isApprovedByAdmin) {
      // TODO implement process to manage approved projects which are still in draft
      status = (
        <Header as="h2" textAlign="centre" color="olive">
          Approved
        </Header>
      );
      details = `Project has been approved by Admins but has been left in draft status for ${this.since(
        createdAt
      )}. \nCustomer must publish it to go live.`;
      action = (
        <Button
          onClick={() => {
            alert(
              "No actions wired up yet, contact customer to ask them to publish the draft"
            );
          }}
          color="teal"
          size="tiny"
        >
          Not implemented
        </Button>
      );
    } else if (isRejectedByAdmin) {
      // TODO implement process to change rejected project to accepted.
      status = (
        <Header as="h2" textAlign="centre" color="orange">
          Returned
        </Header>
      );
      details = `Project has been rejected for publishing and requires additional information to go live. \nChanged your mind? Speak to Tom to change to approved.`;
      action = (
        <Button
          onClick={() =>
            alert(
              "Not sure how to best implement a *cancel* rejected process yet."
            )
          }
          // color='teal'
          size="tiny"
        >
          Not implemented
        </Button>
      );
    } else if (isPublished) {
      status = (
        <Header as="h2" textAlign="centre" color="olive">
          Pending
        </Header>
      );
      details = `Project has been submitted for publishing and has been waiting for approval from Admins to go live for ${this.since(
        updatedAt
      )}\nEdit this project to make any more changes before approval and republish to go live.`;
      action = (
        <Button
          onClick={() => {
            this.setState({ publishElementDisplay: true });
          }}
          color="teal"
          size="tiny"
        >
          Decide
        </Button>
      );
    } else if (isCancelled) {
      // TODO decide what admins can do to cancelled projects. Probably nothing, might be best to only allow customers to resurrect projects.
      status = (
        <Header as="h2" textAlign="centre" color="Brown">
          Cancelled
        </Header>
      );
      details = `This project was cancelled on ${updatedAt}. \n`;
      action = (
        <Button
          onClick={() => {
            this.setState({ publishElementDisplay: true });
          }}
          color="teal"
          size="tiny"
        >
          Revive/Publish
        </Button>
      );
    } else if (isCompleted) {
      // TODO decide what Admins can do to completed projects. Probably best to implement a way for customers to revive dead/finished projects into a new project.
      status = (
        <Header as="h2" textAlign="centre" color="green">
          Completed
        </Header>
      );
      details = `This project was marked as completed on ${updatedAt}.\n Contact support if you wish to publish this project again.`;
      action = (
        <Button
          onClick={() => alert("contact support to republish a project")}
          size="tiny"
        >
          RePublish
        </Button>
      );
    } else if (isDraft) {
      status = (
        <Header as="h2" textAlign="centre" color="grey">
          Draft
        </Header>
      );
      details = `Project has been a draft for ${this.since(
        createdAt
      )}. \nPublish this project and it will go live pending approval.`;
      action = (
        <Button
          onClick={() => {
            this.setState({ publishElementDisplay: true });
          }}
          color="teal"
          size="tiny"
        >
          Decide
        </Button>
      );
    }
    return (
      <Table attached celled>
        <Table.Header>
          <Table.HeaderCell singleLine>Status</Table.HeaderCell>
          <Table.HeaderCell singleLine>Details</Table.HeaderCell>
          <Table.HeaderCell singleLine> Action</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{status}</Table.Cell>
            <Table.Cell style={{ whiteSpace: "pre-wrap" }}>
              {details}
            </Table.Cell>
            <Table.Cell style={{ whiteSpace: "pre-wrap" }}>{action}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  renderUserProfile = () => {
    if (this.props.projectLoaded) {
      return <ProjectUserProfileDisplay userId={this.props.project.owner} />;
    }
  };

  renderSetAsRejectedReasonForm = () => {
    if (this.state.rejectionReasonDisplay) {
      return (
        <Segment>
          <Header>Reason for Rejection</Header>

          <Formik
            initialValues={{
              rejectionReason: "",
            }}
            validationSchema={Yup.object().shape({
              rejectionReason: Yup.string()
                .min(20, "Rejection reason must be at least 20 characters")
                .required("A Rejection Reason is required."),
            })}
            onSubmit={(fields) => {
              console.log(fields.rejectionReason);
              this.setState({ rejectionReasonLoading: true });
              this.props.adminSetProjectAsRejectedInFirestore(
                this.props.project.uuid,
                this.props.project.owner,
                fields.rejectionReason
              );
              setTimeout(() => {
                this.props.getOneUsersProjectFromFirestore(
                  this.props.project.uuid
                );
                setTimeout(
                  () =>
                    this.setState({
                      publishElementDisplay: false,
                      publishingLoading: false,
                      rejectionReasonDisplay: false,
                      rejectionReasonLoading: false,
                    }),
                  1000
                );
              }, 2000);
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Form.Input
                    label="Reason for Rejection"
                    error={
                      touched["rejectionReason"] && errors["rejectionReason"]
                    }
                    required
                  >
                    <TextArea
                      placeholder="Reason for Rejection"
                      name="rejectionReason"
                      value={values.rejectionReason}
                      blur={handleBlur}
                      onChange={handleChange}
                      control="textarea"
                      rows="4"
                    />
                  </Form.Input>
                  <Button onClick={handleSubmit}>Submit</Button>
                </Form>
              );
            }}
          />
        </Segment>
      );
    }
  };

  renderRejectionReason() {
    if (this.props.projectLoaded) {
      if (this.props.project.isDraft || this.props.project.isPublished) {
        if (this.props.project.rejectionReason) {
          return (
            <Segment raised>
              <Segment inverted color="red">
                <Header>Previously Rejected Project</Header>
                Read the previous rejection reason and ensure that the updated
                project information now complies with our baseline requirements.
                <Header as="h4">Reason:</Header>
                <div style={{ whiteSpace: "pre-wrap" }}>
                  {this.props.project.rejectionReason}
                </div>
              </Segment>
            </Segment>
          );
        }
      }
    }
  }

  render() {
    return (
      <Container text>
        <br />
        <br />
        <Segment.Group raised>
          <Segment>
            <Button
              as={Link}
              to={ROUTES.ADMIN}
              color="teal"
              size="tiny"
              floated="right"
            >
              Back
            </Button>

            {/* <Button
              as={Link}
              to={`${ROUTES.EDIT_PROJECT_PREFIX}${this.props.match.params.projectRef}`}
              // color='teal'
              size='tiny'
              floated='right'
            >
              Edit
            </Button> */}
            <Header as="h2">
              <Header.Content>
                View Project
                <Header.Subheader>
                  Approve, or Reject this project
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Segment>
          <Segment basic>
            <Dimmer
              active={
                this.props.adminProjectApprovalRequest ||
                this.state.publishingLoading
              }
              inverted
            >
              <Loader size="large" inverted>
                Publishing project . . .
              </Loader>
            </Dimmer>
            {this.generateProjectStatusAndButtons()}
          </Segment>
          {this.renderRejectionReason()}
          {this.setApprovalStatusConfirmation()}
          {this.renderSetAsRejectedReasonForm()}
          <Segment>
            {/* <ProjectUserProfileDisplay userId={this.props.project.owner} /> */}
            {this.renderUserProfile()}
          </Segment>
          <Segment basic>{this.projectGenerator()}</Segment>
        </Segment.Group>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projectLoaded: state.firestoreReducers.gotUserProjectFromFirestore,
    project: state.firestoreReducers.userProject,
    adminProjectApprovalRequest:
      state.firestoreReducers.adminProjectApprovalRequest,
    adminProjectApprovalRequestSuccess:
      state.firestoreReducers.adminProjectApprovalRequestSuccess,
    adminProjectApprovalRequestError:
      state.firestoreReducers.adminProjectApprovalRequestError,
    adminProjectApprovalRequestErrorMessage:
      state.firestoreReducers.adminProjectApprovalRequestErrorMessage,
  };
};

export default connect(mapStateToProps, {
  getOneUsersProjectFromFirestore,
  adminSetProjectAsApprovedInFirestore,
  adminSetProjectAsApprovedInFirestoreStateClear,
  adminSetProjectAsRejectedInFirestore,
  adminSetProjectAsRejectedInFirestoreStateClear,
})(AdminShowProject);
