import React from 'react';

import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import App from './App';
import configureStore from './configureStore';
import history from './history';

import { Helmet } from 'react-helmet';

const store = configureStore();

function Root() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Helmet>
          <script>{`
          (function() {
            if(typeof(jQuery)=='undefined')
            {
              var jScript = document.createElement('script'); jScript.type = 'text/javascript';
              jScript.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'www.googletagmanager.com/gtag/js?id=AW-595125483';
              var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(jScript, s);
            }
          })();

          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-595125483');
          
          `}</script>
        </Helmet>
        <App />
      </Router>
    </Provider>
  );
}
export default Root;
