import React from 'react';
import { connect } from 'react-redux';

import { getOneUsersProjectFromFirestore } from '../actions';
import NewProject from './NewProject';

class EditProject extends React.Component {
  componentDidMount = () => {
    this.props.getOneUsersProjectFromFirestore(
      this.props.match.params.projectRef
    );
  };
  render() {
    // console.log(this.props);
    return (
      <NewProject
        continue={true}
        projectRef={this.props.match.params.projectRef}
        project={this.props.project}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    projectLoaded: state.firestoreReducers.gotUserProjectFromFirestore,
    project: state.firestoreReducers.userProject,
  };
};

export default connect(mapStateToProps, { getOneUsersProjectFromFirestore })(
  EditProject
);
