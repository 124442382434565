import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Container, Header, Icon } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

import * as ROUTES from '../constants/routes';

class SignUpThankYou extends React.Component {
  render() {
    return (
      <Container text>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Thank You</title>
          <meta
            name="description"
            content="Thank you for signing up for Machine Builders.co.uk"
          />
          <script>{`
          (function() {
            if(typeof(jQuery)=='undefined')
            {
              var jScript = document.createElement('script'); jScript.type = 'text/javascript';
              jScript.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'www.googletagmanager.com/gtag/js?id=AW-595125483';
              var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(jScript, s);
            }
          })();

          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-595125483');
          gtag('event', 'conversion', {'send_to': 'AW-595125483/U-tnCJ_T0t4BEOvJ45sC'});
          `}</script>

          {/* <script>{`
          window.addEventListener('load',function(){
            if(window.location.pathname.substring(0, 19)=="/projects/thankyou/"){
              gtag('event', 'conversion', {'send_to': 'AW-595125483/V_yfCJ-vod8BEOvJ45sC'});
            };
            if(window.location.pathname=="/signup/thankyou"){
              gtag('event', 'conversion', {'send_to': 'AW-595125483/U-tnCJ_T0t4BEOvJ45sC'});
            };
            
          })
          `}</script> */}
        </Helmet>
        <Header
          as="h1"
          content="Thank You"
          style={{
            fontSize: '4em',
            fontWeight: 'normal',
            marginBottom: 0,
            marginTop: '0.8em',
          }}
        />
        <Header
          as="h2"
          content="Thank you for signing up to Machine Builders"
          style={{
            fontSize: '1.7em',
            fontWeight: 'normal',
            marginTop: 0,
            marginBottom: '1em',
          }}
        />
        <img
          class="ui centered medium image"
          src="https://firebasestorage.googleapis.com/v0/b/machine-builders.appspot.com/o/staticFiles%2F512.png?alt=media&token=87c463a0-133e-46da-a07c-9fea7d0b7561"
          alt="Machine builders logo"
        />
        <br />

        <p>
          Thank you for taking your first step in finding the best machine
          builders by signing up for our platform!
        </p>
        <p>
          Head to your homepage and complete your profile to start posting
          projects.
        </p>
        <Button as={Link} to={ROUTES.HOME} color="teal" fluid size="large">
          Get Started
          <Icon name="right arrow" />
        </Button>
      </Container>
    );
  }
}

export default SignUpThankYou;
