import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Button,
  Container,
  Form,
  Header,
  Message,
  Popup,
  Table,
} from 'semantic-ui-react';

import { Formik } from 'formik';
import * as Yup from 'yup';

import * as ROUTES from '../../constants/routes';

import {
  getUserInfoFromFirestore,
  updateFirestoreUserAsInitialised,
  userAuthEmailResendConfirmation,
  userAuthEmailResendConfirmationStateClear,
  changeUserAuthEmail,
  changeUserAuthEmailStateClear,
} from '../../actions';

class ProfileManage extends React.Component {
  state = {
    showEmailChangeForm: false,
    resendSent: false,
    timeoutOn: false,
  };

  componentDidMount() {
    this.props.getUserInfoFromFirestore();
    this.props.userAuthEmailResendConfirmationStateClear();
    this.props.changeUserAuthEmailStateClear();
  }

  toggleEmailChangeForm = () => {
    this.setState({ showEmailChangeForm: !this.state.showEmailChangeForm });
  };

  handleNewEmailChange = ({ target }) => {
    this.setState({ newEmail: target.value });
  };

  renderNewEmailStatusMessage() {
    if (this.props.changingSignInEmailError) {
      return (
        <Message error>
          <Message.Header>Error</Message.Header>
          <p>{this.props.changingSignInEmailErrorMessage}</p>
          <p>Log out and log in again to retry.</p>
        </Message>
      );
    } else if (this.props.changingSignInEmailSuccess) {
      return (
        <Message success>
          <Message.Header>Success!</Message.Header>
          <p>Your new email address has been registered!</p>
          <p>
            Send a new confirmation email below to confirm the new email
            address.
          </p>
        </Message>
      );
    }
  }

  renderEmailConfirmationStatusMessage() {
    if (this.props.sendingNewEmailConfirmationError) {
      return (
        <Message error>
          <Message.Header>Error</Message.Header>
          <p>{this.props.sendingNewEmailConfirmationErrorMessage}</p>
        </Message>
      );
    } else if (this.props.sendingNewEmailConfirmationSuccess) {
      return (
        <Message success>
          <Message.Header>Success!</Message.Header>
          <p>
            A new confirmation email has been sent to {this.props.userEmail}
          </p>
          <p>Check your email and click on the link to confirm this address.</p>
        </Message>
      );
    }
  }

  renderEmailChangeForm() {
    // TODO: replace this with formik and add validation that it is a correct email
    if (this.state.showEmailChangeForm) {
      return (
        <Table.Row>
          <Table.Cell>New Email</Table.Cell>
          <Table.Cell>
            <Message warning>
              <Message.Header>Warning!</Message.Header>
              <p>Ensure you enter the new email address correctly!</p>
              <p>
                You need to have recently signed in to complete this action. If
                you receive an error message, log out and log in to try again.
              </p>
            </Message>
            <Formik
              initialValues={{ newEmail: '' }}
              validationSchema={Yup.object().shape({
                newEmail: Yup.string('A valid email address is required')
                  .email('A valid email address is required')
                  .required('New email address is required')
                  .min(2, 'A valid email address is required'),
              })}
              onSubmit={(fields) => {
                // console.log(fields.newEmail);
                // this.props.updateFirestoreUserInfo(this.props.userUid, fields);
                this.props.changeUserAuthEmail(fields.newEmail);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => {
                // console.log(errors);
                // console.log(touched);
                return (
                  <Form>
                    {/* // removed email for now as we need to update and validate the log in record if this changes . . .*/}
                    <Form.Input
                      fluid
                      icon="edit outline"
                      iconPosition="left"
                      label="Email"
                      placeholder="Email"
                      name="newEmail"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newEmail}
                      error={touched.newEmail && errors.newEmail}
                      required
                    />

                    <Button
                      color="teal"
                      fluid
                      size="large"
                      type="submit"
                      loading={this.props.changingSignInEmail}
                      onClick={handleSubmit}
                    >
                      Submit New Email
                    </Button>
                  </Form>
                );
              }}
            </Formik>
            {this.renderNewEmailStatusMessage()}
          </Table.Cell>
        </Table.Row>
      );
    }
  }

  checkIfResendSent() {
    if (this.props.sendingNewEmailConfirmationSuccess) {
      if (!this.state.resendSent) {
        this.setState({ resendSent: true });
      }

      if (this.state.resendSent && this.state.timeoutOn === false) {
        setTimeout(() => {
          this.setState({ resendSent: false, timeoutOn: true });
          this.props.userAuthEmailResendConfirmationStateClear();
        }, 120000);
      }
    }
  }

  renderResendButton() {
    if (this.state.resendSent) {
      return (
        <Popup
          content="You must wait for a few minutes before trying to send another email."
          trigger={
            <Button
              content="Resend"
              floated="right"
              color="teal"
              loading={this.sendingNewEmailConfirmation}
            />
          }
        />
      );
    } else {
      return (
        <Button
          floated="right"
          color="teal"
          loading={this.sendingNewEmailConfirmation}
          onClick={() => {
            this.props.userAuthEmailResendConfirmation();
          }}
        >
          Resend
        </Button>
      );
    }
  }

  render() {
    // console.log(this.state);
    this.checkIfResendSent();
    return (
      <Container text>
        <Header
          as="h1"
          content="Manage Profile"
          style={{
            fontSize: '4em',
            fontWeight: 'normal',
            marginBottom: 0,
            marginTop: '0.5em',
          }}
        />
        <Header
          as="h2"
          content="Manage your account settings here"
          style={{
            fontSize: '1.7em',
            fontWeight: 'normal',
            marginTop: 0,
            marginBottom: '1em',
          }}
        />

        <Table size="large" attached="top">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell> Account Settings</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        </Table>

        <Table size="large" attached>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Sign In Method:</Table.Cell>
              <Table.Cell>Email and Password</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Email</Table.Cell>
              <Table.Cell>
                <Button
                  floated="right"
                  color="teal"
                  onClick={this.toggleEmailChangeForm}
                >
                  Change
                </Button>
                {this.props.userEmail}
              </Table.Cell>
            </Table.Row>
            {this.renderEmailChangeForm()}
            <Table.Row>
              <Table.Cell>Verified</Table.Cell>
              <Table.Cell>
                {this.renderResendButton()}
                {this.props.emailVerified ? 'True' : 'False'}
                {this.renderEmailConfirmationStatusMessage()}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Password</Table.Cell>
              <Table.Cell>
                <Button
                  floated="right"
                  color="teal"
                  as={Link}
                  to={ROUTES.PASSWORD_RESET}
                >
                  Reset
                </Button>
                &nbsp;
              </Table.Cell>
            </Table.Row>
          </Table.Body>
          {/* TODO implement ability to change marketting preference */}
          {/* <Table.Row>
                <Table.Cell>Marketting</Table.Cell>
                <Table.Cell>
                  <Button floated="right">Disabled</Button>
                </Table.Cell>
              </Table.Row> */}
        </Table>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    userRecord: state.firestoreReducers.userRecord,
    userFirstName: state.firestoreReducers.userRecord.firstName,
    userLastName: state.firestoreReducers.userRecord.lastName,
    emailVerified: state.auth.user.emailVerified,
    userEmail: state.auth.user.email,
    changingSignInEmail: state.auth.changingSignInEmail,
    changingSignInEmailSuccess: state.auth.changingSignInEmailSuccess,
    changingSignInEmailError: state.auth.changingSignInEmailError,
    changingSignInEmailErrorMessage: state.auth.changingSignInEmailErrorMessage,
    sendingNewEmailConfirmation: state.auth.sendingNewEmailConfirmation,
    sendingNewEmailConfirmationSuccess:
      state.auth.sendingNewEmailConfirmationSuccess,
    sendingNewEmailConfirmationError:
      state.auth.sendingNewEmailConfirmationError,
    sendingNewEmailConfirmationErrorMessage:
      state.auth.sendingNewEmailConfirmationErrorMessage,
  };
}

export default connect(mapStateToProps, {
  getUserInfoFromFirestore,
  updateFirestoreUserAsInitialised,
  userAuthEmailResendConfirmation,
  userAuthEmailResendConfirmationStateClear,
  changeUserAuthEmail,
  changeUserAuthEmailStateClear,
})(ProfileManage);
