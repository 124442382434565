import React from 'react';

import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';

class Wizard extends React.Component {
  render() {
    if (this.props.page === 0) {
      return (
        <Page1
          touched={this.props.touched}
          handleChange={this.props.handleChange}
          errors={this.props.errors}
          handleBlur={this.props.handleBlur}
          values={this.props.values}
          setFieldValue={this.props.setFieldValue}
          setFieldTouched={this.props.setFieldTouched}
        />
      );
    } else if (this.props.page === 1) {
      return (
        <Page2
          touched={this.props.touched}
          handleChange={this.props.handleChange}
          errors={this.props.errors}
          handleBlur={this.props.handleBlur}
          values={this.props.values}
          setFieldValue={this.props.setFieldValue}
        />
      );
    } else if (this.props.page === 2) {
      return (
        <Page3
          touched={this.props.touched}
          handleChange={this.props.handleChange}
          errors={this.props.errors}
          handleBlur={this.props.handleBlur}
          values={this.props.values}
          setFieldValue={this.props.setFieldValue}
          setFieldTouched={this.props.setFieldTouched}
        />
      );
    } else if (this.props.page === 3) {
      return (
        <Page4
          touched={this.props.touched}
          handleChange={this.props.handleChange}
          errors={this.props.errors}
          handleBlur={this.props.handleBlur}
          values={this.props.values}
          setFieldValue={this.props.setFieldValue}
          setFieldTouched={this.props.setFieldTouched}
        />
      );
    }
  }
}

export default Wizard;
