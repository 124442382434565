import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Button,
  Container,
  Grid,
  Header,
  List,
  Segment,
} from 'semantic-ui-react';

import * as ROUTES from '../constants/routes';

class Footer extends React.Component {
  callToActionButton() {
    if (this.props.isAuthenticated) {
      return (
        <Button inverted as={Link} to={ROUTES.NEW_PROJECT}>
          Create Project
        </Button>
      );
    } else {
      return (
        <Button inverted as={Link} to={ROUTES.SIGN_UP}>
          Sign Up
        </Button>
      );
    }
  }
  render() {
    return (
      <div style={{ paddingTop: '5vh' }}>
        <Segment
          inverted
          vertical
          style={{ padding: '5em 0em', background: '#00b5ad' }}
        >
          <Container>
            <Grid divided inverted stackable>
              <Grid.Row>
                <Grid.Column width={3}>
                  <Header inverted as='h4' content='Pages' />
                  <List link inverted>
                    <List.Item as={Link} to={ROUTES.ABOUT_US}>
                      About Us
                    </List.Item>
                    <List.Item as={Link} to={ROUTES.FAQ}>
                      FAQ
                    </List.Item>
                    <List.Item as={Link} to={ROUTES.CONTACT_US}>
                      Contact
                    </List.Item>
                    <List.Item as={Link} to={ROUTES.PROJECTS}>
                      Projects
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header inverted as='h4' content='Content' />
                  <List link inverted>
                    <List.Item as={Link} to={ROUTES.PROJECTS}>
                      Register as a quoter?
                    </List.Item>
                    <List.Item as={Link} to={ROUTES.TERMS_AND_CONDITIONS}>
                      Terms and Conditions
                    </List.Item>
                    <List.Item as={Link} to={ROUTES.PRIVACY_POLICY}>
                      Privacy Policy
                    </List.Item>
                    <List.Item as={Link} to={ROUTES.BLOG}>
                      Blog
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={7}>
                  <Header as='h4' inverted>
                    Have a project in mind?
                  </Header>
                  <p>
                    Why not post your project for free and give us a chance to
                    show you how we operate.
                  </p>
                  {this.callToActionButton()}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
}
export default connect(mapStateToProps, {})(Footer);
