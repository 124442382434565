import React from 'react';
import { connect } from 'react-redux';

import { Container, Header } from 'semantic-ui-react';

import {
  getUserInfoFromFirestore,
  getAllUsersProjectsFromFirestore,
} from '../../actions';
import ProjectList from './ProjectList';
import ProfileDisplay from './ProfileDisplay';
import WelcomeModal from './WelcomeModal';
import NoProjectModal from './NoProjectModal';

class Home extends React.Component {
  componentDidMount() {
    this.props.getUserInfoFromFirestore();
    this.props.getAllUsersProjectsFromFirestore();
  }

  render() {
    return (
      <Container text>
        <Header
          as="h1"
          content="Homepage"
          style={{
            fontSize: '4em',
            fontWeight: 'normal',
            marginBottom: 0,
            marginTop: '0.8em',
          }}
        />
        <Header
          as="h2"
          content="Home of your profile and projects"
          style={{
            fontSize: '1.7em',
            fontWeight: 'normal',
            marginTop: 0,
          }}
        />
        <WelcomeModal />
        <NoProjectModal />
        <ProfileDisplay />

        {/* {this.greetingGenerator()} */}

        <br />
        <ProjectList />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    logoutError: state.auth.logoutError,
  };
}

export default connect(mapStateToProps, {
  getUserInfoFromFirestore,
  getAllUsersProjectsFromFirestore,
})(Home);
