import React from "react";
import { connect } from "react-redux";

import {
  Button,
  // Card,
  Container,
  Dimmer,
  Header,
  Icon,
  Image,
  Loader,
  Message,
  Modal,
  Placeholder,
  Popup,
  Segment,
  Table,
  Grid,
  Divider,
} from "semantic-ui-react";
import prettysize from "prettysize";

import "react-image-crop/dist/ReactCrop.css";

import {
  getUserInfoFromFirestore,
  uploadUserLogo,
  uploadUserLogoStateClear,
} from "../../actions";
import history from "../../history";
import * as ROUTES from "../../constants/routes";

import ImageCropper from "./ImageCropper";

class ProfileImageUpload extends React.Component {
  state = {
    initialised: false,
    logoFile: null,
    logoBlob: null,
    currentLogo: null,
    imageLoadedStatus: "loading",
    modalOpen: false,
    loadingModalOpen: false,
  };

  componentDidMount() {
    this.props.getUserInfoFromFirestore();
    this.props.uploadUserLogoStateClear();
    setTimeout(() => {
      this.setState({ initialised: true });
    }, 1000);
  }

  handleSelectFile = (e) => {
    // Check if a file has been uploaded. If it has, update the state with the file.
    // Once the state has been updated call the callback function to update state in redux.
    if (e.target.files[0]) {
      this.setState({ logoFile: e.target.files[0] }, () => {
        // console.log(this.state);
        this.props.uploadUserLogoStateClear();
      });
    }
  };

  handleUpload = () => {
    if (this.state.logoFile) {
      console.log("uploading");
      this.setState({ loadingModalOpen: true, imageLoadedStatus: "loading" });
      this.props.uploadUserLogo(this.state.logoBlob);
    }
  };
  handleRedirect() {
    // TODO:
    // Create reducers for file upload. If the file upload is successful and we see that reflected in the state,
    // we should use history .push to redirect people back to the home path
    if (
      this.props.uploadingUserLogoSuccess &&
      this.state.initialised &&
      this.state.imageLoadedStatus === "loaded"
    ) {
      setTimeout(() => {
        history.push(ROUTES.HOME);
      }, 2000);
      return (
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>Logo successfully uploaded</Message.Header>
            Redirecting you to your homepage . . .
          </Message.Content>
        </Message>
      );
    }
  }

  renderLogoUploader() {
    // console.log(this.state);
    let headerText = "";
    let headerSubtitle = "";

    if (this.props.userCompanyRecord) {
      if (
        this.props.userCompanyRecord.logoUrl === null &&
        this.state.logoFile === null
      ) {
        headerText = "You currently have no logo uploaded!";
        headerSubtitle = "Select a logo file to crop and upload";
      } else if (this.state.logoFile === null) {
        headerText = "Select a new logo file to crop and upload.";
      }
    }

    if (this.state.logoFile === null) {
      return (
        <>
          <Header as="h3" icon>
            <Icon name="file image outline" />
            {headerText}
            {headerSubtitle && (
              <Header.Subheader>{headerSubtitle}</Header.Subheader>
            )}
          </Header>
          <br />
          <Button as="label" color="teal" htmlFor="file" type="button">
            Select logo file
          </Button>
        </>
      );
    } else {
      return this.renderLogoInformation();
    }
  }

  renderLogoInfoButtons() {
    if (this.state.logoBlob) {
      return (
        <Grid columns="equal">
          <Grid.Column>
            <Button as="label" htmlFor="file" type="button">
              Select different logo file
            </Button>
          </Grid.Column>
          <Grid.Column>
            <Button
              onClick={() => {
                this.setState({ modalOpen: true });
              }}
            >
              Edit Image Crop
            </Button>
            <ImageCropper
              modalOpen={this.state.modalOpen}
              closeModal={this.closeModal}
              setImageBlob={this.setImageBlob}
              logoFile={this.state.logoFile}
            ></ImageCropper>
          </Grid.Column>
          <Grid.Column>
            <Button onClick={this.handleUpload} color="teal">
              Submit and Upload
            </Button>
          </Grid.Column>
        </Grid>
      );
    } else if (this.state.logoFile) {
      return (
        <Grid columns="equal">
          <Grid.Column>
            <Button as="label" htmlFor="file" type="button">
              Select different logo file
            </Button>
          </Grid.Column>
          <Grid.Column>
            <Button
              color="teal"
              onClick={() => {
                this.setState({ modalOpen: true });
              }}
            >
              Crop Image
            </Button>
            <ImageCropper
              modalOpen={this.state.modalOpen}
              closeModal={this.closeModal}
              setImageBlob={this.setImageBlob}
              logoFile={this.state.logoFile}
            ></ImageCropper>
          </Grid.Column>
          <Grid.Column>
            <Popup
              trigger={
                <Button
                  onClick={() => {
                    alert("You must crop your image before uploading!");
                  }}
                >
                  Submit and Upload
                </Button>
              }
              content="Crop your image first before uploading!."
              basic
            />
          </Grid.Column>
        </Grid>
      );
    }
  }

  renderLogoInformation() {
    if (this.state.logoFile) {
      return (
        <>
          <Header icon>
            <Icon name="file image outline" />
            Logo file selected:
          </Header>
          <Table>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <Icon name="file image" />
                  {this.state.logoFile.name}
                </Table.Cell>
                <Table.Cell>{prettysize(this.state.logoFile.size)}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          {/* <Button onClick={this.handleUpload} color='teal'>
            Upload
          </Button> */}

          {this.renderLogoInfoButtons()}
        </>
      );
    }
  }

  setImageBlob = (logoBlob) => {
    this.setState({ logoBlob: logoBlob });
    // console.log('got image blob in the top end!');
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  closeUploadingModal = () => {
    this.setState({ loadingModalOpen: false });
  };

  cropImageModal(logoFile) {
    return (
      <>
        <Modal.Header>Crop your logo</Modal.Header>
        <ImageCropper logoFile={logoFile} />
      </>
    );
  }

  handleImageLoaded = () => {
    this.setState({ imageLoadedStatus: "loaded" });
  };

  handleImageLoadedError = () => {
    this.setState({ imageLoadedStatus: "error" });
  };

  displayCurrentLogo() {
    if (this.props.userCompanyRecord) {
      if (this.props.userCompanyRecord.logoUrl) {
        if (this.state.currentLogo === null) {
          this.setState({
            currentLogo: this.props.userCompanyRecord.currentLogo,
          });
        }
        return (
          <>
            {this.state.imageLoadedStatus === "loading" && (
              <Placeholder>
                <Placeholder.Image square />
              </Placeholder>
            )}
            <Image
              src={this.props.userCompanyRecord.logoUrl}
              size="medium"
              rounded
              onLoad={this.handleImageLoaded}
              onError={this.handleImageErrored}
              className={
                this.state.imageLoadedStatus === "loading" ? "hidden" : ""
              }
            />
          </>
        );
      } else {
        return (
          <Image
            src="https://react.semantic-ui.com/images/wireframe/image.png"
            size="medium"
            rounded
          />
        );
      }
    }
  }

  displayNewLogo() {
    if (this.state.logoFile !== null && this.state.logoBlob !== null) {
      const tempUrl = URL.createObjectURL(this.state.logoBlob);
      return <Image src={tempUrl} size="medium" rounded />;
    }
  }

  displayLogos() {
    const currentLogo = this.displayCurrentLogo();
    const newLogo = this.displayNewLogo();
    let newLogoSubheader = "";
    if (this.state.logoBlob) {
      newLogoSubheader = "Pending upload";
    } else if (this.state.logoFile) {
      newLogoSubheader = "Crop your selected file to continue";
    } else {
      newLogoSubheader = "Select a file to crop and upload";
    }
    return (
      <>
        <Grid columns="two" divided>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">Current Logo</Header>
              {currentLogo}
            </Grid.Column>
            <Grid.Column>
              <Header as="h4">
                New Logo<Header.Subheader>{newLogoSubheader}</Header.Subheader>
              </Header>
              {newLogo}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }

  syncNewLogoAndReducers() {
    if (
      this.props.uploadingUserLogoSuccess === true &&
      this.state.logoBlob !== null
    ) {
      this.setState({ ...this.state, logoBlob: null, logoFile: null });
    }
  }

  render() {
    //this.syncValuesAndState();
    this.syncNewLogoAndReducers();
    return (
      <Container text>
        <Dimmer
          active={
            this.props.uploadingUserLogo ||
            this.props.gettingUserInfoFromFirestore
          }
          inverted
        >
          <Loader size="large" inverted>
            Loading
          </Loader>
        </Dimmer>
        <br />
        <br />
        <Segment>
          <Header>
            Logo Upload
            <Header.Subheader>Add or change your company logo</Header.Subheader>
          </Header>
          <Divider />
          {this.displayLogos()}
          <Segment raised textAlign="center">
            {this.renderLogoUploader()}
            {/* {this.renderLogoUploadLoading()} */}
          </Segment>
          {/* <Segment>{this.cropImage()}</Segment> */}
          {/* hidden html input for the select a file button to attach itself to */}
          <input
            type="file"
            id="file"
            hidden
            onChange={this.handleSelectFile}
          />
          {this.handleRedirect()}
        </Segment>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    userCompanyRecord: state.firestoreReducers.userRecord.company,
    uploadingUserLogo: state.firestoreReducers.uploadingUserLogo,
    uploadingUserLogoSuccess: state.firestoreReducers.uploadingUserLogoSuccess,
    uploadingUserLogoError: state.firestoreReducers.uploadingUserLogoError,
    uploadingUserLogoErrorMessage:
      state.firestoreReducers.uploadingUserLogoErrorMessage,
    gettingUserInfoFromFirestore:
      state.firestoreReducers.isGettingUserInfoFromFirestore,
  };
}

export default connect(mapStateToProps, {
  uploadUserLogo,
  uploadUserLogoStateClear,
  getUserInfoFromFirestore,
})(ProfileImageUpload);
