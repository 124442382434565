import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Button,
  Container,
  Dimmer,
  Header,
  Icon,
  Loader,
  Placeholder,
  Segment,
  Table,
  Divider,
} from 'semantic-ui-react';

import * as ROUTES from '../../constants/routes';
import {
  getUserInfoFromFirestore,
  getAllUsersProjectsFromFirestore,
} from '../../actions';

class ProjectList extends React.Component {
  componentDidMount() {
    this.props.getUserInfoFromFirestore();
    this.props.getAllUsersProjectsFromFirestore();
  }

  since(time) {
    const timeNow = Math.floor(Date.now() / 1000);
    const elapsedSeconds = timeNow - time.seconds;
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    const elapsedHours = Math.floor(elapsedMinutes / 60);
    const elapsedDays = Math.floor(elapsedHours / 24);

    if (elapsedDays > 1) {
      return `${elapsedDays} Day${elapsedDays === 1 ? '' : 's'}`;
    } else if (elapsedHours >= 1) {
      return `${elapsedHours} Hour${elapsedHours === 1 ? '' : 's'}`;
    } else if (elapsedMinutes >= 1) {
      return `${elapsedMinutes} Minute${elapsedMinutes === 1 ? '' : 's'}`;
    } else if (elapsedSeconds >= 1) {
      return `${elapsedSeconds} Second${elapsedSeconds === 1 ? '' : 's'}`;
    }
    return 'Error';
  }

  projectTableGenerator(project) {
    let status = (
      <Header as="h2" textAlign="center">
        Loading
      </Header>
    );
    let details =
      'Loading project status. If this does not load contact support.';

    if (project.isLive) {
      status = (
        <Header as="h2" textAlign="center" color="green">
          Live
        </Header>
      );
      details = `Project is live since ${this.since(project.updatedAt)}`;
    } else if (project.isApprovedByAdmin) {
      status = (
        <Header as="h2" textAlign="center" color="olive">
          Approved
        </Header>
      );
      details = `Project has been approved by Admins but has been left in draft status for ${this.since(
        project.createdAt
      )}. \nOpen this project and publish it to go live.`;
    } else if (project.isRejectedByAdmin) {
      status = (
        <Header as="h2" textAlign="center" color="orange">
          Returned
        </Header>
      );
      details = `Project has been rejected for publishing and requires additional information to go live. \nOpen this project to view feedback and edit it to publish again.`;
    } else if (project.isPublished) {
      status = (
        <Header as="h2" textAlign="center" color="olive">
          Pending
        </Header>
      );
      details = `Project has been submitted for publishing and has been waiting for approval from Admins to go live for ${this.since(
        project.updatedAt
      )}\nOpen and edit this project to make any more changes before approval.`;
    } else if (project.isCancelled) {
      status = (
        <Header as="h2" textAlign="center" color="Brown">
          Cancelled
        </Header>
      );
      details = `This project was cancelled on ${project.updatedAt}. \n`;
    } else if (project.isCompleted) {
      status = (
        <Header as="h2" textAlign="center" color="green">
          Completed
        </Header>
      );
      details = `This project was marked as cancelled on ${project.updatedAt}. Open the project and click on 'Publish' to open it for new bids.\n `;
    } else if (project.isDraft) {
      status = (
        <Header as="h2" textAlign="center" color="grey">
          Draft
        </Header>
      );
      details = `Project has been a draft for ${this.since(
        project.createdAt
      )}. \nOpen this project and publish it to go live.`;
    }
    return (
      <>
        <Table attached="top" celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine>
                <Button
                  as={Link}
                  to={`${ROUTES.SHOW_PROJECT_PREFIX}${project.projectDocumentReference}`}
                  color="teal"
                  size="tiny"
                  floated="right"
                >
                  Open
                </Button>
                Project Title
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Header
                  as="h2"
                  textAlign="left"
                  width={16}
                  style={{ wordWrap: 'break-word' }}
                >
                  {project.title}
                </Header>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Table celled attached>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine>
                Project Description
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <p
                  textalign="left"
                  width={16}
                  style={{ wordWrap: 'break-word' }}
                >
                  {project.description}
                </p>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Table attached celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine>Status</Table.HeaderCell>
              <Table.HeaderCell singleLine>Details</Table.HeaderCell>
              {/* <Table.HeaderCell singleLine> Action</Table.HeaderCell> */}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{status}</Table.Cell>
              <Table.Cell style={{ whiteSpace: 'pre-wrap' }}>
                {details}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </>
    );
  }

  projectsGenerator = () => {
    //TODO: Create error handling display
    // console.log(this.props);
    if (!this.props.gotProjects || this.props.gettingProjectsError) {
      return (
        <Segment>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </Segment>
      );
    } else if (this.props.gotProjects && this.props.userProjects.length === 0) {
      return (
        <Segment placeholder>
          <Header icon>
            <Icon name="file outline" />
            You do not currently have any projects.
          </Header>
          <Button
            as={Link}
            to={ROUTES.NEW_PROJECT}
            color="teal"
            size="tiny"
            floated="right"
          >
            Create New Project
          </Button>
        </Segment>
      );
    } else {
      return this.props.userProjects.map((project) => {
        return (
          <React.Fragment key={`frag${project.projectDocumentReference}`}>
            <Segment basic key={project.projectDocumentReference}>
              {this.projectTableGenerator(project)}
            </Segment>
            <Divider hidden />
          </React.Fragment>
        );
      });
    }
  };

  render() {
    return (
      <Container fluid>
        <Segment.Group raised>
          <Segment>
            <Button
              as={Link}
              to={ROUTES.NEW_PROJECT}
              color="teal"
              // size='tiny'
              floated="right"
            >
              New Project
            </Button>
            <Header>My Projects</Header>
          </Segment>
          <br />
          <Segment basic>{this.projectsGenerator()}</Segment>
        </Segment.Group>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    userProjects: state.firestoreReducers.userProjects,
    gettingProjects: state.firestoreReducers.gettingUserProjectsFromFirestore,
    gotProjects: state.firestoreReducers.gotUserProjectsFromFirestore,
    gettingProjectsError:
      state.firestoreReducers.gettingUserProjectsFromFirestoreError,
  };
}

export default connect(mapStateToProps, {
  getUserInfoFromFirestore,
  getAllUsersProjectsFromFirestore,
})(ProjectList);
