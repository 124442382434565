import React from 'react';

import { Button, Segment } from 'semantic-ui-react';

class Buttons extends React.Component {
  checkReadyToProceed = () => {
    // On each page of the wizard, check all the required fields have been touched and aren't in an error state.
    // If everything has passed, render the 'next' or 'submit' button. Otherwise set fields to touched and display errors.

    const t = this.props.touched;
    const e = this.props.errors;
    const page = this.props.page + 1;

    const allPageOneTouched =
      t.title && t.description && t.category && t.speciality;
    const allPageOneOK =
      !e.title && !e.description && !e.category && !e.speciality;

    const allPageTwoTouched = t.detailedDescription;
    const allPageTwoOK = !e.detailedDescription;

    const allPageThreeTouched =
      t.quoteTimeline && t.projectTimeline && t.budget;
    const allPageThreeOK = !e.quoteTimeline && !e.projectTimeline && !e.budget;

    const allPageFourTouched = t.quoterLocation && t.questions;
    const allPageFourOK = !e.quoterLocation && !e.questions;

    if (page === 1 && allPageOneTouched && allPageOneOK) {
      return true;
    } else if (page === 2 && allPageTwoTouched && allPageTwoOK) {
      return true;
    } else if (page === 3 && allPageThreeTouched && allPageThreeOK) {
      return true;
    } else if (page === 4 && allPageFourTouched && allPageFourOK) {
      return true;
    } else {
      return false;
    }
  };

  setTouched(page) {
    const setTouched = this.props.setFieldTouched;
    page++;

    if (this.checkReadyToProceed()) {
      this.props.nextPage();
    } else {
      if (page === 1) {
        // this.props.setFieldTouched('title');
        setTouched('title');
        setTouched('description');
        setTouched('category');
        setTouched('speciality');
      } else if (page === 2) {
        setTouched('detailedDescription');
        setTouched('additionalInformation');
        setTouched('files');
      } else if (page === 3) {
        setTouched('quoteTimeline');
        setTouched('projectTimeline');
        setTouched('budget');
      } else if (page === 4) {
        setTouched('quoterLocation');
        setTouched('questions');
      }
    }
  }
  render() {
    if (this.props.page === 3 && this.checkReadyToProceed()) {
      return (
        <Segment basic>
          <Button
            color='teal'
            fluid
            size='large'
            type='submit'
            onClick={this.props.handleSubmit}
          >
            Save Project
          </Button>
        </Segment>
      );
    } else if (!this.checkReadyToProceed()) {
      return (
        // Force all fields on page to be touched and check for errors
        <Segment basic>
          <Button
            color='teal'
            fluid
            size='large'
            type='Next'
            onClick={() => this.setTouched(this.props.page)}
          >
            Check Page
          </Button>
          <p>Complete all required fields to proceed</p>
        </Segment>
      );
    } else {
      return (
        // Disable button if errors?
        <Segment basic>
          <Button
            color='teal'
            fluid
            size='large'
            type='Next'
            onClick={this.props.nextPage}
          >
            Next Page
          </Button>
        </Segment>
      );
    }
  }
}

export default Buttons;
