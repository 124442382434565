import { myFirebase, db } from '../firebase/firebase';
// import { times } from 'lodash';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

export const CREATING_NEW_USER_IN_FIRESTORE = 'CREATING_NEW_USER_IN_FIRESTORE';
export const CREATED_NEW_USER_IN_FIRESTORE = 'CREATED_NEW_USER_IN_FIRESTORE';
export const CREATING_NEW_USER_IN_FIRESTORE_ERROR =
  'CREATING_NEW_USER_IN_FIRESTORE_ERROR';

export const SEND_PASSWORD_RESET_EMAIL = 'SEND_PASSWORD_RESET_EMAIL';
export const SEND_PASSWORD_RESET_EMAIL_SUCCESS =
  'SEND_PASSWORD_RESET_EMAIL_SUCCESS';
export const SEND_PASSWORD_RESET_EMAIL_ERROR =
  'SEND_PASSWORD_RESET_EMAIL_ERROR';
export const SEND_PASSWORD_RESET_EMAIL_STATE_CLEAR =
  'SEND_PASSWORD_RESET_EMAIL_STATE_CLEAR';

export const CHANGE_SIGN_IN_EMAIL = 'CHANGE_SIGN_IN_EMAIL';
export const CHANGE_SIGN_IN_EMAIL_SUCCESS = 'CHANGE_SIGN_IN_EMAIL_SUCCESS';
export const CHANGE_SIGN_IN_EMAIL_ERROR = 'CHANGE_SIGN_IN_EMAIL_ERROR';
export const CHANGE_SIGN_IN_EMAIL_STATE_CLEAR =
  'CHANGE_SIGN_IN_EMAIL_STATE_CLEAR';

export const REQUEST_USER_AUTH_EMAIL_CONFIRMATION =
  'REQUEST_USER_AUTH_EMAIL_CONFIRMATION';
export const REQUEST_USER_AUTH_EMAIL_CONFIRMATION_SUCCESS =
  'REQUEST_USER_AUTH_EMAIL_CONFIRMATION_SUCCESS';
export const REQUEST_USER_AUTH_EMAIL_CONFIRMATION_ERROR =
  'REQUEST_USER_AUTH_EMAIL_CONFIRMATION_ERROR';
export const REQUEST_USER_AUTH_EMAIL_CONFIRMATION_STATE_CLEAR =
  'REQUEST_USER_AUTH_EMAIL_CONFIRMATION_STATE_CLEAR';

// TODO Worth adding the error message to send to the reducer upon failure

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

const receiveLogin = (user) => {
  return {
    type: LOGIN_SUCCESS,
    user,
  };
};

const loginError = (error) => {
  console.log(error.message);
  return {
    type: LOGIN_FAILURE,
    payload: error.message,
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

const receiveLogout = (user) => {
  return {
    type: LOGOUT_SUCCESS,
    user,
  };
};

const logoutError = () => {
  return {
    type: LOGOUT_FAILURE,
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST,
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS,
  };
};

const requestSendPasswordResetEmail = () => {
  return {
    type: SEND_PASSWORD_RESET_EMAIL,
  };
};

const requestSendPasswordResetEmailSuccess = () => {
  return {
    type: SEND_PASSWORD_RESET_EMAIL_SUCCESS,
  };
};
const requestSendPasswordResetEmailError = (error) => {
  return {
    type: SEND_PASSWORD_RESET_EMAIL_ERROR,
    error: error.message,
  };
};
const requestSendPasswordResetEmailStateClear = () => {
  return {
    type: SEND_PASSWORD_RESET_EMAIL_STATE_CLEAR,
  };
};

const requestChangeSignInEmail = () => {
  return {
    type: CHANGE_SIGN_IN_EMAIL,
  };
};
const requestChangeSignInEmailSuccess = () => {
  return {
    type: CHANGE_SIGN_IN_EMAIL_SUCCESS,
  };
};
const requestChangeSignInEmailError = (error) => {
  return {
    type: CHANGE_SIGN_IN_EMAIL_ERROR,
    error: error.message,
  };
};
const requestChangeSignInEmailStateClear = () => {
  return {
    type: CHANGE_SIGN_IN_EMAIL_STATE_CLEAR,
  };
};

const requestUserAuthEmailConfirmation = () => {
  return {
    type: REQUEST_USER_AUTH_EMAIL_CONFIRMATION,
  };
};
const requestUserAuthEmailConfirmationSuccess = () => {
  return {
    type: REQUEST_USER_AUTH_EMAIL_CONFIRMATION_SUCCESS,
  };
};
const requestUserAuthEmailConfirmationError = (error) => {
  return {
    type: REQUEST_USER_AUTH_EMAIL_CONFIRMATION_ERROR,
    error: error.message,
  };
};
const requestUserAuthEmailConfirmationStateClear = () => {
  return {
    type: REQUEST_USER_AUTH_EMAIL_CONFIRMATION_STATE_CLEAR,
  };
};

export const loginUser = (email, password) => (dispatch) => {
  dispatch(requestLogin());
  myFirebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((user) => {
      dispatch(receiveLogin(user));
    })
    .catch((error) => {
      //TODO something with the error if you want!
      dispatch(loginError(error));
    });
};

export const logoutUser = () => (dispatch) => {
  dispatch(requestLogout());
  myFirebase
    .auth()
    .signOut()
    .then(() => {
      dispatch(receiveLogout());
    })
    .catch((error) => {
      //TODO something with the error if you want!
      dispatch(logoutError());
    });
};

export const verifyAuth = () => (dispatch) => {
  dispatch(verifyRequest());
  myFirebase.auth().onAuthStateChanged((user) => {
    if (user !== null) {
      dispatch(receiveLogin(user));
    }
    dispatch(verifySuccess());
  });
};

// *** SIGN_UP SECTION BY ME

const requestSignUp = () => {
  return {
    type: SIGN_UP_REQUEST,
  };
};

export const signUpUser = (fields) => async (dispatch) => {
  dispatch(requestSignUp());
  const { email, password } = fields;
  // console.log(fields);
  // Use firebase to create a new authenticated user
  myFirebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((userData) => {
      dispatch(receiveSignUp(userData));
      dispatch(createNewUserInFirestore(fields, userData));
      userData.user
        .sendEmailVerification()
        .then(() => {
          console.log('email sent');
        })
        .catch((error) => {
          console.log('some error occured sending email');
          console.log(error);
        });
    })
    .catch((error) => {
      //TODO do something with the error. At the moment just sets a signUpError Boolean. Need to print the message?
      dispatch(signUpError(error));
    });
};

const receiveSignUp = (user) => {
  // console.log(user);
  // console.log(user.user.emailVerified);
  return {
    type: SIGN_UP_SUCCESS,
    user,
  };
};

const signUpError = (error) => {
  console.log(error);
  return {
    type: SIGN_UP_ERROR,
    payload: error.message,
  };
};

const creatingNewUserInFirestore = () => {
  return {
    type: CREATING_NEW_USER_IN_FIRESTORE,
  };
};

const createdNewUserInFirestore = () => {
  return {
    type: CREATED_NEW_USER_IN_FIRESTORE,
  };
};

const errorCreatingNewUserInFirestore = (error) => {
  // IMPROVE THIS ERROR HANDLING!
  return {
    type: CREATING_NEW_USER_IN_FIRESTORE_ERROR,
    payload: error.message,
  };
};

const createNewUserInFirestore = (fields, user) => async (dispatch) => {
  const { email, uid } = user.user;
  const {
    firstName,
    lastName,
    companyName,
    terms,
    privacy,
    marketing,
  } = fields;
  const timestamp = db.app.firebase_.firestore.FieldValue.serverTimestamp();
  let termsAgreed;
  let privacyAgreed;
  let marketingAgreed;

  if (terms.length > 0) {
    termsAgreed = timestamp;
  }
  if (privacy.length > 0) {
    privacyAgreed = timestamp;
  }
  if (marketing.length > 0) {
    marketingAgreed = timestamp;
  } else {
    marketingAgreed = false;
  }

  dispatch(creatingNewUserInFirestore());
  db.collection('users')
    .doc(uid)
    .set(
      {
        uuid: uid,
        firstName: firstName,
        lastName: lastName,
        company: {
          companyName: companyName,
          logoUrl: null,
          address: {
            line1: null,
            line2: null,
            town: null,
            postCode: null,
          },
        },
        email: email,
        landline: null,
        mobile: null,
        termsAgreed,
        privacyAgreed,
        marketingAgreed,
      },
      { merge: true }
    )
    .then(function () {
      //Firebase doesn't return any data when successfully created document by .set()
      dispatch(createdNewUserInFirestore());
      // console.log('Document created for user!');
    })
    .catch(function (error) {
      dispatch(errorCreatingNewUserInFirestore(error));
      console.error('Error creating user: ', error);
    });
};

export const sendPasswordResetEmail = (email) => async (dispatch) => {
  console.log(email);
  dispatch(requestSendPasswordResetEmail());
  myFirebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => {
      dispatch(requestSendPasswordResetEmailSuccess());
    })
    .catch((error) => {
      //TODO handle error!
      console.log(error);
      dispatch(requestSendPasswordResetEmailError(error));
    });
};

export const sendPasswordResetEmailStateClear = () => async (dispatch) => {
  dispatch(requestSendPasswordResetEmailStateClear());
};

export const changeUserAuthEmail = (newEmail) => async (dispatch) => {
  dispatch(requestChangeSignInEmail());
  const user = myFirebase.auth().currentUser;
  console.log(user.uid);

  user
    .updateEmail(newEmail)
    .then(() => {
      dispatch(requestChangeSignInEmailSuccess());
      console.log('success!');
      db.collection('users')
        .doc(user.uid)
        .set({ email: newEmail }, { merge: true })
        .then(() => {
          dispatch(requestChangeSignInEmailSuccess());
        });
    })
    .catch((error) => {
      dispatch(requestChangeSignInEmailError(error));
      console.log(error);
      console.log(error.message);
    });
};

export const changeUserAuthEmailStateClear = () => async (dispatch) => {
  dispatch(requestChangeSignInEmailStateClear());
};

export const userAuthEmailResendConfirmation = () => async (dispatch) => {
  dispatch(requestUserAuthEmailConfirmation());

  const user = myFirebase.auth().currentUser;
  // console.log(user);
  user
    .sendEmailVerification()
    .then((res) => {
      // console.log(res);
      dispatch(requestUserAuthEmailConfirmationSuccess());
    })
    .catch((error) => {
      dispatch(requestUserAuthEmailConfirmationError(error));
    });
};

export const userAuthEmailResendConfirmationStateClear = () => async (
  dispatch
) => {
  dispatch(requestUserAuthEmailConfirmationStateClear());
};
