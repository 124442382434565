import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import { Redirect } from 'react-router-dom';

import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Message,
  Segment,
} from "semantic-ui-react";

import * as ROUTES from "../constants/routes";
import { sendPasswordResetEmail } from "../actions";

class SignIn extends Component {
  state = { email: "" };

  handleEmailChange = ({ target }) => {
    this.setState({ email: target.value });
  };

  handlePasswordChange = ({ target }) => {
    this.setState({ password: target.value });
  };

  handleSubmit = () => {
    const { email } = this.state;
    this.props.sendPasswordResetEmail(email);
  };

  renderButtons = () => {
    if (this.props.authSendingPasswordResetEmail) {
      return (
        <Button loading color="teal" fluid size="large">
          Processing
        </Button>
      );
    } else if (this.props.authSendingPasswordResetEmailSuccess) {
      return (
        <Button color="teal" fluid size="large" onClick={this.handleSubmit}>
          Send Again?
        </Button>
      );
    } else {
      return (
        <Button color="teal" fluid size="large" onClick={this.handleSubmit}>
          Reset
        </Button>
      );
    }
  };

  renderResetStatus = () => {
    if (this.props.authSendingPasswordResetEmailError) {
      return (
        <Message negative>
          <Message.Header>Error!</Message.Header>
          <p>{this.props.authSendingPasswordResetEmailError}</p>
        </Message>
      );
    } else if (this.props.authSendingPasswordResetEmailSuccess) {
      // console.log('success');
      return (
        <Message>
          <Message.Header>Success!</Message.Header>
          <p>
            An email has been sent to your email address with a link to reset
            your password. <a href={ROUTES.SIGN_IN}>Sign In</a>
          </p>
        </Message>
      );
    }
  };

  render() {
    return (
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="teal" textAlign="center">
            <Icon color="teal" name="question circle" size="huge" />
            Password Reset
          </Header>
          <Header
            as="h4"
            content="Enter the email address used to create your account"
            style={{
              fontWeight: "normal",
              marginTop: 0,
            }}
          />
          <Form size="large">
            <Segment stacked>
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="E-mail address"
                onChange={this.handleEmailChange}
              />
              {this.renderButtons()}
            </Segment>
          </Form>
          {this.renderResetStatus()}
          <Message>
            Remembered it? <Link to={ROUTES.SIGN_IN}>Sign In</Link>
          </Message>
        </Grid.Column>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    authSendingPasswordResetEmail: state.auth.authSendingPasswordResetEmail,
    authSendingPasswordResetEmailSuccess:
      state.auth.authSendingPasswordResetEmailSuccess,
    authSendingPasswordResetEmailError:
      state.auth.authSendingPasswordResetEmailError,
    authSendingPasswordResetEmailErrorMessage:
      state.auth.authSendingPasswordResetEmailErrorMessage,
  };
}

export default connect(mapStateToProps, { sendPasswordResetEmail })(SignIn);
