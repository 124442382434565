import React from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Container,
  Dimmer,
  Form,
  Header,
  Icon,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react';

import { Formik, getIn } from 'formik';
import * as Yup from 'yup';

import {
  getUserInfoFromFirestore,
  updateFirestoreUserInfo,
  updatingUserRecordInFirestoreStateClear,
} from '../../actions';
import history from '../../history';
import * as ROUTES from '../../constants/routes';

class ProfileEdit extends React.Component {
  state = { initialised: false };

  initialValues = {
    firstName: '',
    lastName: '',
    company: {
      companyName: '',
      companyWebsite: '',
      address: { line1: '', line2: '', town: '', postCode: '' },
    },
    email: '',
    landline: '',
    mobile: '',
    // logoUrl: '',
  };

  yupValidationSchema = Yup.object().shape({
    firstName: Yup.string('Should be a string')
      .required('First Name is required')
      .min(2, 'First name should be longer than 2 characters'),
    lastName: Yup.string('Should be a string')
      .required('Last Name is required')
      .min(2, 'Last name should be longer than 2 characters'),
    // email: Yup.required('Email is required').string().email('Email must be a valid email address').required().min(),
    landline: Yup.string('Should be a string')
      .min(9, 'Landline must be at least 9 characters')
      .max(14, 'Landline cannot be more than 14 characters'),
    mobile: Yup.string('Should be a string')
      .min(9, 'Mobile must be at least 9 characters')
      .max(14, 'Mobile cannot be more than 14 characters'),

    company: Yup.object().shape({
      companyName: Yup.string('Should be a string')
        .required('Company Name / Trading Name is required')
        .min(4, 'Company name should be at least 4 characters long'),
      companyWebsite: Yup.string('Should be a string'),
      // .url(
      //   'Company website should be a valid url.'
      // )
      address: Yup.object().shape({
        line1: Yup.string('Should be a string').required(
          'First line of the address is required'
        ),
        line2: Yup.string('Should be a string'),
        town: Yup.string('Should be a string')
          .required('Town / City is required')
          .min(4, 'Town / City must be more than 4 characters'),
        postCode: Yup.string('Should be a string')
          .required('Post Code is required')
          .min(4, 'Postcode cannot be smaler than 4 characters')
          .max(7, 'Postcode cannot be larger than 7 characters'),
      }),
    }),
  });

  loaded = false;

  async componentDidMount() {
    await this.props.getUserInfoFromFirestore();
    this.props.updatingUserRecordInFirestoreStateClear();
    setTimeout(() => {
      this.setState({ initialised: true });
    }, 1000);
  }

  syncInitialValues = (setFieldValue, setValues, values) => {
    if (this.props.userFirstName) {
      if (this.loaded === false) {
        this.loaded = true;
        const firstName = this.props.userFirstName;
        const lastName = this.props.userLastName;
        // const email = this.props.userEmail;
        const landline = this.props.userLandline;
        const mobile = this.props.userMobile;
        const companyName = this.props.userCompanyRecord.companyName;
        const companyWebsite = this.props.userCompanyRecord.companyWebsite;
        const companyAddressL1 = this.props.userCompanyRecord.address.line1;
        const companyAddressL2 = this.props.userCompanyRecord.address.line2;
        const companyAddressTown = this.props.userCompanyRecord.address.town;
        const companyAddressPostCode = this.props.userCompanyRecord.address
          .postCode;

        //const logoUrl = this.props.userCompanyRecord.logo;

        setValues({
          firstName: firstName,
          lastName: lastName,
          // email: email,
          landline: landline,
          mobile: mobile,
          company: {
            companyName: companyName,
            companyWebsite: companyWebsite,
            address: {
              line1: companyAddressL1,
              line2: companyAddressL2,
              town: companyAddressTown,
              postCode: companyAddressPostCode,
            },
          },
        });
      }
    }
  };

  handleRedirect() {
    if (
      this.props.updatingUserRecordInFirestoreSuccess &&
      this.state.initialised
    ) {
      setTimeout(() => {
        history.push(ROUTES.HOME);
      }, 2000);
      return (
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>Profile successfully updated</Message.Header>
            Redirecting you to your homepage . . .
          </Message.Content>
        </Message>
      );
    }
  }

  // TODO: implement YUP validation
  // TODO: implement firestore validation

  render() {
    return (
      <Container text>
        <Dimmer
          active={
            this.props.updatingUserRecordInFirestore ||
            this.props.gettingUserInfoFromFirestore
          }
          inverted
        >
          <Loader size="large" inverted>
            {`${this.props.updatingUserRecordInFirestore ? 'Upl' : 'L'}oading`}
          </Loader>
        </Dimmer>
        <br />
        <Header as="h1">Edit Profile</Header>
        <Container fluid>
          <Segment.Group raised>
            <Segment>
              <Header>My Details</Header>
              {/* {this.generateCompanyInfo()} */}
              <Formik
                initialValues={this.initialValues}
                validationSchema={this.yupValidationSchema}
                // enableReinitialize={true}
                onSubmit={(fields) => {
                  // console.log(fields);
                  // console.log(this.props);
                  this.props.updateFirestoreUserInfo(
                    this.props.userUid,
                    fields
                  );
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setValues,
                }) => {
                  this.syncInitialValues(setFieldValue, setValues, values);
                  // console.log(errors);
                  // console.log(touched);
                  return (
                    <Form>
                      <Form.Input
                        fluid
                        icon="edit outline"
                        iconPosition="left"
                        label="First Name"
                        placeholder="First Name"
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        error={touched.firstName && errors.firstName}
                        required
                      />
                      <Form.Input
                        fluid
                        icon="edit outline"
                        iconPosition="left"
                        label="Last Name"
                        placeholder="Last Name"
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        error={touched.lastName && errors.lastName}
                        required
                      />
                      {/* // removed email for now as we need to update and validate the log in record if this changes . . .*/}
                      {/* <Form.Input
                        fluid
                        icon='edit outline'
                        iconPosition='left'
                        label='Email'
                        placeholder='Email'
                        name='email'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={touched.email && errors.email}
                        required
                      /> */}
                      <Form.Input
                        fluid
                        icon="edit outline"
                        iconPosition="left"
                        label="Landline"
                        placeholder="Landline"
                        name="landline"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.landline}
                        error={touched.landline && errors.landline}
                        required
                      />
                      <Form.Input
                        fluid
                        icon="edit outline"
                        iconPosition="left"
                        label="Mobile"
                        placeholder="Mobile"
                        name="mobile"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mobile}
                        error={touched.mobile && errors.mobile}
                        required
                      />
                      <Segment.Group raised>
                        <Segment>
                          <Header>Company Information</Header>
                          <Form.Input
                            fluid
                            icon="edit outline"
                            iconPosition="left"
                            label="Company Name"
                            placeholder="Company Name"
                            name="company.companyName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.company.companyName}
                            error={
                              getIn(touched, 'company.companyName') &&
                              getIn(errors, 'company.companyName')
                            }
                            required
                          />
                          <Form.Input
                            fluid
                            icon="edit outline"
                            iconPosition="left"
                            label="Company Website"
                            placeholder="Company Website"
                            name="company.companyWebsite"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.company.companyWebsite}
                            error={
                              getIn(touched, 'company.companyWebsite') &&
                              getIn(errors, 'company.companyWebsite')
                            }
                            required
                          />
                          <Form.Input
                            fluid
                            icon="edit outline"
                            iconPosition="left"
                            label="Line 1"
                            placeholder="Line 1"
                            name="company.address.line1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.company.address.line1}
                            error={
                              getIn(touched, 'company.address.line1') &&
                              getIn(errors, 'company.address.line1')
                            }
                            required
                          />
                          <Form.Input
                            fluid
                            icon="edit outline"
                            iconPosition="left"
                            label="Line 2"
                            placeholder="Line 2"
                            name="company.address.line2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.company.address.line2}
                            error={
                              getIn(touched, 'company.address.line2') &&
                              getIn(errors, 'company.address.line2')
                            }
                            required
                          />
                          <Form.Input
                            fluid
                            icon="edit outline"
                            iconPosition="left"
                            label="Town/City"
                            placeholder="Town/City"
                            name="company.address.town"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.company.address.town}
                            error={
                              getIn(touched, 'company.address.town') &&
                              getIn(errors, 'company.address.town')
                            }
                            required
                          />
                          <Form.Input
                            fluid
                            icon="edit outline"
                            iconPosition="left"
                            label="Postcode"
                            placeholder="Postcode"
                            name="company.address.postCode"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.company.address.postCode}
                            error={
                              getIn(touched, 'company.address.postCode') &&
                              getIn(errors, 'company.address.postCode')
                            }
                            required
                          />
                        </Segment>
                      </Segment.Group>
                      <Button
                        color="teal"
                        fluid
                        size="large"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Save Changes
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
              {this.handleRedirect()}
            </Segment>
          </Segment.Group>
        </Container>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    userUid: state.auth.user.uid,
    userFirstName: state.firestoreReducers.userRecord.firstName,
    userLastName: state.firestoreReducers.userRecord.lastName,
    userCompanyRecord: state.firestoreReducers.userRecord.company,
    userEmail: state.firestoreReducers.userRecord.email,
    userLandline: state.firestoreReducers.userRecord.landline,
    userMobile: state.firestoreReducers.userRecord.mobile,
    gettingUserInfoFromFirestore:
      state.firestoreReducers.gettingUserInfoFromFirestore,
    updatingUserRecordInFirestore:
      state.firestoreReducers.updatingUserRecordInFirestore,
    updatingUserRecordInFirestoreSuccess:
      state.firestoreReducers.updatingUserRecordInFirestoreSuccess,
    updatingUserRecordInFirestoreError:
      state.firestoreReducers.updatingUserRecordInFirestoreError,
    updatingUserRecordInFirestoreErrorMessage:
      state.firestoreReducers.updatingUserRecordInFirestoreErrorMessage,
  };
}

export default connect(mapStateToProps, {
  getUserInfoFromFirestore,
  updateFirestoreUserInfo,
  updatingUserRecordInFirestoreStateClear,
})(ProfileEdit);
