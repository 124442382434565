import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Container, Divider, Grid, Header, Statistic } from 'semantic-ui-react';
import {
  getUserInfoFromFirestore,
  adminGetStatistics,
  adminGetDraftProjectsStateClear,
  adminGetPublishedProjectsStateClear,
  adminGetRejectedProjectsStateClear,
  adminGetPendingGoLiveProjectsStateClear,
  adminGetProjectOwnersInfoStateClear,
} from '../../actions';
import history from '../../history';
import * as ROUTES from '../../constants/routes';

import DraftProjects from './DraftProjects';
import PublishedProjects from './PublishedProjects';
import RejectedProjects from './RejectedProjects';
import PendingGoLiveProjects from './PendingGoLiveProjects';

class Admin extends Component {
  componentDidMount() {
    this.props.getUserInfoFromFirestore();
    this.props.adminGetStatistics();
    this.props.adminGetDraftProjectsStateClear();
    this.props.adminGetPublishedProjectsStateClear();
    this.props.adminGetRejectedProjectsStateClear();
    this.props.adminGetPendingGoLiveProjectsStateClear();
    this.props.adminGetProjectOwnersInfoStateClear();
  }

  renderStats() {
    if (this.props.adminGetStatisticsSuccess) {
      const {
        userCount,
        projectCount,
        draftProjects,
        liveProjects,
        completedProjects,
        quotes,
      } = this.props.adminStatistics;
      return (
        <>
          <Grid centered>
            <Grid.Row stretched>
              <Statistic size='small'>
                <Statistic.Value>{userCount}</Statistic.Value>
                <Statistic.Label>Users</Statistic.Label>
              </Statistic>
              <Statistic size='small'>
                <Statistic.Value>{projectCount}</Statistic.Value>
                <Statistic.Label>Projects</Statistic.Label>
              </Statistic>
              <Statistic size='small'>
                <Statistic.Value>{draftProjects}</Statistic.Value>
                <Statistic.Label>Unpublished</Statistic.Label>
              </Statistic>
              <Statistic size='small'>
                <Statistic.Value>{liveProjects}</Statistic.Value>
                <Statistic.Label>Live</Statistic.Label>
              </Statistic>
              <Statistic size='small'>
                <Statistic.Value>{completedProjects}</Statistic.Value>
                <Statistic.Label>Completed</Statistic.Label>
              </Statistic>
              <Statistic size='small'>
                <Statistic.Value>{quotes}</Statistic.Value>
                <Statistic.Label>Quotes</Statistic.Label>
              </Statistic>
            </Grid.Row>
          </Grid>
        </>
      );
    }
  }

  render() {
    if (this.props.user.isAdmin) {
      return (
        <Container>
          <br />
          <Header as='h1'>
            Admin Page
            <Header.Subheader>Welcome to the admin page</Header.Subheader>
          </Header>
          <Divider />
          {this.renderStats()}
          <Divider />
          <Container text>
            <PendingGoLiveProjects />
            <Divider />
            <PublishedProjects />
            <Divider />
            <RejectedProjects />
            <Divider />
            <DraftProjects />
          </Container>
        </Container>
      );
    } else {
      if (this.props.user.firstName) {
        console.log('redirecting');
        history.push(ROUTES.HOME);
      }
      return <div>Admin page</div>;
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.firestoreReducers.userRecord,
    adminStatistics: state.firestoreReducers.adminStatistics,
    adminGetStatisticsRequest:
      state.firestoreReducers.adminGetStatisticsRequest,
    adminGetStatisticsSuccess:
      state.firestoreReducers.adminGetStatisticsSuccess,
    adminGetStatisticsError: state.firestoreReducers.adminGetStatisticsError,
    adminGetStatisticsErrorMessage:
      state.firestoreReducers.adminGetStatisticsErrorMessage,
  };
}

export default connect(mapStateToProps, {
  getUserInfoFromFirestore,
  adminGetStatistics,
  adminGetDraftProjectsStateClear,
  adminGetPublishedProjectsStateClear,
  adminGetRejectedProjectsStateClear,
  adminGetPendingGoLiveProjectsStateClear,
  adminGetProjectOwnersInfoStateClear,
})(Admin);
