import React from 'react';
import { Link } from 'react-router-dom';

import CookieConsent from 'react-cookie-consent';
import * as ROUTES from '../constants/routes';

const CookieConsentPopup = () => {
  return (
    <CookieConsent
      cookieName='cookieConsent'
      expires={300}
      disableStyles={true}
      containerClasses='ui segment placeholder'
      contentClasses='ui header'
      overlayClasses='ui page modals dimmer transition visible active'
      buttonClasses='ui large teal right floated button'
      overlay
    >
      This website uses cookies to enhance the user experience. Read our
      <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link> for more
      information.
    </CookieConsent>
  );
};

export default CookieConsentPopup;
