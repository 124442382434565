import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Button,
  Container,
  Dimmer,
  Form,
  Header,
  Input,
  Loader,
  Message,
  Segment,
  TextArea,
} from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';

import * as ROUTES from '../constants/routes';

import {
  storeUnregisteredContactUsRequestInFirebase,
  storeUnregisteredContactUsRequestInFirebaseStateClear,
} from '../actions';

const initialFormikValues = {
  firstName: '',
  lastName: '',
  title: '',
  company: '',
  email: '',
  phone: '',
  message: '',
  method: '',
};

const yupValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  title: Yup.string(),
  company: Yup.string().required('Company name is required'),
  email: Yup.string()
    .required('We need an email address to reply to you.')
    .email('We require a valid email address to contact you.'),
  phone: Yup.string().min(
    11,
    'A phone number should be at least 11 characters.'
  ),
  message: Yup.string().required('You need to type a message to send to us.'),
  method: Yup.string(),
});

class ContactUs extends React.Component {
  state = { initialised: false };

  componentDidMount() {
    this.props.storeUnregisteredContactUsRequestInFirebaseStateClear();
    this.timeoutID = this.setInitialised();
  }

  // put this in to clear state instantly to prevent timeouts setting state after component unmounts
  componentWillUnmout() {
    clearTimeout(this.timeoutID);
    this.setState = (state, callback) => {
      return;
    };
  }

  setInitialised = () => {
    setTimeout(() => {
      this.setState({ initialised: true });
    }, 1000);
  };

  renderButton = () => {
    if (this.props.uploadingContactUsForm) {
      return (
        <Form.Button loading control={Button} color="teal" fluid size="large">
          Submit
        </Form.Button>
      );
    } else {
      return (
        <Form.Button
          control={Button}
          type="submit"
          color="teal"
          fluid
          size="large"
        >
          Submit
        </Form.Button>
      );
    }
  };

  renderUnregisteredUserForm = () => {
    return (
      <>
        <Dimmer
          active={this.props.uploadingContactUsForm && this.state.initialised}
          inverted
        >
          <Loader size="large" inverted>
            Sending'
          </Loader>
        </Dimmer>

        <Formik
          initialValues={initialFormikValues}
          validationSchema={yupValidationSchema}
          onSubmit={(fields) => {
            // console.log(fields);
            this.props.storeUnregisteredContactUsRequestInFirebase(fields);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group widths="equal">
                <Form.Field
                  control={Input}
                  label="First Name"
                  type="firstName"
                  name="firstName"
                  placeholder="First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  error={touched.firstName && errors.firstName}
                  required
                />
                <Form.Field
                  control={Input}
                  label="Last Name"
                  type="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  error={touched.lastName && errors.lastName}
                  required
                />
              </Form.Group>
              <Form.Field
                control={Input}
                label="Job Title"
                type="title"
                name="title"
                placeholder="Job Title"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                error={touched.title && errors.title}
              />
              <Form.Field
                control={Input}
                label="Company Name"
                type="company"
                name="company"
                placeholder="Company Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.company}
                error={touched.company && errors.company}
                required
              />
              <Form.Field
                control={Input}
                label="Business Email Address"
                type="email"
                name="email"
                placeholder="name@business.domain"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={touched.email && errors.email}
                required
              />
              <Form.Field
                control={Input}
                label="Phone Number"
                type="phone"
                name="phone"
                placeholder="Phone number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                error={touched.phone && errors.phone}
              />
              <Form.Field
                control={TextArea}
                label="Message"
                type="message"
                name="message"
                placeholder="What would you like to say to us?"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                error={touched.message && errors.message}
                required
              />
              <Form.Select
                label="How would you like us to contact you?"
                placeholder="select"
                name="method"
                onChange={(_, { value }) => {
                  setFieldTouched('method');
                  setFieldValue('method', value);
                }}
                onBlur={(e) => {
                  setFieldTouched('method');
                  handleBlur(e);
                }}
                value={values.method}
                error={touched.method && errors.method}
                options={[
                  {
                    key: '0',
                    text: '',
                    value: '',
                  },
                  {
                    key: '1',
                    text: 'Phone',
                    value: 'phone',
                  },
                  {
                    key: '2',
                    text: 'Email',
                    value: 'email',
                  },
                  {
                    key: '3',
                    text: 'Either',
                    value: 'either',
                  },
                ]}
              />
              {this.renderButton()}
            </Form>
          )}
        </Formik>
      </>
    );
  };

  renderFormArea = () => {
    // console.log(this.props);
    if (!this.state.initialised) {
      return (
        <Segment basic loading>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Segment>
      );
    } else if (
      this.state.initialised &&
      !this.props.uploadingContactUsFormSuccess
    ) {
      return this.renderUnregisteredUserForm();
    } else if (this.props.uploadingContactUsFormSuccess) {
      return (
        <Segment basic style={{ margin: '5em' }}>
          <Message positive>
            <Message.Header>Message Sent!</Message.Header>
            <p>
              Your message was successfully sent. Someone will get back to you
              as soon as possible.
            </p>
            <p>
              Click the button below if you would like to send another message.
            </p>
            <Button
              color="teal"
              onClick={() => {
                // console.log('clicked!');
                this.props.storeUnregisteredContactUsRequestInFirebaseStateClear();
              }}
            >
              Send Another
            </Button>
          </Message>
        </Segment>
      );
    }
  };

  render() {
    return (
      <Container text>
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Contact Us</title>
            <meta
              name="description"
              content="Contact us to find out more about how we can help your business, or if you have anything else you wish to tell us."
            />
          </Helmet>
          <Header
            as="h1"
            content="Contact Us"
            style={{
              fontSize: '4em',
              fontWeight: 'normal',
              marginBottom: 0,
              marginTop: '0.8em',
            }}
          />
          <Header
            as="h2"
            content="Need to discuss something with us? Get in touch!"
            style={{
              fontSize: '1.7em',
              fontWeight: 'normal',
              marginTop: 0,
              marginBottom: '2em',
            }}
          />
        </>
        <p style={{ fontSize: '1em' }}>
          We're always happy to help. Whilst we've tried to make the site as
          easy to use as possible we know that creating the perfect project
          proposal isn't always straightforward.
        </p>
        <p style={{ fontSize: '1em' }}>
          We have a list of{' '}
          <Link to={ROUTES.FAQ}>Frequently Asked Questions</Link> which cover
          some of the most common situations, but if you have any other
          questions please don't hesitate to get in touch.
        </p>
        <Segment style={{ marginTop: '4em', marginBottom: '4em ' }}>
          <Header as="h3">Our details</Header>
          <Header.Subheader>Phone us:</Header.Subheader>
          <p>
            <a href="tel:0151 622 0260">0151 622 0260</a>
          </p>
          <Header.Subheader>Email us:</Header.Subheader>
          <p>
            <a href="mailto:support@machinebuilders.co.uk?subject=Contact%20Machine%20Builders%20Support">
              support@machinebuilders.co.uk
            </a>
          </p>
        </Segment>
        <Segment style={{ marginTop: '4em', marginBottom: '4em ' }}>
          <Header as="h3">Send us a message</Header>
          {this.renderFormArea()}
        </Segment>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uploadingContactUsForm: state.firestoreReducers.uploadingContactUsForm,
    uploadingContactUsFormSuccess:
      state.firestoreReducers.uploadingContactUsFormSuccess,
    uploadingContactUsFormError:
      state.firestoreReducers.uploadingContactUsFormError,
    uploadingContactUsFormErrorMessage:
      state.firestoreReducers.uploadingContactUsFormErrorMessage,
  };
};

export default connect(mapStateToProps, {
  storeUnregisteredContactUsRequestInFirebase,
  storeUnregisteredContactUsRequestInFirebaseStateClear,
})(ContactUs);
