import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dimmer, Header, Image, Placeholder } from 'semantic-ui-react';

import * as ROUTES from '../../constants/routes';

export default class ProfileImageDimmer extends Component {
  state = { imageLoadedStatus: 'loading' };

  // componentDidMount() {
  //   this.setState({ logoUrl: this.props.logoUrl });
  // }
  handleShow = () => this.setState({ active: true });
  handleHide = () => this.setState({ active: false });

  handleImageLoaded = () => {
    this.setState({ imageLoadedStatus: 'loaded' });
  };

  handleImageLoadedError = () => {
    this.setState({ imageLoadedStatus: 'error' });
  };

  generateDimmmerContent() {
    const logoUrl = this.props.logoUrl;
    if (logoUrl) {
      return (
        <>
          <Header as='h2' inverted>
            Company Logo
          </Header>

          <Button color='teal' as={Link} to={`${ROUTES.PROFILE_IMAGE_UPLOAD}`}>
            Edit Logo
          </Button>
        </>
      );
    } else {
      return (
        <div>
          <Header as='h2' inverted>
            Company Logo
          </Header>

          <Button color='teal' as={Link} to={`${ROUTES.PROFILE_IMAGE_UPLOAD}`}>
            Add Logo
          </Button>
        </div>
      );
    }
  }

  generateImageDimmer(active, content) {
    if (this.props.logoUrl) {
      return (
        <>
          {this.state.imageLoadedStatus === 'loading' && (
            <Placeholder>
              <Placeholder.Image square />
            </Placeholder>
          )}

          <Dimmer.Dimmable
            as={Image}
            className={
              this.state.imageLoadedStatus === 'loading' ? 'hidden' : ''
            }
            dimmed={active}
            dimmer={{ active, content }}
            onMouseEnter={this.handleShow}
            onMouseLeave={this.handleHide}
            size='medium'
            src={this.props.logoUrl}
            onLoad={this.handleImageLoaded}
            onError={this.handleImageErrored}
          />
        </>
      );
    } else {
      return (
        <Dimmer.Dimmable
          as={Image}
          dimmed={active}
          dimmer={{ active, content }}
          onMouseEnter={this.handleShow}
          onMouseLeave={this.handleHide}
          size='medium'
          src='https://react.semantic-ui.com/images/wireframe/image.png'
        />
      );
    }
  }

  render() {
    const { active } = this.state;
    const content = this.generateDimmmerContent();
    return <>{this.generateImageDimmer(active, content)}</>;
  }
}
