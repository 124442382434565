import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Dropdown, Responsive, Menu } from 'semantic-ui-react';

import * as ROUTES from '../../constants/routes';
import history from '../../history';
import { logoutUser } from '../../actions';

class Navigation extends React.Component {
  state = { active: '' };

  componentDidMount() {
    const currentPath = history.location.pathname;
    let componentName = '/';
    if (currentPath !== '/') {
      componentName = currentPath.split('/');
      if (componentName.length > 1) {
        componentName = componentName[1];
      } else {
        componentName = componentName[0];
      }
    }
    this.setState({ active: componentName });
  }
  handleClick = (e, { name }) => {
    // console.log(name);
    // console.log(this.state.active);
    this.setState({ active: name });
  };

  handleSignOut = (e, { name }) => {
    this.setState({ active: name });
    this.props.logoutUser();
  };

  authStateMenuItem(dropdown) {
    if (!this.props.isAuthenticated) {
      if (!dropdown) {
        return (
          <Menu.Item
            as={Link}
            to={ROUTES.SIGN_IN}
            active={this.state.active === 'SIGNIN'}
            content="Sign In"
            name="SIGNIN"
            onClick={this.handleClick}
            style={{
              WebkitTextFillColor: 'white',
            }}
          />
        );
      } else {
        return (
          <Dropdown.Item
            as={Link}
            to={ROUTES.SIGN_IN}
            active={this.state.active === 'SIGNIN'}
            content="Sign In"
            name="SIGNIN"
            onClick={this.handleClick}
          />
        );
      }
    } else {
      if (!dropdown) {
        return (
          <Dropdown item text="Profile">
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={ROUTES.SIGN_IN}
                active={this.state.active === 'SIGNOUT'}
                content="Sign Out"
                name="SIGNOUT"
                onClick={this.handleSignOut}
              />
              <Dropdown.Item
                as={Link}
                to={ROUTES.PROFILE_MANAGE}
                active={this.state.active === 'PROFILE_MANAGE'}
                content="Manage"
                name="PROFILE_MANAGE"
                onClick={this.handleClick}
              />
              {/* <Menu.Item
            as={Link}
            to={ROUTES.SIGN_IN}
            active={this.state.active === 'SIGNOUT'}
            content="Sign Out"
            name="SIGNOUT"
            onClick={this.handleSignOut}
            style={{
              WebkitTextFillColor: 'white',
            }}
          /> */}
            </Dropdown.Menu>
          </Dropdown>
        );
      } else {
        return (
          <>
            <Dropdown.Item
              as={Link}
              to={ROUTES.SIGN_IN}
              active={this.state.active === 'SIGNOUT'}
              content="Sign Out"
              name="SIGNOUT"
              onClick={this.handleSignOut}
            />
            <Dropdown.Item
              as={Link}
              to={ROUTES.PROFILE_MANAGE}
              active={this.state.active === 'PROFILE_MANAGE'}
              content="Manage"
              name="PROFILE_MANAGE"
              onClick={this.handleClick}
            />
          </>
        );
      }
    }
  }

  menuItems() {
    return (
      <>
        <Menu.Item
          as={Link}
          to={ROUTES.HOME}
          active={this.state.active === 'home'}
          content="Home"
          name="home"
          onClick={this.handleClick}
          style={{
            WebkitTextFillColor: 'white',
          }}
        />
        <Menu.Item
          as={Link}
          to={ROUTES.ABOUT_US}
          active={this.state.active === 'about'}
          content="About"
          name="about"
          onClick={this.handleClick}
          style={{
            WebkitTextFillColor: 'white',
          }}
        />
        <Menu.Item
          as={Link}
          to={ROUTES.FAQ}
          active={this.state.active === 'FAQ'}
          content="FAQ"
          name="FAQ"
          onClick={this.handleClick}
          style={{
            WebkitTextFillColor: 'white',
          }}
        />
        <Menu.Item
          as={Link}
          to={ROUTES.CONTACT_US}
          active={this.state.active === 'contact'}
          content="Contact"
          name="contact"
          onClick={this.handleClick}
          style={{
            WebkitTextFillColor: 'white',
          }}
        />
        <Menu.Item
          as={Link}
          to={ROUTES.PROJECTS}
          active={this.state.active === 'projects'}
          content="Projects"
          name="projects"
          onClick={this.handleClick}
          style={{
            WebkitTextFillColor: 'white',
          }}
        />
        {this.authStateMenuItem(false)}
      </>
    );
  }

  dropDownItems() {
    return (
      <>
        <Dropdown.Item
          as={Link}
          to={ROUTES.HOME}
          active={this.state.active === 'home'}
          content="Home"
          name="home"
          onClick={this.handleClick}
        />
        <Dropdown.Item
          as={Link}
          to={ROUTES.ABOUT_US}
          active={this.state.active === 'about'}
          content="About"
          name="about"
          onClick={this.handleClick}
        />
        <Dropdown.Item
          as={Link}
          to={ROUTES.FAQ}
          active={this.state.active === 'FAQ'}
          content="FAQ"
          name="FAQ"
          onClick={this.handleClick}
        />
        <Dropdown.Item
          as={Link}
          to={ROUTES.CONTACT_US}
          active={this.state.active === 'contact'}
          content="Contact"
          name="contact"
          onClick={this.handleClick}
        />
        <Dropdown.Item
          as={Link}
          to={ROUTES.PROJECTS}
          active={this.state.active === 'projects'}
          content="Projects"
          name="projects"
          onClick={this.handleClick}
        />
        {this.authStateMenuItem(true)}
      </>
    );
  }

  render() {
    const { active } = this.state;
    const getCurrentPath = history.location.pathname;
    let headerStyle = { padding: '1em 0em', background: '#00b5ad' };

    if (getCurrentPath === '/') {
      headerStyle = {
        background: 'rgba(255,255,255,0)',
        borderWidth: '0px',
        padding: '1em 0em',
      };
    }
    return (
      <Menu secondary inverted pointing style={headerStyle} size="huge">
        <Menu.Item
          as={Link}
          to={ROUTES.LANDING}
          active={active === 'notused'}
          content="MachineBuilders.co.uk"
          name="landing"
          onClick={this.handleClick}
          style={{
            WebkitTextFillColor: 'white',
          }}
        />

        <Responsive
          as={Menu.Menu}
          position="right"
          maxWidth={Responsive.onlyTablet.maxWidth}
        >
          <Dropdown item text="Menu">
            <Dropdown.Menu>{this.dropDownItems()}</Dropdown.Menu>
          </Dropdown>
        </Responsive>

        <Responsive
          as={Menu.Menu}
          position="right"
          minWidth={Responsive.onlyTablet.maxWidth}
        >
          {this.menuItems()}
        </Responsive>
      </Menu>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
  };
}
export default connect(mapStateToProps, {
  logoutUser,
})(Navigation);
