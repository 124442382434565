import React from 'react';

import {
  Button,
  Container,
  Header,
  Input,
  List,
  Segment,
} from 'semantic-ui-react';
import _ from 'lodash';

class QuestionList extends React.Component {
  state = { value: '', questions: [] };

  handleAddQuestion = () => {
    // Check if a file has been uploaded. If it has, update the state with the list of all the questions.
    // Once the state has been updated call the callback function to update state in the form.
    if (this.state.value) {
      this.setState(
        { value: '', questions: [...this.state.questions, this.state.value] },
        () => {
          // Not sure why we set the state to the file list on the file uploader,
          // Keeping this in here to review later
          // TODO
          // this.props.setQuestionsForUpload(this.state.questions);
          this.props.setFieldValue('questions', this.state.questions);
        }
      );
    }
  };

  handleInputChange = (e) => {
    this.setState({ value: e.target.value });
  };

  renderQuestions() {
    if (this.state.questions.length === 0) {
      return <></>;
    } else {
      return (
        <>
          <Container textAlign='left'>
            <List celled size='large'>
              {this.renderQuestionList()}
            </List>
          </Container>
        </>
      );
    }
  }

  removeFromList(index) {
    // Create an updated list of questions with the question at the index removed

    const updatedListOfQuestions = this.state.questions
      .slice(0, index)
      .concat(this.state.questions.slice(index + 1));
    //Update state with new question list
    this.setState({
      questions: updatedListOfQuestions,
    });

    // Inform Formik of the new list of questions
    this.props.setFieldValue('questions', this.state.questions);
  }

  renderQuestionList() {
    // console.log(this.state.questions);
    return this.state.questions.map((question, index) => {
      return (
        // <List.Item>
        //   {question} Count = {index + 1}
        // </List.Item>
        <List.Item key={'question' + (index + 1)}>
          <Button
            floated='right'
            color='teal'
            size='small'
            onClick={(e) => {
              this.removeFromList(index);
            }}
          >
            Remove
          </Button>
          <List.Content>
            <List.Header>Question {index + 1}</List.Header>
            {question}
          </List.Content>
        </List.Item>
      );
    });
  }

  syncValuesAndState() {
    // If the user goes back to the page containing the question list, the state of the component will have been reset
    // this function reconciles the state with the form values.
    if (_.isEqual(this.state.questions, this.props.values.questions)) {
    } else if (
      this.state.questions.length === 0 &&
      this.props.values.questions.length > 1
    ) {
      this.setState({ questions: this.props.values.questions });
    }
  }

  render = () => {
    this.syncValuesAndState();
    return (
      <Segment>
        <Header as='h4' icon>
          List of Questions you would like quoters to answer
        </Header>
        {this.renderQuestions()}
        <Input
          fluid
          // action={{
          //   color: 'teal',
          //   labelPosition: 'right',
          //   icon: 'add',
          //   content: 'Add'
          // }}
          id='NewQuestion'
          action={
            <Button color='teal' icon='add' onClick={this.handleAddQuestion} />
          }
          placeholder='Enter a question you would like answered'
          value={this.state.value}
          onChange={this.handleInputChange}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault(e);
              this.handleAddQuestion(e);
            }
          }}
        />
      </Segment>
    );
  };
}

export default QuestionList;
