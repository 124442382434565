import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Button, Header, Image, List, Popup, Modal } from 'semantic-ui-react';

import * as ROUTES from '../../constants/routes';

import {
  getUserInfoFromFirestore,
  updateFirestoreUserAsInitialised,
} from '../../actions';

const confirmProfileCompleted = (user) => {
  try {
    if (user.landline && user.company.address.line1) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

const confirmLogoUploaded = (user) => {
  try {
    if (user.company.logoUrl) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

class WelcomeModal extends React.Component {
  state = {
    modalOpen: false,
    modalDismissed: false,
    initialised: false,
    uploadingChanges: false,
  };

  componentDidMount() {
    this.props.getUserInfoFromFirestore();
  }

  checkIfFirstLogin() {
    if (!this.state.initialised) {
      if (
        this.props.userRecord.firstName &&
        !this.props.userInitialised &&
        !this.props.uploadingChanges &&
        this.state.modalOpen === false
      ) {
        this.setState({ modalOpen: true, initialised: true });
      }
    }
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  closeModalAfterUpload() {
    if (
      this.state.uploadingChanges &&
      this.props.updatingUserRecordInFirestoreSuccess
    ) {
      this.props.getUserInfoFromFirestore();
    }

    if (
      this.props.userInitialised &&
      this.props.updatingUserRecordInFirestoreSuccess &&
      this.state.uploadingChanges &&
      this.state.modalOpen
    ) {
      this.setState({ modalOpen: false });
    }

    if (this.props.userInitialised) {
      this.setState({ modalOpen: false });
    }
  }

  renderTick(category) {
    if (!this.state.initialised) {
      return <List.Icon name="question circle outline" color="yellow" />;
    }

    switch (category) {
      case 'profile':
        if (confirmProfileCompleted(this.props.userRecord)) {
          return <List.Icon name="check" color="green" />;
        } else {
          return <List.Icon name="warning circle" color="red" />;
        }
      case 'logo':
        if (confirmLogoUploaded(this.props.userRecord)) {
          return <List.Icon name="check" color="green" />;
        } else {
          return <List.Icon name="warning circle" color="red" />;
        }
      case 'email':
        if (this.props.emailVerified) {
          return <List.Icon name="check" color="green" />;
        } else {
          return <List.Icon name="warning circle" color="red" />;
        }
      default:
        return <List.Icon name="warning circle" color="red" />;
    }
  }

  renderCompleteButton() {
    const user = this.props.userRecord;

    if (this.props.updatingUserRecordInFirestore) {
      return (
        <Button
          content={`Complete`}
          labelPosition="right"
          icon="checkmark"
          loading
          onClick={() => this.props.updateFirestoreUserAsInitialised()}
          positive
        />
      );
    } else if (
      confirmProfileCompleted(user) &&
      confirmLogoUploaded(user) &&
      this.props.emailVerified
    ) {
      return (
        <Button
          content={`Complete`}
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            this.props.updateFirestoreUserAsInitialised();
            this.setState({ uploadingChanges: true });
          }}
          positive
        />
      );
    }

    return (
      <Popup
        content="Complete all steps to dismiss"
        trigger={<Button content="Complete" />}
      />
    );
  }

  render() {
    if (!this.state.initialised) {
      this.checkIfFirstLogin();
    }
    if (this.state.modalOpen) {
      this.closeModalAfterUpload();
    }

    return (
      <Modal open={this.state.modalOpen}>
        <Modal.Header>Welcome to Machine Builders!</Modal.Header>
        <Modal.Content image>
          <Image
            size="medium"
            src="https://firebasestorage.googleapis.com/v0/b/machine-builders.appspot.com/o/staticFiles%2F512.png?alt=media&token=87c463a0-133e-46da-a07c-9fea7d0b7561"
            wrapped
          />
          <Modal.Description>
            <Header>Thank you for registering</Header>
            <p>Welcome to the machine builders platform.</p>
            <p>
              Here are the important first tasks you must complete in order to
              start finding talent for your projects:
            </p>
            <List>
              <List.Item>
                {this.renderTick('profile')}
                <List.Content>
                  <List.Header as={Link} to={ROUTES.PROFILE_EDIT}>
                    Profile Information
                  </List.Header>
                  <List.Description>
                    Complete your&nbsp;
                    <Link to={ROUTES.PROFILE_EDIT}>profile here.</Link>
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                {this.renderTick('logo')}
                <List.Content>
                  <List.Header as={Link} to={ROUTES.PROFILE_IMAGE_UPLOAD}>
                    Company Logo
                  </List.Header>
                  <List.Description>
                    Upload your company&nbsp;
                    <Link to={ROUTES.PROFILE_IMAGE_UPLOAD}>logo here.</Link>
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                {this.renderTick('email')}
                <List.Content>
                  <List.Header as={Link} to={ROUTES.PROFILE_MANAGE}>
                    Confirm Email Address
                  </List.Header>
                  <List.Description>
                    You must confirm your email address. Manage your&nbsp;
                    <Link to={ROUTES.PROFILE_MANAGE}>email here.</Link>
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => this.closeModal()}>
            Later
          </Button>
          {this.renderCompleteButton()}
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    userRecord: state.firestoreReducers.userRecord,
    userFirstName: state.firestoreReducers.userRecord.firstName,
    userLastName: state.firestoreReducers.userRecord.lastName,
    emailVerified: state.auth.user.emailVerified,
    userInitialised: state.firestoreReducers.userRecord.userInitialised,
    updatingUserRecordInFirestore:
      state.firestoreReducers.updatingUserRecordInFirestore,
    updatingUserRecordInFirestoreSuccess:
      state.firestoreReducers.updatingUserRecordInFirestoreSuccess,
    updatingUserRecordInFirestoreError:
      state.firestoreReducers.updatingUserRecordInFirestoreError,
    updatingUserRecordInFirestoreErrorMessage:
      state.firestoreReducers.updatingUserRecordInFirestoreErrorMessage,
  };
}

export default connect(mapStateToProps, {
  getUserInfoFromFirestore,
  updateFirestoreUserAsInitialised,
})(WelcomeModal);
