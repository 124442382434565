import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Divider, Header, Segment } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

import * as ROUTES from '../../constants/routes';

class Blog extends React.Component {
  render() {
    return (
      <Container text>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Blog</title>
          <meta
            name="description"
            content="Find up to date news and articles covering all aspects of machine automation and advanced industrial systems. Machine builders is a site dedicated to help find the best machine builders for your applications."
          />
        </Helmet>
        <Header
          as="h1"
          content="Blog"
          style={{
            fontSize: '4em',
            fontWeight: 'normal',
            marginBottom: 0,
            marginTop: '0.8em',
          }}
        />
        <Header
          as="h2"
          content="Guides, Case Studies, and Market Trends"
          style={{
            fontSize: '1.7em',
            fontWeight: 'normal',
            marginTop: 0,
          }}
        />
        <Divider />
        <Header as="h2">Latest</Header>
        <Segment>
          <Header as="h3">
            <Link to={ROUTES.BLOG_POST_200801}>
              What content should you include when posting a new project?
            </Link>
            <Header.Subheader>
              By machinebuilders.co.uk on 01 August 2020
            </Header.Subheader>
          </Header>
          <p>
            You’ve come to the best place to find quotes for your projects, but
            a quotation will only be as good as the information it is based on.
            We’ve done our best to create a simple yet comprehensive project
            wizard to collect all the information that may be required to put
            together an effective quotation, and this guide aims to expand on
            ...
          </p>
        </Segment>
      </Container>
    );
  }
}

export default Blog;
