import React from "react";
import { Link } from "react-router-dom";

import { Button, Container, Header, Icon } from "semantic-ui-react";

import * as ROUTES from "../constants/routes";
import history from "../history";

class BadRoute extends React.Component {
  state = { active: "", loaded: false };

  componentDidMount() {
    const currentPath = history.location.pathname;
    let componentName = "/";
    if (currentPath !== "/") {
      componentName = currentPath.split("/");
      if (componentName.length > 1) {
        componentName = componentName[1];
      } else {
        componentName = componentName[0];
      }
    }
    this.setState({ active: componentName });
    // Had to put this timer in as it would initialise to invalid and then not change when path changed
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 4000);
  }

  componentDidUpdate() {
    const currentPath = history.location.pathname;
    let componentName = "/";
    if (currentPath !== "/") {
      componentName = currentPath.split("/");
      if (componentName.length > 1) {
        componentName = componentName[1];
      } else {
        componentName = componentName[0];
      }
    }
    if (componentName !== this.state.active) {
      this.setState({ active: componentName });
      // Had to put this in as it would initialise to invalid and then not change when path changed
      setTimeout(() => {
        this.setState({ loaded: true });
      }, 4000);
    }
  }
  isGoodRoute() {
    // console.log(`/${this.state.active}`);
    let validRoute = Object.values(ROUTES).includes(`/${this.state.active}`);
    return this.state.loaded ? validRoute : true;
  }

  render() {
    if (this.isGoodRoute()) {
      return <></>;
    } else {
      return (
        <Container text>
          <Header
            as="h1"
            content="Error! 404"
            // inverted
            style={{
              fontSize: "4em",
              fontWeight: "normal",
              marginBottom: 0,
              marginTop: "3em",
            }}
          />
          <Header
            as="h2"
            content="You have been redirected to a path which does not exist."
            // inverted
            style={{
              fontSize: "1.7em",
              fontWeight: "normal",
              marginTop: "1.5em",
            }}
          />
          <Button as={Link} to={ROUTES.LANDING} color="teal" fluid size="large">
            Click Here to go to the Landing Page
            <Icon name="right arrow" />
          </Button>
        </Container>
      );
    }
  }
}

export default BadRoute;
