import React from 'react';
// import { Link } from 'react-router-dom';

import { Container, Header, List } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <Container text>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Privacy Policy</title>
        <meta
          name='description'
          content='Privacy Policy for machinebuilders.co.uk.'
        />
      </Helmet>
      <Header
        as='h1'
        content='Machine Builders.co.uk Privacy Policy'
        style={{
          fontSize: '4em',
          fontWeight: 'normal',
          marginBottom: 0,
          marginTop: '0.8em',
        }}
      />
      <Header as='h4' style={{ paddingBottom: '1em' }}>
        Last updated: 06/08/2020
      </Header>

      <div
        style={{
          textAlign: 'justify',
          textJustify: 'inter-word',
          paddingBottom: '4em',
        }}
      >
        <Header as='h3'>Overview</Header>
        <p>
          We ask that you read this website privacy policy carefully as it
          contains important information on who we are, how and why we collect,
          store, use and share personal information, your rights in relation to
          your personal information and on how to contact us and supervisory
          authorities in the event you have a complaint.
        </p>
        <p>
          This website privacy policy is divided into the following sections:
        </p>

        <List bulleted>
          <List.Item>Who we are</List.Item>
          <List.Item>Our website</List.Item>
          <List.Item>
            Our collection and use of your personal information
          </List.Item>
          <List.Item>Transfer of your information out of EEA</List.Item>
          <List.Item>Cookies and similar technologies</List.Item>
          <List.Item>Marketing</List.Item>
          <List.Item>Your rights</List.Item>
          <List.Item>Keeping your personal information secure</List.Item>
          <List.Item>How to complain</List.Item>
          <List.Item>Changes to this website privacy policy</List.Item>
          <List.Item>How to contact us</List.Item>
          <List.Item>Do you need extra help?</List.Item>
        </List>

        <Header as='h3'>Who we are</Header>

        <p>
          This website is operated by Machine Builders Ltd. We are a tendering
          platform for machine builders and integrators to bid for work at end
          user sites within the manufacturing sector.
        </p>
        <p>
          We collect, use and are responsible for certain personal information
          about you. When we do so we are regulated under the General Data
          Protection Regulation which applies across the European Union
          (including in the United Kingdom) and we are responsible as
          ‘controller’ of that personal information for the purposes of those
          laws.
        </p>

        <Header as='h3'>Our Website</Header>
        <p>
          This privacy policy relates to your use of our website,
          www.machinebuilders.co.uk only.
        </p>
        <p>
          Throughout our website we may link to other websites owned and
          operated by certain trusted third parties to make services available
          to you. These other third party websites may also gather information
          about you in accordance with their own separate privacy polices. For
          privacy information relating to these other third party websites,
          please consult their privacy policies as appropriate.
        </p>

        <Header as='h3'>
          Our collection and use of your personal information
        </Header>

        <p>
          We collect personal information about you when you access our website,
          register with us, contact us, send us feedback and purchase services
          via our website.
        </p>
        <p>
          We collect this personal information from you either directly, such as
          when you register with us, contact us or purchase services via our
          website or indirectly, such as your browsing activity while on our
          website (see ‘Cookies’ below).
        </p>
        <p>
          The personal information we collect about you depends on the
          particular activities carried out through our website. Such
          information includes:
        </p>
        <List bulleted>
          <List.Item>
            your name, address, company information, and contact details
          </List.Item>
          <List.Item>
            details of any feedback you give us by phone, email, post or via
            social media
          </List.Item>
          <List.Item>
            information about the services we provide to you
          </List.Item>
          <List.Item>
            your account details, such as username, email address, login details
          </List.Item>
        </List>

        <p>We use this personal information to:</p>

        <List bulleted>
          <List.Item>create and manage your account with us</List.Item>
          <List.Item>verify your identity</List.Item>
          <List.Item>provide services to you</List.Item>
          <List.Item>
            customise our website and its content to your particular preferences
          </List.Item>
          <List.Item>
            notify you of any changes to our website or to our services that may
            affect you
          </List.Item>
          <List.Item>improve our services </List.Item>
        </List>

        <p>
          This website is not intended for use by children under the age of 16
          and we do not knowingly collect or use personal information relating
          to children.
        </p>

        <Header as='h3'>
          Our legal basis for processing your personal information
        </Header>

        <p>
          When we use your personal information we are required to have a legal
          basis for doing so. There are various different legal bases upon which
          we may rely, depending on what personal information we process and
          why.
        </p>
        <p>The legal bases we may rely on include:</p>

        <List bulleted>
          <List.Item>
            consent: where you have given us clear consent for us to process
            your personal information for a specific purpose
          </List.Item>
          <List.Item>
            contract: where our use of your personal information is necessary
            for us to facilitate a contract between you and service providers,
            or because you have asked us to take specific steps before entering
            into a contract
          </List.Item>
          <List.Item>
            legal obligation: where our use of your personal information is
            necessary for us to comply with the law (not including contractual
            obligations)
          </List.Item>
          <List.Item>
            vital interests: where our use of your personal information is
            necessary to protect you or someone else’s life
          </List.Item>
          <List.Item>
            legitimate interests: where our use of your personal information is
            necessary for our legitimate interests or the legitimate interests
            of a third party (unless there is a good reason to protect your
            personal information which overrides our legitimate interests)
          </List.Item>
        </List>

        <Header as='h3'>Who we share your personal information with </Header>
        <p>
          We will share your name, company details and contact information with
          service providers interested in quoting upon a project.
        </p>
        <p>This data sharing enables them to provide the services to you. </p>
        <p>
          We will share personal information with law enforcement or other
          authorities if required by applicable law. We will not share your
          personal information with any other third party.
        </p>

        <Header as='h3'>
          Whether information has to be provided by you, and if so why
        </Header>

        <p>
          We require you to provide your name, company address, telephone number
          and email address to enable us to maintain our user account with you
          and to share such information with the service provider or end user so
          that they can perform the services for you. We will inform you at the
          point of collecting information from you, whether you are required to
          provide the information to us.
        </p>

        <Header as='h3'>
          The impact of our use of your personal information
        </Header>

        <p>
          This enables us to facilitate your entry into a contract with the
          service provider and to administer your account.
        </p>

        <Header as='h3'>Transfer of your information out of the EEA </Header>

        <p>
          We will not transfer your personal data outside of the United Kingdom
          or to any organisation (or subordinate bodies) governed by public
          international law or which is set up under any agreement between two
          or more countries.
        </p>

        <Header as='h3'>Cookies and similar technologies </Header>

        <p>
          A cookie is a small text file which is placed onto your device (eg
          computer, smartphone or other electronic device) when you use our
          website. We use cookies including those of Google Analytics, cookie
          consent cookies, and log-in cookies. These help us recognise you and
          your device and store some information about your preferences or past
          actions.
        </p>
        <p>
          For further information on cookies generally visit
          www.aboutcookies.org or
          <a href='www.allaboutcookies.org'>www.allaboutcookies.org.</a>
        </p>

        <Header as='h3'>Marketing </Header>

        <p>
          We would like to send you information about our services and special
          offers, which may be of interest to you. Where we have your consent or
          it is in our legitimate interests to do so, we may do this by post,
          email, telephone or text message (SMS).
        </p>
        <p>
          We would also like to share your information with selected service
          providers and suppliers. We will only ask whether you would like us
          and other businesses to send you marketing messages when you tick the
          relevant boxes when you open a user account for the first time.
        </p>
        <p>
          If you have previously agreed to being contacted in this way, you can
          unsubscribe at any time by:
        </p>
        <List bulleted>
          <List.Item>
            contacting us at{' '}
            <a href='support@machinebuilders.co.uk'>
              support@machinebuilders.co.uk
            </a>
          </List.Item>
          <List.Item>
            using the ‘unsubscribe’ link in emails or ‘STOP’ number in texts
          </List.Item>
        </List>

        <p>It may take up to one month for this to take place. </p>
        <p>
          For more information on your rights in relation to marketing, see
          'Your rights’ below.
        </p>
        <Header as='h3'>Your Rights </Header>

        <p>
          Under the General Data Protection Regulation you have a number of
          important rights free of charge. In summary, those include rights to:
        </p>

        <List bulleted>
          <List.Item>
            fair processing of information and transparency over how we use your
            use personal information
          </List.Item>
          <List.Item>
            access to your personal information and to certain other
            supplementary information that this Privacy Notice is already
            designed to address
          </List.Item>
          <List.Item>
            require us to correct any mistakes in your information which we hold
          </List.Item>
          <List.Item>
            require the erasure of personal information concerning you in
            certain situations
          </List.Item>
          <List.Item>
            receive the personal information concerning you which you have
            provided to us, in a structured, commonly used and machine-readable
            format and have the right to transmit those data to a third party in
            certain situations
          </List.Item>
          <List.Item>
            object at any time to processing of personal information concerning
            you for direct marketing
          </List.Item>
          <List.Item>
            object to decisions being taken by automated means which produce
            legal effects concerning you or similarly significantly affect you
          </List.Item>
          <List.Item>
            object in certain other situations to our continued processing of
            your personal information
          </List.Item>
          <List.Item>
            otherwise restrict our processing of your personal information in
            certain circumstances
          </List.Item>
          <List.Item>
            claim compensation for damages caused by our breach of any data
            protection laws
          </List.Item>
        </List>

        <p>
          For further information on each of those rights, including the
          circumstances in which they apply, see the Guidance from the UK
          Information Commissioner’s Office (ICO) on individuals rights under
          the General Data Protection Regulation.
        </p>
        <p>If you would like to exercise any of those rights, please: </p>

        <List bulleted>
          <List.Item>email, call or write to us</List.Item>
          <List.Item>
            let us have enough information to identify you (eg account number,
            user name, registration details,
          </List.Item>
          <List.Item>
            let us have proof of your identity and address (a copy of your
            driving licence or passport and a recent utility or credit card
            bill) which has been certified by your solicitor as being a true
            copy of your original identification documents, and
          </List.Item>
          <List.Item>
            let us know the information to which your request relates, including
            any account or reference numbers, if you have them
          </List.Item>
        </List>

        <Header as='h3'>Keeping your personal information secure </Header>

        <p>
          We have appropriate security measures in place to prevent personal
          information from being accidentally lost, or used, or accessed in an
          unauthorised way. We limit access to your personal information to
          those who have a genuine business need to know it. Those processing
          your information will do so only in an authorised manner and are
          subject to a duty of confidentiality.
        </p>
        <p>
          We also have procedures in place to deal with any suspected data
          security breach. We will notify you and any applicable regulator of a
          suspected data security breach where we are legally required to do so.
        </p>

        <Header as='h3'>How to complain</Header>

        <p>
          We hope that we can resolve any query or concern you raise about our
          use of your information.
        </p>
        <p>
          The General Data Protection Regulation also gives you right to lodge a
          complaint with a supervisory authority, in particular in the European
          Union (or European Economic Area) state where you work, normally live
          or where any alleged infringement of data protection laws occurred.
          The supervisory authority in the UK is the Information Commissioner
          who may be contacted at
          <a href='https://ico.org.uk/concerns'>https://ico.org.uk/concerns</a>
        </p>

        <Header as='h3'>Changes to this website privacy policy</Header>

        <p>
          This website privacy policy was published on 06/08/2020 and last
          updated /08/2020 We may change this website privacy policy from time
          to time, when we do we will inform you via email and when you next log
          on to your user account.
        </p>

        <Header as='h3'>How to contact us </Header>

        <p>
          Please contact us if you have any questions about this privacy notice
          or the information we hold about you.
        </p>

        <p>
          f you wish to contact us please send an email to:
          <a href='mailto:support@machinebuilders.co.uk'>
            support@machinebuilders.co.uk
          </a>
        </p>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
