import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Message,
  Segment,
} from 'semantic-ui-react';

import * as ROUTES from '../constants/routes';
import { loginUser } from '../actions';

class SignIn extends Component {
  state = { email: '', password: '' };

  handleEmailChange = ({ target }) => {
    this.setState({ email: target.value });
  };

  handlePasswordChange = ({ target }) => {
    this.setState({ password: target.value });
  };

  handleSubmit = () => {
    const { dispatch } = this.props;
    const { email, password } = this.state;

    dispatch(loginUser(email, password));
  };

  render() {
    const { isLoggingIn, loginError, isAuthenticated } = this.props;
    if (isAuthenticated) {
      return <Redirect to={ROUTES.HOME} />;
    } else {
      return (
        <Grid
          textAlign='center'
          style={{ height: '100vh' }}
          verticalAlign='middle'
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as='h2' color='teal' textAlign='center'>
              <Icon circular inverted color='teal' name='cog' size='huge' />{' '}
              Log-in to your account
            </Header>
            <Form size='large'>
              <Segment stacked>
                <Form.Input
                  fluid
                  icon='user'
                  iconPosition='left'
                  placeholder='E-mail address'
                  onChange={this.handleEmailChange}
                />
                <Form.Input
                  fluid
                  icon='lock'
                  iconPosition='left'
                  placeholder='Password'
                  type='password'
                  onChange={this.handlePasswordChange}
                />
                {/* TODO Messy to have this inline on here and signup page. Move this to a function called with the isLoggingIn prop */}
                {(() => {
                  if (isLoggingIn) {
                    return (
                      <Button
                        loading
                        color='teal'
                        fluid
                        size='large'
                        type='submit'
                      >
                        Logging In
                      </Button>
                    );
                  } else {
                    return (
                      <Button
                        color='teal'
                        fluid
                        size='large'
                        onClick={this.handleSubmit}
                      >
                        Login
                      </Button>
                    );
                  }
                })()}

                {loginError && (
                  <Message negative>
                    <Message.Header>Error!</Message.Header>
                    <p>{this.props.loginErrorMessage}</p>
                  </Message>
                )}
              </Segment>
            </Form>
            <Message>
              New to us?&nbsp;<a href={ROUTES.SIGN_UP}>Sign Up</a>
            </Message>
            <Message>
              Forgot your password?&nbsp;
              <a href={ROUTES.PASSWORD_RESET}>Reset password</a>
            </Message>
          </Grid.Column>
        </Grid>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    loginErrorMessage: state.auth.loginErrorMessage,
    isAuthenticated: state.auth.isAuthenticated,
  };
}

export default connect(mapStateToProps)(SignIn);
// export default withStyles(styles)(connect(mapStateToProps)(Login));
