import React from 'react';

import { Container, Header, List } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

const TermsAndConditions = () => {
  return (
    <Container text>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Terms and Conditions</title>
        <meta
          name='description'
          content='Terms and Conditions of use for machinebuilders.co.uk.'
        />
      </Helmet>
      <Header
        as='h1'
        content='Machine Builders.co.uk Terms and Conditions'
        style={{
          fontSize: '4em',
          fontWeight: 'normal',
          marginBottom: 0,
          marginTop: '0.8em',
        }}
      />
      <Header
        as='h2'
        content='Application of conditions of use'
        style={{
          fontSize: '1.7em',
          fontWeight: 'normal',
          marginTop: 0,
        }}
      />
      <Header as='h4' style={{ paddingBottom: '1em' }}>
        Last updated: 02/08/2020
      </Header>
      <div
        style={{
          textAlign: 'justify',
          textJustify: 'inter-word',
          paddingBottom: '4em',
        }}
      >
        <Header as='h3'>Overview</Header>
        <p>
          By accessing or using any part of the website,
          www.machinebuilders.com, (“the website”) you agree to be bound by the
          following conditions of use. If you do not wish to be bound by these
          conditions, you may not access or use the website.
        </p>

        <p>
          We may amend this user agreement without warning by posting updates to
          this website.
        </p>

        <Header as='h3'>Glossary</Header>
        <p>
          Service Providers (OEM) – Contractors to complete a project and submit
          quotations to carry out projects.
        </p>
        <p>
          End Users – Companies who post projects for service providers to bid
          for.
        </p>
        <p>
          Quote – Offer for services including all costs to complete entire
          project posed by an End User.
        </p>
        <p>Project – A live requirement for an end user.</p>
        <p>Verified Account – User has verified email and phone details.</p>
        <p>
          The platform - Machine Builders websites, services, and applications.
        </p>

        <Header as='h2'>All Users</Header>
        <p>All accounts require the below information:</p>
        <List ordered>
          <List.Item>full name;</List.Item>
          <List.Item>a valid email address;</List.Item>
          <List.Item>a valid phone number;</List.Item>
          <List.Item>full business name and address</List.Item>
        </List>

        <Header as='h2'>End Users</Header>
        <Header as='h3'>Eligibility</Header>
        <p>You will not use the website if you:</p>
        <List ordered>
          <List.Item>are not able to form legally binding contracts;</List.Item>
          <List.Item>are under the age of 16</List.Item>
          <List.Item>are suspended from using the website; or</List.Item>
          <List.Item>do not hold a valid email address.</List.Item>
        </List>
        <p>
          All End User accounts are associated with individuals. Login
          credentials should not be shared by users with others. The user
          associated with the account will be held responsible for all actions
          taken by the account, without limitation. Their company may also be
          liable subject to the relevant laws and regulations of their country.
        </p>
        <p>
          We may, at our absolute discretion, refuse to register any person or
          entity as a user.
        </p>
        <Header as='h3'>Confirmation and Contract</Header>
        <p>
          You must set up a free of charge user account. Once you have verified
          your account you will then be able to post a new project to the
          website. As we are a tendering agent we take no responsibility for any
          errors in documentation provided by the End Users when specifying the
          requirements of a project.
        </p>
        <p>
          All contracts are governed by English law unless the service provider
          requires that your contract with them is governed by the law of the
          country where their company is located, or service provided. In the
          event that you have any dispute with any service provider, you will
          generally be able to ask the Courts of England and Wales to deal with
          this (or Scotland or Northern Ireland if you are resident in either of
          these countries).
        </p>

        <Header as='h3'>Services Provided</Header>
        <p>
          We will provide a platform for you to post projects or applications
          for OEMs or service providers to give full proposals within the
          manufacturing industry. We do not perform installation, provide any
          recommendation and take no liability for the outcome of your proposal.
        </p>
        <p>
          Project proposals may be subject to moderation by the platform. You
          may be required to provide additional information or documentation
          before your project goes live. You are solely responsible for ensuring
          accurate information is provided regarding your contact details and
          any details included in the project proposal. The platform will not be
          held liable for any damages caused by inaccurate information provided
          by the end user.
        </p>
        <p>
          Project proposals may be withdrawn from the platform at any time at
          the discretion of the platform.{' '}
        </p>
        <p>
          Once project proposals have been submitted we may choose to publish
          details of the project for consideration by service providers. Service
          providers may then contact you using the details provided when
          creating your account which will be shared with them at our
          discretion. Further details on how your information may be used to
          conduct the required business of the platform is included in our
          Privacy Policy.
        </p>
        <p>
          On contact with service providers you are solely responsible for
          ensuring you comply with your obligation to the service provider. If
          not, you may become liable to that service provider.
        </p>
        <p>
          If the service provider breaches any obligation to you, you are solely
          responsible for any rights that you have. We have no responsibility
          for enforcing this.
        </p>

        <Header as='h3'>Closing your account</Header>
        <p>You may close your account at any time.</p>
        <List ordered>
          <List.Item>
            not having any outstanding obligations on the platform. They must be
            withdrawn prior;
          </List.Item>
          <List.Item>Resolving any outstanding matter; and</List.Item>
          <List.Item>
            Paying any outstanding fees or amounts owed on the account
          </List.Item>
        </List>
        <p>
          We may retain some of your personal information to satisfy
          regulations.
        </p>

        <Header as='h2'>Service Providers</Header>
        <Header as='h3'>Eligibility</Header>
        <p>You will not use the website if you:</p>
        <List ordered>
          <List.Item>are not able to form legally binding contracts;</List.Item>
          <List.Item>are under the age of 16</List.Item>
          <List.Item>are suspended from using the website; or</List.Item>
          <List.Item>do not hold a valid email address.</List.Item>
        </List>
        <p>
          Login credentials should not be shared by users with others. The
          company associated with the account will be held responsible for all
          actions taken by the account, without limitation.
        </p>
        <p>
          We may, at our absolute discretion, refuse to register any person or
          entity as a user.
        </p>

        <Header as='h3'>Confirmation and Contract</Header>
        <p>
          You may be invited to quote for project proposals posted by other
          users. As we are a tendering agent we take no responsibility for any
          errors in documentation provided by the end user when specifying the
          requirements of a project.
        </p>
        <p>
          All contracts are governed by English law unless the service provider
          requires that your contract with them is governed by the law of the
          country where their company is located, or service provided. In the
          event that you have any dispute with any service provider, you will
          generally be able to ask the Courts of England and Wales to deal with
          this (or Scotland or Northern Ireland if you are resident in either of
          these countries).
        </p>

        <Header as='h3'>Fees and Services</Header>
        <p>
          We may charge a fee for services provided by the platform. These fees
          are payable on request and may be non-refundable.
        </p>
        <p>
          Should a project not go ahead for any reason, the platform is not
          liable for any cost incurred whilst submitting quotation.
        </p>
        <p>
          There may be times where this fee is waived such as during promotions
          or as incentives.
        </p>

        <Header as='h3'>Payment</Header>
        <p>
          Payments can be made on the platform when requested. Options for
          payment methods will be made clear at the time of request. Failure to
          make payment will result in loss of use of the platform.
        </p>

        <Header as='h3'>Services Provided</Header>
        <p>
          We will provide a platform for you to view and bid for projects posted
          by other users of the platform. We do not perform any tasks other than
          sourcing proposals and displaying them for consideration. Users must
          use their own judgement when selecting projects to quote for and
          quotations to accept, and we take no liability for the outcome of
          proposals from either party.
        </p>
        <p>
          Service providers are required to have the ability to complete all
          tasks specified by the end user unless negotiated at time of
          quotation.{' '}
        </p>
        <p>
          Once your quotation has been approved by Machine Builders Ltd, it will
          be submitted to the End User. If there are any amendments required
          then you will be informed via your contact information and on the
          platform.
        </p>
        <p>
          The end user may be provided multiple quotations from users of the
          platform. If there are any questions from the end user they will be
          requested directly via your account projects page.
        </p>
        <p>
          Upon approval to view full details of a project, end user contact
          details may be provided to you for you to proceed as required.
          Information that may be shared is provided solely for purposes
          relating to this project proposal or business arrangement and should
          not be shared or transmitted without prior approval. Access to these
          contact details may be removed at any time at the discretion of the
          platform or end user.
        </p>
        <p>
          Providing opportunities for Service Providers to contact End Users
          ends our involvement.{' '}
        </p>
        <p>
          After we provide the opportunity to contact End Users you are solely
          responsible for ensuring you comply with your obligation to that end
          user. If not, you may become liable to that end user.
        </p>
        <p>
          If the End User breaches any obligation to you, you are solely
          responsible for any rights that you have. We have no responsibility
          for enforcing this.{' '}
        </p>

        <Header as='h3'>Closing your account</Header>
        <p>You may close your account at any time.</p>
        <p>Account closing is subject to:</p>
        <List ordered>
          <List.Item>
            not having any outstanding obligations on the platform. This
            material must be withdrawn prior;
          </List.Item>
          <List.Item>Resolving any outstanding matters; and</List.Item>
          <List.Item>
            Paying any outstanding fees or amounts owed on the account
          </List.Item>
        </List>
        <p>
          We may retain some of your personal information to satisfy
          regulations.
        </p>

        <Header as='h2'>Other services</Header>
        <p>
          Postings on our Website are made at such times as
          machinebuilders.co.uk determines at its discretion. You should not
          assume that the information contained on our blog posts have been
          updated or otherwise contains current information.
          MachineBuilders.co.uk does not review past postings to determine
          whether they remain accurate and information contained in such
          postings may have been superseded.
        </p>
        <p>
          Content within the blog is posted without legal liability. Any
          recommendations made are not legally binding and are the views of
          machinebuilders.co.uk not of the author. If a service or technology be
          recommended by a blog post it does create a contract between the
          reader and machinebuilders.co.uk.
        </p>
        <p>
          Your use of any information or materials on this website is entirely
          at your own risk, for which the website owner shall not be liable. It
          shall be your own responsibility to ensure that any products, services
          or information available through this website meet your specific
          requirements. The website owner disclaims any responsibility for
          actions caused by use of this website or information contained on it.
          Readers should use standard and appropriate caution when specifying
          equipment or commencing with quotations or projects.
        </p>
        <p>
          MachineBuilders.co.uk does not necessarily endorse, support, sanction,
          encourage, verify, or agree with the comments, opinions, or statements
          posted in comments or other areas of this website including guest
          posts. You agree that machinebuilders.co.uk are not responsible, and
          shall have no liability to you, with respect to any information or
          materials posted by others, including defamatory, offensive or illicit
          material, even material that violates this agreement.
        </p>
        <p>
          No language on any pages on this website are legally binding except
          what is contained within this document and the privacy policy.
        </p>

        <Header as='h2'>Data Protection</Header>

        <p>
          All personal information that We may use will be collected, processed,
          and held in accordance with the provisions of EU Regulation 2016/679
          General Data Protection Regulation (“GDPR”) and your rights under the
          GDPR.
        </p>
        <p>
          For complete details of Our collection, processing, storage, and
          retention of personal data including, but not limited to, the
          purpose(s) for which personal data is used, the legal basis or bases
          for using it, details of your rights and how to exercise them, and
          personal data sharing (where applicable), please refer to Our Privacy
          Notice available from: www.machinebuilders.co.uk/privacypolicy
        </p>
        <p>
          The following is a summary of our full data protection policy (Click
          here to view full Data Protection Policy). Please read the full
          version before you provide us with any personal information such as
          your name, address, and contact details ("Data").
        </p>
        <p>
          References to data include any sensitive information that you give to
          us such as detail of any disabilities or religious requirements. In
          order to process your quotation or project, we need to use the data
          you provide us with. We take appropriate technical and organisational
          measures to protect your data against unauthorised or unlawful
          processing and accidental loss, destruction or damage.
        </p>
        <p>
          We must pass the data on to the service provider or End User. The data
          may also be provided to security or credit checking companies, public
          authorities if required by them, or as required by law.
        </p>
        <p>
          We will not transfer data to any country outside the UK other than
          those approved by the EU unless the conditions set out in our full
          data protection policy have been satisfied.
        </p>
        <p>
          If we cannot pass your data to the relevant person (as above), whether
          in the UK or not, we cannot accept your use of the platform. In making
          use of the platform, you consent to your data being passed on to the
          relevant persons.
        </p>
        <p>
          You are generally entitled to a copy of your data which we hold. If
          you would like to see this, please contact us. We may make a small
          charge for providing this to you. We will hold your information, where
          collected by us, and may use it to inform you of offers in the future
          or to send you our marketing material.
        </p>
        <p>
          If you do not wish to receive information from us in the future,
          please notify us by email,{' '}
          <a href='mailto:support@machinebuilders.co.uk'>
            support@machinebuilders.co.uk
          </a>
        </p>

        <Header as='h2'>Copyright and Intellectual Property Rights</Header>
        <p>
          All material on our website is owned by the platform and is protected
          by copyright and database rights. This material includes, but is not
          limited to, all images, photographs, written text, design and layouts.
          All trademarks both registered and unregistered are the property of
          the platform.
        </p>
        <p>
          No copying of any material for any commercial or business use is
          permitted unless authorised, in writing, by us. The re-distribution,
          re-publication, or otherwise making available any of the material to
          any third parties without our prior written consent is prohibited.
          Unauthorised use of this may give rise to a claim for damages and
          maybe punishable by law.{' '}
        </p>
        <p>
          Any downloading, printing or copying from this Website must be in
          accordance with these terms. Such activity is permitted solely for
          personal use or for supply to third parties for no commercial gain and
          for their personal use only (unless stated otherwise). You must
          acknowledge us and this Website as the source of the material. You
          confirm that you have no right to use the content in any other manner.{' '}
        </p>
        <p>
          For the avoidance of doubt, you are expressly prohibited from selling
          or trading any information or materials copied from the Website.
        </p>
        <p>
          Except as expressly provided, we do not confer any licence of our
          intellectual property rights.
        </p>
      </div>
    </Container>
  );
};

export default TermsAndConditions;
