import React from 'react';
import { Button, Header, Icon, Segment, Table } from 'semantic-ui-react';
import prettysize from 'prettysize';
import _ from 'lodash';

class FileUploader extends React.Component {
  state = { files: [] };

  handleAddFile = (e) => {
    // Check if a file has been uploaded. If it has, update the state with the list of all the files.
    // Once the state has been updated call the callback function to update state in the form.
    if (e.target.files[0]) {
      this.setState({ files: [...this.state.files, e.target.files[0]] }, () => {
        this.props.setFilesForUpload(this.state.files);
        this.props.setFieldValue('files', this.state.files);
      });
    }
  };

  renderDocumentUploader() {
    if (this.state.files.length === 0) {
      return (
        <>
          <Header icon>
            <Icon name='pdf file outline' />
            No documents have been uploaded.
          </Header>
          {/* <Button primary onClick={this.handleAddFile}> */}
          <Button as='label' htmlFor='file' type='button'>
            Add Document
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Header icon>
            <Icon name='pdf file outline' />
            Documents to be uploaded:
          </Header>
          <Table collapsing>
            <Table.Body>{this.renderDocumentList()}</Table.Body>
          </Table>
          <Button as='label' htmlFor='file' type='button'>
            Add Document
          </Button>
        </>
      );
    }
  }

  renderDocumentList() {
    return this.state.files.map((file) => {
      // console.log(file);
      // console.log(typeof file);
      let fileSize;
      if (file instanceof File) {
        fileSize = prettysize(file.size);
      } else {
        fileSize = prettysize(file.fileSize);
      }
      return (
        <Table.Row>
          <Table.Cell>
            <Icon name='file' />
            {file.name}
          </Table.Cell>
          <Table.Cell>{fileSize}</Table.Cell>
        </Table.Row>
      );
    });
  }

  syncValuesAndState() {
    // If the user goes back to the page containing the file uploader, the state of the component will have been reset
    // this function reconciles the state with the form values.
    if (_.isEqual(this.state.files, this.props.values.files)) {
    } else if (
      this.state.files.length === 0 &&
      this.props.values.files.length > 0
    ) {
      this.setState({ files: this.props.values.files });
    }
  }

  render() {
    this.syncValuesAndState();
    return (
      <Segment placeholder>
        {this.renderDocumentUploader()}
        <input type='file' id='file' hidden onChange={this.handleAddFile} />
      </Segment>
    );
  }
}

export default FileUploader;
