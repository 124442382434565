import React from 'react';

import { Container, Divider, Header, List, Segment } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

const BlogPost200801 = () => {
  return (
    <Container text>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blog - Posting Project Guidelines</title>
        <meta
          name="description"
          content="Guidelines on how to post the perfect project to get the best quotations from machine builders."
        />
      </Helmet>
      <Header
        as="h1"
        content="Blog"
        style={{
          fontSize: '4em',
          fontWeight: 'normal',
          marginBottom: 0,
          marginTop: '0.8em',
        }}
      />
      <Header
        as="h2"
        content="Guides, Case Studies, and Market Trends"
        style={{
          fontSize: '1.7em',
          fontWeight: 'normal',
          marginTop: 0,
        }}
      />
      <Segment>
        <Header size="huge">
          What content should you include when posting a new project?
          <Header.Subheader>
            By machinebuilders.co.uk on 01 August 2020
          </Header.Subheader>
        </Header>
        <p>
          You’ve come to the best place to find quotes for your projects, but a
          quotation will only be as good as the information it is based on.
          We’ve done our best to create a simple yet comprehensive project
          wizard to collect all the information that may be required to put
          together an effective quotation, and this guide aims to expand on what
          is required for each section.
        </p>
        <p>
          When using the ‘new project’ wizard, there are 4 steps to posting a
          project. This guide will walk you through each stage and what
          information we expect to see included there.
        </p>
        <p>
          When writing project information please try to put yourself in the
          shoes of someone who is reviewing and quoting for it. It is a big
          commitment to put together a proposed solution, so try to make sure
          that the companies who will be quoting for your project have all the
          required information and context necessary to do as good a job as
          possible.
        </p>
        <p>
          Don’t be afraid to skip certain sections and come back if you do not
          have all the information required at the start. When you save a
          project at the end of creating it, it will only be saved as a draft
          project. You can return to edit it and include additional information
          whenever you like before publishing it.
        </p>
        <Divider />
        <Header size="large">Basic Info </Header>
        <Header size="medium">Project Title </Header>
        <p>
          This should be a simple overview of the type of work to be done e.g.
          ‘Vision System Integration with S7-1500’ or ‘Wine Bottle Filling
          Machine’.
        </p>
        <Header size="medium">Short Description</Header>
        <p>
          This should be like the blurb to a good book. Along with the title of
          the project, this is the first bit of information a prospective OEM
          will see. E.g. Require a machine to apply labels to shampoo bottles as
          they pass down a conveyor followed by an automated inspection and
          rejection of NG products.
        </p>
        <Header size="medium">Type of Work</Header>
        <p>
          Category – Select which type applies the most to your project, for
          example:
        </p>
        <List bulleted>
          <List.Item>small PLC upgrade (mostly electrical)</List.Item>
          <List.Item>
            installation of mechanical guarding (mostly mechanical)
          </List.Item>
          <List.Item>upgrades to a scada system (mostly software)</List.Item>
          <List.Item>
            or a completely new production line for electric motor stators
            (multidiscipliniary)
          </List.Item>
        </List>
        <p>
          Speciality – This is very important to help identify which skill set
          you think will be the most important. I.e. a mechanical focussed firm
          may struggle with advanced PLC and SQL integration.
        </p>
        <p>
          If you are unsure to begin with, put ‘other’ and continue to fill out
          the rest of the proposal. An advisor from machinebuilders will be in
          touch if we think this can be better specified.
        </p>
        <Divider />
        <Header size="large">Detailed Info</Header>
        <p>The real meat of the project proposal.</p>
        <Header size="medium">Full Detailed Description</Header>
        <p>
          The devil is in the detail throughout this section. Give as much
          information about what is required from a service provider as
          possible. Line speeds, product throughputs, speed of install, as much
          detail as you can possibly give. This will hugely help any prospective
          service provider or OEM to understand the scope of the project.
        </p>
        <p>
          There is no word limit so feel free to type as much as you want into
          this section. The more information that is available in this section,
          the more accurately a quoter can put together a proposal.
        </p>
        <p>Bad Example:</p>
        <p>“We need to inspect a defect on a can”</p>
        <p>Good example:</p>
        <p>
          “We manufacture 330ml tins for the beverage industry. We have a
          quality complaint where the open tops of the can body have been dented
          causing issues with closing the cans at the customer. The smallest
          defect we wish to detect is 0.3mm and it could be anywhere around the
          top lip of the can body. Our line runs at 120 meters per minute and we
          want to perform 15 inspections per second per line. There is plenty of
          space to mount a camera and lighting at whatever height is required
          and we have space and cabling to mount a dedicated control panel for
          this application. Air will be supplied to power a rejection system to
          be provided. The inspection region is up on a walkway so working at
          height may be involved and ideally equipment will be built at your
          site and installed pre assembled to reduce downtime. Normally the
          plant has scheduled downtime on Sundays but we can stagger the
          installation during the 2 hour shutdowns on weekdays if required. Once
          successfully installed we have 3 other lines where this inspection may
          be considered if the first goes well.”
        </p>
        <Header size="medium">Additional information or requirements</Header>
        <p>
          This section is for information such as preferred suppliers e.g.
          Siemens PLCs, Keyence Vision Systems, Sick safety light curtains. This
          is also the best place to add any requirements from the OEM
          themselves, insurance certificates, weekend work required, non
          disclosure agreements, etc.
        </p>
        <Header size="medium">Supporting Documents</Header>
        <p>
          This section is to add any pictures, videos, electrical drawings or
          diagrams of the current process or the requirements. Again the more
          information you can put in here the better idea the service providers
          or OEMs will have in terms of cost and ability to quote. Put as much
          information as you can in the detailed description, but also remember
          that a picture paints a thousand words.
        </p>
        <Divider />
        <Header size="large">Deliverables</Header>
        <p>
          This section delves into the timelines for the project, expected
          completion dates, budget, time to raise funds for the project and all
          things relating to how quickly this project can be fulfilled or needs
          to be completed.
        </p>
        <p>
          We all know projects which need to be completed by a certain date
          can’t always physically be done within those timeframes. The common
          phrase is ‘this needed to be done yesterday’ so be realistic with
          these figures where you can.
        </p>
        <Header size="medium">Quotation Timeline</Header>
        <p>
          How quickly do you expect to receive the quotation? Remember a site
          visit may be required to measure up or fully understand the process.
        </p>
        <Header size="medium">Project Timeline</Header>
        <p>
          Is there a fixed date for this project to be completed? Is there a new
          variant which will go into production by a certain date? This is the
          absolute maximum time you could wait for the project to be completed.
        </p>
        <Header size="medium">Timeline Reason</Header>
        <p>
          It is useful to know ‘why’ this timeline has been set and it will help
          give an idea of the size and scale of the work to be completed.
        </p>
        <p>
          If your quotation timeline is 2 weeks but your project timeline is a
          year it may look like it is not urgent, however if you require the
          quotation in 2 weeks to meet the capex deadline for next year this is
          very urgent.
        </p>
        <p>
          Likewise if the project timeline is 4 weeks it may put off some people
          from quoting, but if it’s the case that you have a planned shutdown in
          4 weeks and plan to begin initial project work during this date it may
          be more reasonable.
        </p>
        <p>
          Giving the reason for the timeline helps the quoter understand your
          business needs and will make sure that they can align with them
          effectively.
        </p>
        <Header size="medium">Approximate Budget</Header>
        <p>
          Budget is always difficult to decide, especially when first developing
          the project. Try to base it on similar experiences with projects of
          this size and scale.
        </p>
        <p>
          Each project should have a payback to the company and this should be a
          monetary value. If it doesn’t improve the company's bottom line then
          it is unlikely to be approved. When planning projects we should always
          have a figure in mind based on the payback. Whether that is to avoid
          customer fines or increase throughput or quality on a line, this
          figure is what you should calculate your budget.
        </p>
        <p>
          Enter a realistic figure which you believe will be approved by
          management and which you think will have a reasonable payback
          timeline.
        </p>
        <p>
          As the project will be advertised to multiple machine builders you
          will likely receive more competitive quotes than if you just went to
          one company. Because of this you will probably receive a range of
          quotations at different price points with different offers. Some may
          be lower than your estimate and some may be higher. Don’t worry too
          much about having an exact figure at this point, just a rough idea of
          what would be viable to let quoters know what level of project this
          is.
        </p>
        <p>
          Any unrealistic budgets will be flagged up when projects are
          published, if we see what looks to be about £100,000 worth of work
          (i.e. a large robot palletiser) posted with a £500 budget we will be
          in touch to help develop the project proposal further.
        </p>
        <Divider />
        <Header size="large">Final Info</Header>
        This page relates to the location and ability of the potential service
        providers or OEMs.
        <Header size="medium">Required Location</Header>
        <p>
          Would you prefer a local company where support and quick response
          won’t be a problem or is the size and quality of the quoter the key to
          a winning quotation?
        </p>
        <p>
          This platform allows you to find local integrators or machine builders
          that you may have never known exist, why not take advantage of that.
        </p>
        <p>
          Sometimes it’s important to choose a local supplier who can respond to
          support requests quickly, but with modern technology it’s sometimes
          very easy for suppliers who are very far away to monitor and modify
          PLC programs remotely.
        </p>
        <p>
          This location parameter is just used to indicate preferred locations,
          you may still receive offers from suppliers who are further away, but
          who specialise in this type of project and can deliver great results.
        </p>
        <Header size="medium">
          List of questions you would like quoters to answer
        </Header>
        <p>
          This should be a list of the key criteria you are interested in
          regarding quotations sent from machine builders. These key questions
          should be answered by quotes when proposing solutions to your
          application. Use these questions much like you would on a posting for
          a job. I.e. “what relevant experience do you have delivering projects
          involving 3D inspection of components”
        </p>
        <p>
          There is no limit to this section so feel free to add as many as you
          like to the list. This will also make it easy for the service provider
          or OEM to decide if they can provide solutions to each of these
          questions.
        </p>
        <Divider />
        <Header size="large">Post-Submission</Header>
        <p>
          You can save the project as a draft by completing the wizard steps
          with placeholder information and come back to add additional data as
          many times as you like.
        </p>
        <p>
          Once you have fully completed the new project proposal wizard with as
          much information as you think will be required you can publish it.
        </p>
        <p>
          On submission for publishing it will be reviewed by our dedicated team
          of moderators. You may be contacted regarding the contents of your
          proposal and required to give some more information in certain aspects
          if we feel there are still outstanding issues to be resolved before
          putting it out for tender.
        </p>
        <p>
          The quicker you can respond to these queries the quicker we can get
          service providers in touch with you.
        </p>
        <p>
          Once approved, the project will be live for service providers to
          request to quote or browse the specification.
        </p>
      </Segment>
    </Container>
  );
};

export default BlogPost200801;
