import React from 'react';
import { Link } from 'react-router-dom';

import { Formik, ErrorMessage } from 'formik';
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Header,
  Icon,
  Message,
  Segment,
} from 'semantic-ui-react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as ROUTES from '../constants/routes';
import { signUpUser } from '../actions';

const initialFormikValues = {
  firstName: '',
  lastName: '',
  companyName: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  terms: [],
  privacy: [],
  marketing: [],
};

const yupValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required.'),
  lastName: Yup.string().required('Last Name is required.'),
  companyName: Yup.string().required('Company Name is required'),
  email: Yup.string().trim().email().required(),
  password: Yup.string()
    .min(9, 'Your password must be at least 9 characters in length.')
    .required(),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
  ),
  terms: Yup.array()
    .ensure('You must accept our terms and conditions to proceed.')
    .required('You must accept our terms and conditions to proceed.'),
  privacy: Yup.array()
    .ensure('You must accept our privacy policy to proceed.')
    .required('You must accept our privacy policy to proceed.'),
});

class SignUp extends React.Component {
  render() {
    const {
      isSigningUp,
      isSignUpSuccess,
      isSignUpError,
      isCreatingNewUserInFirestore,
      isCreatedNewUserInFirestoreSuccess,
      isAuthenticated,
    } = this.props;

    if (
      isSignUpSuccess &&
      isCreatedNewUserInFirestoreSuccess &&
      isAuthenticated
    ) {
      return <Redirect to={ROUTES.SIGN_UP_THANK_YOU} />;
    } else {
      return (
        <div>
          <Formik
            initialValues={initialFormikValues}
            validationSchema={yupValidationSchema}
            onSubmit={(fields) => {
              // signUpUser(fields);
              this.props.signUpUser(fields);
              // alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 4));
            }}
            render={({
              values,
              dirty,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isValid,
            }) => {
              // console.log(values);
              // console.log(errors);
              return (
                <Grid
                  textAlign="center"
                  style={{ height: '100vh' }}
                  verticalAlign="middle"
                >
                  <Grid.Column style={{ maxWidth: 450 }}>
                    <Header as="h2" color="teal" textAlign="center">
                      <Icon
                        circular
                        inverted
                        color="teal"
                        name="cog"
                        size="huge"
                      />{' '}
                      Sign up to create an account
                    </Header>
                    <Form size="large">
                      <Segment stacked>
                        <Form.Input
                          fluid
                          icon="user"
                          iconPosition="left"
                          placeholder="First Name"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.firstName && errors.firstName && (
                          <ErrorMessage
                            name="firstName"
                            render={(msg) => (
                              <Message negative>
                                <p>{msg}</p>
                              </Message>
                            )}
                          />
                        )}
                        <Form.Input
                          fluid
                          icon="user"
                          iconPosition="left"
                          placeholder="Last Name"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.lastName && errors.lastName && (
                          <ErrorMessage
                            name="lastName"
                            render={(msg) => (
                              <Message negative>
                                <p>{msg}</p>
                              </Message>
                            )}
                          />
                        )}
                        <Form.Input
                          fluid
                          icon="user"
                          iconPosition="left"
                          placeholder="Company Name"
                          name="companyName"
                          value={values.companyName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.companyName && errors.companyName && (
                          <ErrorMessage
                            name="companyName"
                            render={(msg) => (
                              <Message negative>
                                <p>{msg}</p>
                              </Message>
                            )}
                          />
                        )}
                        <Form.Input
                          fluid
                          icon="user"
                          iconPosition="left"
                          placeholder="E-mail address"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          // onBlur={handleBlur}
                          onBlur={(event) => {
                            setFieldValue(
                              event.target.name,
                              event.target.value.trim()
                            );
                            handleBlur(event);
                            setTimeout(() => handleBlur(event), 100);
                          }}
                        />
                        {touched.email && errors.email && (
                          <ErrorMessage
                            name="email"
                            render={(msg) => (
                              <Message negative>
                                <p>{msg}</p>
                              </Message>
                            )}
                          />
                        )}
                        <Form.Input
                          fluid
                          icon="lock"
                          iconPosition="left"
                          name="password"
                          type="password"
                          placeholder="Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.password && errors.password && (
                          <ErrorMessage
                            name="password"
                            render={(msg) => (
                              <Message negative>
                                <p>{msg}</p>
                              </Message>
                            )}
                          />
                        )}
                        <Form.Input
                          fluid
                          icon="lock"
                          iconPosition="left"
                          placeholder="Password confirmation"
                          name="passwordConfirmation"
                          type="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.passwordConfirmation &&
                          errors.passwordConfirmation && (
                            <ErrorMessage
                              name="passwordConfirmation"
                              render={(msg) => (
                                <Message negative>
                                  <p>{msg}</p>
                                </Message>
                              )}
                            />
                          )}
                        <Form.Input>
                          {/* <Checkbox
                            name='terms'
                            id='terms'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label='I agree to the Terms and Conditions'
                          /> */}
                          <Checkbox
                            name="terms"
                            id="terms"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={
                              <label>
                                I agree to the&nbsp;
                                <Link to={ROUTES.TERMS_AND_CONDITIONS}>
                                  Terms and Conditions
                                </Link>
                              </label>
                            }
                          />
                        </Form.Input>
                        {touched.terms && errors.terms && (
                          <ErrorMessage
                            name="terms"
                            render={(msg) => (
                              <Message negative>
                                <p>{msg}</p>
                              </Message>
                            )}
                          />
                        )}
                        <Form.Input>
                          <Checkbox
                            name="privacy"
                            id="privacy"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={
                              <label>
                                I agree to the&nbsp;
                                <Link to={ROUTES.PRIVACY_POLICY}>
                                  Privacy Policy
                                </Link>
                              </label>
                            }
                          />
                        </Form.Input>
                        {touched.terms && errors.terms && (
                          <ErrorMessage
                            name="privacy"
                            render={(msg) => (
                              <Message negative>
                                <p>{msg}</p>
                              </Message>
                            )}
                          />
                        )}
                        <Form.Input>
                          <Checkbox
                            name="marketing"
                            id="marketing"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="I would be happy to receive infrequent marketing materials."
                          />
                        </Form.Input>
                        {touched.terms && errors.terms && (
                          <ErrorMessage
                            name="marketing"
                            render={(msg) => (
                              <Message negative>
                                <p>{msg}</p>
                              </Message>
                            )}
                          />
                        )}

                        {/* The section below checks if the user is signing up / having their user record created.
                        Then it returns the loading button if it is. Otherwise it shows the connected actionable button. 
                        Had to create an inline function due to some quirks with formik.*/}
                        {(() => {
                          if (isSigningUp || isCreatingNewUserInFirestore) {
                            return (
                              <Button
                                loading
                                color="teal"
                                fluid
                                size="large"
                                type="submit"
                              >
                                Loading
                              </Button>
                            );
                          } else if (!isValid || !dirty) {
                            return (
                              <Button
                                disabled
                                color="teal"
                                fluid
                                size="large"
                                type="submit"
                              >
                                Sign Up
                              </Button>
                            );
                          } else {
                            return (
                              <Button
                                color="teal"
                                fluid
                                size="large"
                                type="submit"
                                onClick={handleSubmit}
                              >
                                Sign Up
                              </Button>
                            );
                          }
                        })()}
                        {isSignUpError && (
                          <Message negative>
                            <Message.Header>Error!</Message.Header>
                            <p>{this.props.signUpErrorMessage}</p>
                            {/* TODO change this to a mail href link and add contact us page */}
                            <p>
                              Please contact{' '}
                              <a href="mailto:support@machinebuilders.co.uk?subject=Contact%20Machine%20Builders%20Support">
                                support@machinebuilders.co.uk
                              </a>
                            </p>
                          </Message>
                        )}
                      </Segment>
                    </Form>
                    <Message>
                      Already have an account?
                      <a href={ROUTES.SIGN_IN}>Sign In</a>
                    </Message>
                  </Grid.Column>
                </Grid>
              );
            }}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isSigningUp: state.auth.isSigningUp,
    isSignUpSuccess: state.auth.isSignUpSuccess,
    isSignUpError: state.auth.isSignUpError,
    signUpErrorMessage: state.auth.signUpErrorMessage,
    isCreatingNewUserInFirestore: state.auth.isCreatingNewUserInFirestore,
    isCreatedNewUserInFirestoreSuccess:
      state.auth.isCreatedNewUserInFirestoreSuccess,
    isCreatedNewUserInFirestoreError:
      state.auth.isCreatedNewUSerInFirestoreError,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps, { signUpUser })(SignUp);
