import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Dimmer,
  Grid,
  Header,
  Icon,
  List,
  Loader,
  Segment,
  Table,
  Divider,
} from 'semantic-ui-react';
import prettysize from 'prettysize';

import * as ROUTES from '../../constants/routes';
import {
  getOneUsersProjectFromFirestore,
  setProjectAsPublishedInFirestore,
  setProjectAsPublishedInFirestoreStateClear,
} from '../../actions';

class ShowProject extends React.Component {
  state = {
    publishElementDisplay: false,
    projectRef: '',
    publishingLoading: false,
    showRejection: false,
  };

  componentDidMount() {
    const { projectRef } = this.props.match.params;
    // console.log(projectRef);
    this.props.getOneUsersProjectFromFirestore(projectRef);
  }

  projectLoading() {
    return (
      <Segment loading>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Segment>
    );
  }

  oldProjectDisplay = () => {
    return (
      <>
        <Segment>
          <Grid>
            <Grid.Column width={16}>
              <Header as="h2" dividing>
                <Header.Content>
                  <Header.Subheader>Project Title</Header.Subheader>
                  {this.props.project.title}
                </Header.Content>
              </Header>
              <br />
              <Header as="h4" dividing>
                <Header.Content>
                  Description
                  <Header.Subheader>
                    <small>
                      This is what people can see before they apply to quote.
                    </small>
                  </Header.Subheader>
                </Header.Content>
              </Header>
              <Container textAlign="left">
                {this.props.project.description}
              </Container>

              <br />
              <Header as="h4" dividing>
                <Header.Content>
                  Detailed Description
                  <Header.Subheader>
                    <small>
                      This is everything someone needs to put together an
                      effective quote.
                    </small>
                  </Header.Subheader>
                </Header.Content>
              </Header>
              <Container textAlign="left">
                {this.props.project.detailedDescription}
              </Container>

              <p>{this.props.project.detailedDecription}</p>

              <br />
              <Button
                as={Link}
                to={`${ROUTES.EDIT_PROJECT_PREFIX}${this.props.match.params.projectRef}`}
                color="teal"
                size="tiny"
                floated="right"
              >
                Edit
              </Button>
            </Grid.Column>
          </Grid>
        </Segment>
      </>
    );
  };

  convertCamelToHumanReadable(str) {
    return str
      .match(/^[a-z]+|[A-Z][a-z]*/g)
      .map(function (x) {
        return x[0].toUpperCase() + x.substr(1).toLowerCase();
      })
      .join(' ');
  }

  renderQuestionList(questions) {
    const questionsList = questions.map((question, index) => {
      return <List.Item key={index * question.length}>{question}</List.Item>;
    });
    return (
      <List.Item key={'question list container'}>
        <List.Header>Questions for quoter</List.Header>
        <List ordered relaxed>
          {questionsList}
        </List>
      </List.Item>
    );
  }

  downloadFile(url, filename) {
    var link = document.createElement('a');
    link.download = filename;
    link.href = url;
    link.target = 'blank';
    link.click();
  }

  renderUploadedDocuments(documents) {
    const documentsList = documents.map((document) => {
      return (
        <Table.Row key={`${document.size}${document.name}`}>
          <Table.Cell>
            <Icon name="file" />
            {document.name}
          </Table.Cell>
          <Table.Cell>{prettysize(document.fileSize)}</Table.Cell>
          <Table.Cell>
            <Button
              onClick={() => {
                this.downloadFile(document.downloadUrl, document.name);
              }}
            >
              Open File
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
    return (
      <List.Item key={'Uploaded document container'}>
        <List.Header>Uploaded documents</List.Header>
        <Table>
          <Table.Body>{documentsList}</Table.Body>
        </Table>
      </List.Item>
    );
  }

  listOfProjectElements = () => {
    const projectHeaders = [
      'title',
      'description',
      'category',
      'speciality',
      'detailedDescription',
      'additionalInformation',
      'uploadedDocuments',
      'quoteTimeline',
      'projectTimeline',
      'timelineReason',
      'budget',
      'quoterLocation',
      'questions',
    ];

    const categoryHumanised = {
      electrical: 'Electrical',
      mechanical: 'Mechanical',
      software: 'Software',
      multidisciplinary: 'Multidiscipliniary',
      other: 'Other',
    };

    const specialityHumanised = {
      newPanelElectrical: 'New Panel',
      newHardwareElectrical: 'New Hardware',
      upgradeElectrical: 'Upgrade',
      drawingsElectrical: 'Drawings',
      modificationsElectrical: 'Modifications',
      otherElectrical: 'Other',
      newFabrication: 'New Fabrication',
      equipmentInstallation: 'Equipment Installation',
      modifications: 'Modifications',
      otherMechanical: 'Other',
      plc: 'PLC',
      scada: 'Scada / SAP',
      pcSoftware: 'PC / Server Software',
      otherSoftware: 'Other',
      newMachine: 'New Machine',
      machineUpgrade: 'Machine Upgrade',
      fullLine: 'Full Line Design & Build',
      commissioning: 'Commissioning',
      otherMultidiscipliniary: 'Other',
      otherOther: 'Other',
    };

    const locationHumanised = {
      '1hour': 'Within a 1 hour drive of site',
      '2hour': 'Within a 2 hour drive of site',
      '4hour': 'Within a 4 hour drive of site',
      uk: 'Anywhere in the UK',
      europe: 'Anywhere within Europe',
      worldwide: 'Anywhere globally',
    };
    const quoteTimelineHumanised = {
      rushed: 'Needed urgently',
      '1month': 'Required within 1 month',
      anytime: 'No specific timeline.',
    };

    const projectTimelineHumanised = {
      '1week': 'Project needs delivering within 1 week.',
      '1month': 'Project needs delivering within 1 month',
      '3months': 'Project needs delivering within 3 months',
      '6months': 'Project needs delivering within 6 months',
      '1year': 'Project needs delivering within 12 months',
      '>1year': 'Project does not need delivering until after 12 months',
    };

    const projectHeadersHumanised = {
      title: { header: 'Project Title', append: '' },
      description: { header: 'Short Description', append: '' },
      category: { header: 'Category of work', append: '' },
      speciality: { header: 'Speciality', append: '' },
      detailedDescription: {
        header: 'Detailed Description of Project',
        append: '',
      },
      additionalInformation: { header: 'Additional Information', append: '' },
      uploadedDocuments: { header: 'Uploaded Documents', append: '' },
      quoteTimeline: { header: 'Timeline for Quotations', append: '' },
      projectTimeline: {
        header: 'Timeline for project implementation',
        append: '',
      },
      timelineReason: { header: 'Timeline Reason', append: '' },
      budget: { header: 'Approximate Budget', append: '£' },
      quoterLocation: { header: 'Desired location of Quoter', append: '' },
      questions: { header: 'Questions from the customer', append: '' },
    };
    const list = projectHeaders.map((key) => {
      if (this.props.project[key]) {
        if (key === 'questions') {
          const questionList = this.renderQuestionList(this.props.project[key]);
          return questionList;
        } else if (key === 'uploadedDocuments') {
          const documentList = this.renderUploadedDocuments(
            this.props.project[key]
          );
          return documentList;
        } else if (key === 'quoteTimeline') {
          return (
            <List.Item key={key}>
              <List.Header>{projectHeadersHumanised[key].header}</List.Header>
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {projectHeadersHumanised[key].append}
                {quoteTimelineHumanised[this.props.project[key]]}
              </div>
            </List.Item>
          );
        } else if (key === 'projectTimeline') {
          return (
            <List.Item key={key}>
              <List.Header>{projectHeadersHumanised[key].header}</List.Header>
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {projectHeadersHumanised[key].append}
                {projectTimelineHumanised[this.props.project[key]]}
              </div>
            </List.Item>
          );
        } else if (key === 'quoterLocation') {
          return (
            <List.Item key={key}>
              <List.Header>{projectHeadersHumanised[key].header}</List.Header>
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {projectHeadersHumanised[key].append}
                {locationHumanised[this.props.project[key]]}
              </div>
            </List.Item>
          );
        } else if (key === 'category') {
          return (
            <List.Item key={key}>
              <List.Header>{projectHeadersHumanised[key].header}</List.Header>
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {projectHeadersHumanised[key].append}
                {categoryHumanised[this.props.project[key]]}
              </div>
            </List.Item>
          );
        } else if (key === 'speciality') {
          return (
            <List.Item key={key}>
              <List.Header>{projectHeadersHumanised[key].header}</List.Header>
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {projectHeadersHumanised[key].append}
                {specialityHumanised[this.props.project[key]]}
              </div>
            </List.Item>
          );
        } else if (key === 'budget') {
          const formattedBudget = new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(this.props.project[key]);

          return (
            <List.Item key={key}>
              <List.Header>{projectHeadersHumanised[key].header}</List.Header>
              <div style={{ whiteSpace: 'pre-wrap' }}>{formattedBudget}</div>
            </List.Item>
          );
        }
      }

      return (
        <List.Item key={key}>
          <List.Header>{projectHeadersHumanised[key].header}</List.Header>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {projectHeadersHumanised[key].append}
            {this.props.project[key]}
          </div>
        </List.Item>
      );
    });
    return list;
  };

  projectGenerator = () => {
    if (!this.props.projectLoaded) {
      return this.projectLoading();
    } else {
      return (
        <Segment>
          <List relaxed>{this.listOfProjectElements()}</List>
        </Segment>
      );
    }
  };

  handleSetAsPublished = () => {
    const { projectRef } = this.props.match.params;
    this.setState({ publishingLoading: true });
    this.props.setProjectAsPublishedInFirestore(projectRef);
    setTimeout(() => {
      this.props.getOneUsersProjectFromFirestore(projectRef);
      setTimeout(() =>
        this.setState({
          publishElementDisplay: false,
          publishingLoading: false,
        })
      );
    }, 2000);
  };

  setAsPublishedConfirmation = () => {
    if (this.state.publishElementDisplay) {
      return (
        <Segment raised>
          <Segment placeholder>
            <Header as="h3">
              You are about to publish this project!
              <Header.Subheader>
                Ensure all project details are correct before publishing.{' '}
              </Header.Subheader>
            </Header>
            <p>
              Once published your project will be reviewed by our Admins and
              published if it meets the required standard.
            </p>
            <p>
              To be successfully approved and posted for tenders your project
              should:
            </p>
            <List bulleted>
              <List.Item>
                Contain all the relevant information required to produce an
                effective quote
              </List.Item>
              <List.Item>
                Include all relevant documentation required to produce an
                effective quote. (drawings, diagrams, manuals, sample programs,
                etc)
              </List.Item>
              <List.Item>
                Follow our best practice guidelines for effective project
                requirements.
              </List.Item>
            </List>
            <Divider horizontal>
              <Header as="h4">Happy to proceed with publishing?</Header>
            </Divider>
            <Button.Group>
              <Button
                as={Link}
                to={`${ROUTES.EDIT_PROJECT_PREFIX}${this.props.match.params.projectRef}`}
                size="tiny"
              >
                Edit
              </Button>
              <Button.Or />
              <Button onClick={this.handleSetAsPublished} color="teal">
                Proceed
              </Button>
            </Button.Group>
          </Segment>
        </Segment>
      );
    }
  };

  since(time) {
    const timeNow = Math.floor(Date.now() / 1000);
    const elapsedSeconds = timeNow - time.seconds;
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    const elapsedHours = Math.floor(elapsedMinutes / 60);
    const elapsedDays = Math.floor(elapsedHours / 24);

    if (elapsedDays > 1) {
      return `${elapsedDays} Day${elapsedDays === 1 ? '' : 's'}`;
    } else if (elapsedHours >= 1) {
      return `${elapsedHours} Hour${elapsedHours === 1 ? '' : 's'}`;
    } else if (elapsedMinutes >= 1) {
      return `${elapsedMinutes} Minute${elapsedMinutes === 1 ? '' : 's'}`;
    } else if (elapsedSeconds >= 1) {
      return `${elapsedSeconds} Second${elapsedSeconds === 1 ? '' : 's'}`;
    }
    return 'Error';
  }

  generateProjectStatusAndButtons = () => {
    const {
      isLive,
      isApprovedByAdmin,
      isRejectedByAdmin,
      isPublished,
      isCancelled,
      isCompleted,
      isDraft,
      createdAt,
      updatedAt,
    } = this.props.project;
    let status = (
      <Header as="h2" textAlign="center">
        Loading
      </Header>
    );
    let details =
      'Loading project status. If this does not load contact support.';
    let action = <></>;
    if (isLive) {
      status = (
        <Header as="h2" textAlign="center" color="green">
          Live
        </Header>
      );
      details = `Project is live since ${this.since(updatedAt)}.`;
      action = (
        <Button
          onClick={() => alert('Contact support to edit a live project')}
          size="tiny"
        >
          Edit
        </Button>
      );
    } else if (isApprovedByAdmin && isPublished) {
      status = (
        <Header as="h2" textAlign="center" color="olive">
          Pending Go Live
        </Header>
      );
      details = `Project has been Published by yourself and has been approved by Admins.
      \n Project has been waiting for ${this.since(createdAt)}.
      \nProject should 'Go Live' for publication shortly.
      \nIf it has been longer than 24 hours since approval please contact support.
      \nIf you wish to make any changes to the project before Go Live please do so now and re submit it for publishing.`;
      action = (
        <Button
          as={Link}
          to={`${ROUTES.EDIT_PROJECT_PREFIX}${this.props.match.params.projectRef}`}
          // color='teal'
          size="tiny"
        >
          Edit
        </Button>
      );
    } else if (isApprovedByAdmin) {
      status = (
        <Header as="h2" textAlign="center" color="olive">
          Approved
        </Header>
      );
      details = `Project has been approved by Admins but has been left in draft status for ${this.since(
        createdAt
      )}. \nPublish it to go live.`;
      action = (
        <Button
          onClick={() => {
            this.setState({ publishElementDisplay: true });
          }}
          color="teal"
          size="tiny"
        >
          Publish
        </Button>
      );
    } else if (isRejectedByAdmin) {
      status = (
        <Header as="h2" textAlign="center" color="orange">
          Returned
        </Header>
      );
      details = `Project has been rejected for publishing and requires additional information to go live. \nView feedback and edit it to publish again.`;
      action = (
        <Button
          onClick={() => this.setState({ showRejection: true })}
          // color='teal'
          size="tiny"
        >
          View Feedback
        </Button>
      );
    } else if (isPublished) {
      status = (
        <Header as="h2" textAlign="center" color="olive">
          Pending
        </Header>
      );
      details = `Project has been submitted for publishing and has been waiting for approval from Admins to go live for ${this.since(
        updatedAt
      )}\nEdit this project to make any more changes before approval and republish to go live.`;
      action = (
        <Button
          as={Link}
          to={`${ROUTES.EDIT_PROJECT_PREFIX}${this.props.match.params.projectRef}`}
          // color='teal'
          size="tiny"
        >
          Edit
        </Button>
      );
    } else if (isCancelled) {
      status = (
        <Header as="h2" textAlign="center" color="Brown">
          Cancelled
        </Header>
      );
      details = `This project was cancelled on ${updatedAt}. \n`;
      action = (
        <Button
          onClick={() => {
            this.setState({ publishElementDisplay: true });
          }}
          color="teal"
          size="tiny"
        >
          Revive/Publish
        </Button>
      );
    } else if (isCompleted) {
      status = (
        <Header as="h2" textAlign="center" color="green">
          Completed
        </Header>
      );
      details = `This project was marked as completed on ${updatedAt}.\n Contact support if you wish to publish this project again.`;
      action = (
        <Button
          onClick={() => alert('contact support to republish a project')}
          size="tiny"
        >
          RePublish
        </Button>
      );
    } else if (isDraft) {
      status = (
        <Header as="h2" textAlign="center" color="grey">
          Draft
        </Header>
      );
      details = `Project has been a draft for ${this.since(
        createdAt
      )}. \nPublish this project and it will go live pending approval.`;
      action = (
        <Button
          onClick={() => {
            this.setState({ publishElementDisplay: true });
          }}
          color="teal"
          size="tiny"
        >
          Publish
        </Button>
      );
    }
    return (
      <Table attached celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell singleLine>Status</Table.HeaderCell>
            <Table.HeaderCell singleLine>Details</Table.HeaderCell>
            <Table.HeaderCell singleLine> Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{status}</Table.Cell>
            <Table.Cell style={{ whiteSpace: 'pre-wrap' }}>
              {details}
            </Table.Cell>
            <Table.Cell style={{ whiteSpace: 'pre-wrap' }}>{action}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  renderRejectionReason() {
    if (this.props.projectLoaded) {
      if (this.state.showRejection) {
        if (this.props.project.isRejectedByAdmin) {
          if (this.props.project.rejectionReason) {
            return (
              <Segment raised>
                <Segment inverted color="red">
                  <Header>Project requires modifications</Header>
                  <p>
                    Unfortunately your project has been rejected for Go Live in
                    it's current state.
                  </p>
                  <p>
                    Please read the feedback provided by our Admins and modify
                    your project proposal based on our suggestions
                  </p>
                  <p>
                    Click on 'edit' to ammend your project. Please contact
                    support if you have any questions. Alternatively please read
                    our best practice guidelines for effective project
                    requirements.
                  </p>
                  <Header as="h4">Reason:</Header>
                  <div style={{ whiteSpace: 'pre-wrap' }}>
                    {this.props.project.rejectionReason}
                  </div>
                </Segment>
              </Segment>
            );
          }
        }
      }
    }
  }

  render() {
    return (
      <Container text>
        <br />
        <br />
        <Segment.Group raised>
          <Segment>
            <Button
              as={Link}
              to={ROUTES.HOME}
              color="teal"
              size="tiny"
              floated="right"
            >
              Back
            </Button>

            <Button
              as={Link}
              to={`${ROUTES.EDIT_PROJECT_PREFIX}${this.props.match.params.projectRef}`}
              // color='teal'
              size="tiny"
              floated="right"
            >
              Edit
            </Button>
            <Header as="h2">
              <Header.Content>
                View Project
                <Header.Subheader>
                  Edit, Publish, or Delete your project
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Segment>
          <Segment basic>
            <Dimmer
              active={
                this.props.markingProjectAsPublished ||
                this.state.publishingLoading
              }
              inverted
            >
              <Loader size="large" inverted>
                Publishing project . . .
              </Loader>
            </Dimmer>
            {this.generateProjectStatusAndButtons()}
          </Segment>
          {this.renderRejectionReason()}
          {this.setAsPublishedConfirmation()}
          <Segment basic>{this.projectGenerator()}</Segment>
        </Segment.Group>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    projectLoaded: state.firestoreReducers.gotUserProjectFromFirestore,
    project: state.firestoreReducers.userProject,
    markingProjectAsPublished:
      state.firestoreReducers.markingProjectAsPublished,
    markingProjectAsPublishedSuccess:
      state.firestoreReducers.markingProjectAsPublishedSuccess,
    markingProjectAsPublishedError:
      state.firestoreReducers.markingProjectAsPublishedError,
    markingProjectAsPublishedErrorMessage:
      state.firestoreReducers.markingProjectAsPublishedErrorMessage,
  };
};

export default connect(mapStateToProps, {
  getOneUsersProjectFromFirestore,
  setProjectAsPublishedInFirestore,
  setProjectAsPublishedInFirestoreStateClear,
})(ShowProject);
