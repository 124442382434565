// import ReactDOM from 'react-dom';
import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { Button, Divider, Image, Modal, Header } from "semantic-ui-react";

class ImageCropper extends PureComponent {
  state = {
    src: URL.createObjectURL(this.props.logoFile),
    fileName: this.props.logoFile.name,
    mimeType: this.props.logoFile.type,
    modalPage: "crop",
    hasCropped: false,
    crop: {
      unit: "%",
      width: 100,
      aspect: 4 / 4,
      x: 100,
    },
  };

  componentDidUpdate() {
    if (this.state.fileName !== this.props.logoFile.name) {
      this.setState({
        src: URL.createObjectURL(this.props.logoFile),
        fileName: this.props.logoFile.name,
        mimeType: this.props.logoFile.type,
        modalPage: "crop",
        hasCropped: false,
      });
    }
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  //   onImageLoaded = (image) => {
  //     this.imageRef = image;
  //   };

  onImageLoaded = (image) => {
    this.imageRef = image;

    const width =
      image.width > image.height ? (image.height / image.width) * 100 : 100;
    const height =
      image.height > image.width ? (image.width / image.height) * 100 : 100;
    const x = width === 100 ? 0 : (100 - width) / 2;
    const y = height === 100 ? 0 : (100 - height) / 2;

    this.setState({
      crop: {
        unit: "%",
        aspect: 1,
        width,
        height,
        x,
        y,
      },
    });

    return false;
  };

  onCropComplete = (crop) => {
    // console.log(crop);
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    // console.log(crop);
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        this.state.fileName
      );
      this.setState({ croppedImageUrl, hasCropped: true });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }

        blob.name = fileName;
        this.setState({ imageBlob: blob });
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, this.state.mimeType);
    });
  }

  generateCrop() {
    const { crop, src } = this.state;
    if (src) {
      return (
        <ReactCrop
          src={src}
          crop={crop}
          ruleOfThirds
          onImageLoaded={this.onImageLoaded}
          onComplete={this.onCropComplete}
          onChange={this.onCropChange}
        />
      );
    }
  }

  generateCroppedImage() {
    if (this.state.croppedImageUrl) {
      return (
        <Modal.Content image>
          <Header as="h3">
            Cropped Image
            <Header.Subheader>
              Once you are satisfied with the crop click on 'Complete'.
            </Header.Subheader>
          </Header>
          <Image
            rounded
            wrapped
            alt="Cropped Image"
            src={this.state.croppedImageUrl}
          />{" "}
        </Modal.Content>
      );
    } else {
      return <Header as="h2">Adjust the crop to begin.</Header>;
    }
  }

  generateButtons() {
    if (this.state.modalPage === "crop" && this.state.hasCropped) {
      return (
        <Button
          floated="right"
          color="teal"
          onClick={() => {
            this.setState({ modalPage: "review" });
          }}
        >
          Continue
        </Button>
      );
    } else if (this.state.modalPage === "crop") {
      return (
        <Button floated="right" disabled>
          Crop Image to Continue
        </Button>
      );
    } else if (this.state.modalPage === "review" && this.state.hasCropped) {
      return (
        <>
          <Button
            floated="left"
            onClick={() => {
              this.setState({ modalPage: "crop" });
            }}
          >
            Go back
          </Button>
          <Button
            floated="right"
            color="teal"
            onClick={() => {
              this.submitForUpload();
              this.props.closeModal();
            }}
          >
            Submit
          </Button>
        </>
      );
    }
  }

  generateModalPage() {
    if (this.state.modalPage === "crop") {
      return (
        <Modal.Content>
          <Header as="h3">
            Original Image
            <Header.Subheader>
              Adjust the size and position of the crop until you are happy.
            </Header.Subheader>
          </Header>

          {this.generateButtons()}
          <br />
          <br />
          <Divider />
          {this.generateCrop()}
        </Modal.Content>
      );
    } else {
      return (
        <Modal.Content>
          <Header as="h3">
            Cropped Image
            <Header.Subheader>
              Once you are satisfied with the crop click on 'Complete'.
            </Header.Subheader>
          </Header>
          {this.generateButtons()}
          <br />
          <br />
          <Divider />
          <Image
            rounded
            wrapped
            alt="Cropped Image"
            src={this.state.croppedImageUrl}
          />{" "}
        </Modal.Content>
      );
    }
  }

  submitForUpload() {
    // console.log(this.state.imageBlob);
    this.props.setImageBlob(this.state.imageBlob);
  }

  render() {
    //const { crop, croppedImageUrl, src } = this.state;

    return (
      //   <Modal.Content>
      //     <Header as='h3'>
      //       Original Image
      //       <Header.Subheader>
      //         Adjust the size and position of the crop until you are happy.
      //       </Header.Subheader>
      //     </Header>
      //     {this.generateCrop()}
      //     <Divider />
      //     {this.generateCroppedImage()}
      //   </Modal.Content>
      <Modal size="small" open={this.props.modalOpen}>
        {this.generateModalPage()}
      </Modal>
    );
  }
}

export default ImageCropper;
