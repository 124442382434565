import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Container, Header } from 'semantic-ui-react';

import * as ROUTES from '../../constants/routes';

class Projects extends Component {
  render() {
    return (
      <Container text>
        <Header
          as='h1'
          content='Live Projects'
          style={{
            fontSize: '4em',
            fontWeight: 'normal',
            marginBottom: 0,
            marginTop: '0.8em',
          }}
        />
        <Header
          as='h2'
          content='View live projects and submit your proposals'
          style={{
            fontSize: '1.7em',
            fontWeight: 'normal',
            marginTop: 0,
          }}
        />

        <p style={{ fontSize: '1.33em', marginTop: '2em' }}>
          Only approved suppliers can view this page.
        </p>
        <p>
          If you are interested in supplying quotations please{' '}
          <Link to={ROUTES.CONTACT_US}>register your interest with us. </Link>
        </p>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(Projects);
