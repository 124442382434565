import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  CREATING_NEW_USER_IN_FIRESTORE,
  CREATED_NEW_USER_IN_FIRESTORE,
  CREATING_NEW_USER_IN_FIRESTORE_ERROR,
  SEND_PASSWORD_RESET_EMAIL,
  SEND_PASSWORD_RESET_EMAIL_SUCCESS,
  SEND_PASSWORD_RESET_EMAIL_ERROR,
  SEND_PASSWORD_RESET_EMAIL_STATE_CLEAR,
  CHANGE_SIGN_IN_EMAIL,
  CHANGE_SIGN_IN_EMAIL_SUCCESS,
  CHANGE_SIGN_IN_EMAIL_ERROR,
  CHANGE_SIGN_IN_EMAIL_STATE_CLEAR,
  REQUEST_USER_AUTH_EMAIL_CONFIRMATION,
  REQUEST_USER_AUTH_EMAIL_CONFIRMATION_SUCCESS,
  REQUEST_USER_AUTH_EMAIL_CONFIRMATION_ERROR,
  REQUEST_USER_AUTH_EMAIL_CONFIRMATION_STATE_CLEAR,
} from '../actions/';

export default (
  state = {
    isLoggingIn: false,
    isLoggingOut: false,
    isVerifying: false,
    loginError: false,
    loginErrorMessage: '',
    logoutError: false,
    isAuthenticated: false,

    isSigningUp: false,
    isSignUpSuccess: false,
    isSignUpError: false,
    signUpErrorMessage: '',
    isCreatingNewUserInFirestore: false,
    isCreatedNewUserInFirestoreSuccess: false,
    isCreatedNewUserInFirestoreError: false,
    isCreatedNewUserInFirestoreErrorMessage: '',
    user: {},
    changingSignInEmail: false,
    changingSignInEmailSuccess: false,
    changingSignInEmailError: false,
    changingSignInEmailErrorMessage: '',
    sendingNewEmailConfirmation: false,
    sendingNewEmailConfirmationSuccess: false,
    sendingNewEmailConfirmationError: false,
    sendingNewEmailConfirmationErrorMessage: '',
  },
  action
) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        loginError: false,
        loginErrorMessage: '',
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: true,
        user: action.user,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        loginErrorMessage: action.payload,
        isAuthenticated: false,
        loginError: true,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoggingOut: true,
        logoutError: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggingOut: false,
        isAuthenticated: false,
        user: {},
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        logoutError: true,
      };
    case VERIFY_REQUEST:
      return {
        ...state,
        isVerifying: true,
        verifyingError: false,
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        isVerifying: false,
      };

    case SIGN_UP_REQUEST:
      return {
        ...state,
        isSigningUp: true,
        isSignUpSuccess: false,
        isSignUpError: false,
        signUpErrorMessage: '',
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        isSigningUp: false,
        isSignUpSuccess: true,
        user: action.user,
      };
    case SIGN_UP_ERROR:
      return {
        ...state,
        isSigningUp: false,
        isSignUpSuccess: false,
        isSignUpError: true,
        signUpErrorMessage: action.payload,
      };
    case CREATING_NEW_USER_IN_FIRESTORE:
      return {
        ...state,
        isCreatingNewUserInFirestore: true,
        isCreatedNewUserInFirestoreSuccess: false,
        isCreatedNewUserInFirestoreError: false,
        isCreatedNewUserInFirestoreErrorMessage: '',
      };
    case CREATED_NEW_USER_IN_FIRESTORE:
      return {
        ...state,
        isCreatingNewUserInFirestore: false,
        isCreatedNewUserInFirestoreSuccess: true,
        isCreatedNewUserInFirestoreError: false,
        isCreatedNewUserInFirestoreErrorMessage: '',
      };
    case CREATING_NEW_USER_IN_FIRESTORE_ERROR:
      return {
        ...state,
        isCreatingNewUserInFirestore: false,
        isCreatedNewUserInFirestoreSuccess: false,
        isCreatedNewUserInFirestoreError: true,
        isCreatedNewUserInFirestoreErrorMessage: action.payload,
      };

    case SEND_PASSWORD_RESET_EMAIL:
      return {
        ...state,
        authSendingPasswordResetEmail: true,
        authSendingPasswordResetEmailSuccess: false,
        authSendingPasswordResetEmailError: false,
        authSendingPasswordResetEmailErrorMessage: '',
      };

    case SEND_PASSWORD_RESET_EMAIL_SUCCESS:
      return {
        ...state,
        authSendingPasswordResetEmail: false,
        authSendingPasswordResetEmailSuccess: true,
        authSendingPasswordResetEmailError: false,
        authSendingPasswordResetEmailErrorMessage: '',
      };

    case SEND_PASSWORD_RESET_EMAIL_ERROR:
      return {
        ...state,
        authSendingPasswordResetEmail: false,
        authSendingPasswordResetEmailSuccess: false,
        authSendingPasswordResetEmailError: true,
        authSendingPasswordResetEmailErrorMessage: action.error,
      };

    case SEND_PASSWORD_RESET_EMAIL_STATE_CLEAR:
      return {
        ...state,
        authSendingPasswordResetEmail: false,
        authSendingPasswordResetEmailSuccess: false,
        authSendingPasswordResetEmailError: false,
        authSendingPasswordResetEmailErrorMessage: '',
      };

    case CHANGE_SIGN_IN_EMAIL:
      return {
        ...state,
        changingSignInEmail: true,
        changingSignInEmailSuccess: false,
        changingSignInEmailError: false,
        changingSignInEmailErrorMessage: '',
      };
    case CHANGE_SIGN_IN_EMAIL_SUCCESS:
      return {
        ...state,
        changingSignInEmail: false,
        changingSignInEmailSuccess: true,
        changingSignInEmailError: false,
        changingSignInEmailErrorMessage: '',
      };
    case CHANGE_SIGN_IN_EMAIL_ERROR:
      return {
        ...state,
        changingSignInEmail: false,
        changingSignInEmailSuccess: false,
        changingSignInEmailError: true,
        changingSignInEmailErrorMessage: action.error,
      };
    case CHANGE_SIGN_IN_EMAIL_STATE_CLEAR:
      return {
        ...state,
        changingSignInEmail: false,
        changingSignInEmailSuccess: false,
        changingSignInEmailError: false,
        changingSignInEmailErrorMessage: '',
      };
    case REQUEST_USER_AUTH_EMAIL_CONFIRMATION:
      return {
        ...state,
        sendingNewEmailConfirmation: true,
        sendingNewEmailConfirmationSuccess: false,
        sendingNewEmailConfirmationError: false,
        sendingNewEmailConfirmationErrorMessage: '',
      };
    case REQUEST_USER_AUTH_EMAIL_CONFIRMATION_SUCCESS:
      return {
        ...state,
        sendingNewEmailConfirmation: false,
        sendingNewEmailConfirmationSuccess: true,
        sendingNewEmailConfirmationError: false,
        sendingNewEmailConfirmationErrorMessage: '',
      };
    case REQUEST_USER_AUTH_EMAIL_CONFIRMATION_ERROR:
      return {
        ...state,
        sendingNewEmailConfirmation: false,
        sendingNewEmailConfirmationSuccess: false,
        sendingNewEmailConfirmationError: true,
        sendingNewEmailConfirmationErrorMessage: action.error,
      };
    case REQUEST_USER_AUTH_EMAIL_CONFIRMATION_STATE_CLEAR:
      return {
        ...state,
        sendingNewEmailConfirmation: false,
        sendingNewEmailConfirmationSuccess: false,
        sendingNewEmailConfirmationError: false,
        sendingNewEmailConfirmationErrorMessage: '',
      };

    default:
      return state;
  }
};
